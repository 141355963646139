import React, { useState } from 'react'
import { ImSearch } from 'react-icons/im';
import { RiDeleteBin5Fill, RiEdit2Fill } from 'react-icons/ri';
import DownloadTableData from '../../Screens/Common/downloadData';
import { decryptData, getEncryptItems } from '../../Screens/Common/localStorageUtils';
import add from '../../Assets/AddFrame(1).png'
import SkeletonTableLoader from '../../Screens/Common/Loading/SkeletonTableLoader';
import CreateAutoConsumption from './Products Modal/CreateAutoConsumption';

const AutoConsumption = () => {
    const [searchData, setSearchData] = useState("");
    const [results, setResults] = useState([]);
    const [openAutoConsumption, setOpenAutoConsumption] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let userRole = decryptData()?.user?.role;
    const userRoleName = getEncryptItems("customRoles");
    const handleClear = () => {

    }
    const handleSearch = () => {

    }
    const rowData = [];
    const headerData = [];
    return (
        <>
            <CreateAutoConsumption
                openAutoConsumption={openAutoConsumption}
                setOpenAutoConsumption={setOpenAutoConsumption}
                
            />
            <div className="mx-auto mt-4">
                <div className="px-5 py-5 bg-white w-full border-t rounded-t-lg grid grid-flow-row md:grid-flow-col justify-between">
                    <div className="font-bold text-2xl tracking-wide w-full">
                        Auto Consumption
                    </div>

                    <div className="flex w-full justify-center mt-2 xs:mt-0">
                        <div className="bg-white rounded-lg w-full xs:w-72 flex p-1 relative border border-gray-200 hover:border-primaryHoverColor hover:border-1">
                            <span className="flex items-center justify-center transition ease-in-out duration-300 ml-2">
                                <ImSearch color="gray" size={15} />
                            </span>
                            <input
                                id="searchData"
                                className="border-0 rounded px-4 py-1 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none w-full pr-6"
                                placeholder="Search Supplier"
                                type="text"
                                value={searchData}
                                onChange={(e) => {
                                    // setCurrentPage(1);
                                    handleSearch(e);
                                }}
                            />
                            {searchData && (
                                <span
                                    className="absolute right-14 top-2 cursor-pointer"
                                    onClick={handleClear}
                                >
                                    X
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="font-bold text-2xl tracking-wide w-full flex  justify-end gap-2">
                        <div className="flex flex-row items-center justify-center gap-2 flex-wrap mb-2">
                            <DownloadTableData
                                rowData={rowData}
                                headerData={headerData}
                            />
                            {(userRole === "admin" ||
                                (userRole === "staff" &&
                                    userRoleName?.roleId?.permissions?.products?.autoConsumption)) && (
                                    <>
                                        <button
                                            onClick={() => setOpenAutoConsumption(true)}
                                        >
                                            {0 ? (
                                                <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                                            ) : (
                                                <span className="flex justify-center items-center">
                                                    <img
                                                        src={add}
                                                        alt="add"
                                                        className="text-primaryColor w-7 h-7"
                                                    />
                                                </span>
                                            )}
                                        </button>
                                    </>
                                )}
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <SkeletonTableLoader />
                ) : [1, 2, 3]?.length === 0 ? (
                    <div className="flex justify-center bg-[#fff] shadow-md py-4">
                        No Consumption found
                    </div>
                ) : searchData && results.length > 0 ? (
                    <div className="shadow rounded-t-lg overflow-x-auto">
                        <table className="min-w-full leading-normal">
                            {renderHeader(userRole)}
                            <tbody>
                                {results?.map((v, i) => {
                                    return (
                                        <tr key={i}>
                                            <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                                <div className="text-gray-900 whitespace-no-wrap">
                                                    {i + 1}
                                                </div>
                                            </td>

                                            <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                                <div className="text-gray-900 whitespace-no-wrap">
                                                    {v?.name}
                                                </div>
                                            </td>
                                            <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                                <div className="text-gray-900 whitespace-no-wrap">
                                                    {v?.phone}
                                                </div>
                                            </td>

                                            <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                                <div className="text-gray-900 whitespace-no-wrap">
                                                    {v?.isActive ? "Active" : "InActive"}
                                                </div>
                                            </td>
                                            <td className="px-5 py-5 border flex justify-center border-gray-200 bg-white text-sm">
                                                <div className="whitespace-no-wrap flex">
                                                    {(userRole === "admin" ||
                                                        (userRole === "staff" &&
                                                            userRoleName?.roleId?.permissions?.products
                                                                ?.editSupplier)) && (
                                                            <RiEdit2Fill
                                                                size={15}
                                                                // onClick={() => {
                                                                //     handleOpenEdit(v?._id);
                                                                // }}
                                                                className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                                                            />
                                                        )}

                                                    {(userRole === "admin" ||
                                                        (userRole === "staff" &&
                                                            userRoleName?.roleId?.permissions?.products
                                                                ?.deleteSupplier)) && (
                                                            <RiDeleteBin5Fill
                                                                className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                                                                size={15}
                                                            // onClick={() => {
                                                            //     setId(v?._id);
                                                            //     setOp(true);
                                                            // }}
                                                            />
                                                        )}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                ) : searchData && results.length === 0 ? (
                    <div className="flex justify-center bg-[#fff] w-full shadow-md py-4">
                        No Consumption found
                    </div>
                ) : (
                    <div className="shadow rounded-t-lg overflow-x-auto">
                        <table className="min-w-full leading-normal">
                            {renderHeader(userRole)}
                            <tbody>
                                {[1, 2, 3]?.map((v, i) => {
                                    return (
                                        <tr key={i}>
                                            <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                                <div className="text-gray-900 whitespace-no-wrap">
                                                    {i + 1}
                                                </div>
                                            </td>

                                            <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                                <div className="text-gray-900 whitespace-no-wrap">
                                                    {v?.name}
                                                </div>
                                            </td>
                                            <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                                <div className="text-gray-900 whitespace-no-wrap">
                                                    {v?.phone}
                                                </div>
                                            </td>

                                            <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                                <div className="text-gray-900 whitespace-no-wrap">
                                                        30
                                                </div>
                                            </td>
                                            <td className="px-5 py-5 border flex justify-center border-gray-200 bg-white text-sm">
                                                <div className="whitespace-no-wrap flex">
                                                    {(userRole === "admin" ||
                                                        (userRole === "staff" &&
                                                            userRoleName?.roleId?.permissions?.products
                                                                ?.editSupplier)) && (
                                                            <RiEdit2Fill
                                                                size={15}
                                                                // onClick={() => {
                                                                //     handleOpenEdit(v?._id);
                                                                // }}   
                                                                className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                                                            />
                                                        )}

                                                    {(userRole === "admin" ||
                                                        (userRole === "staff" &&
                                                            userRoleName?.roleId?.permissions?.products
                                                                ?.deleteSupplier)) && (
                                                            <RiDeleteBin5Fill
                                                                className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                                                                size={15}
                                                            // onClick={() => {
                                                            //     setId(v?._id);
                                                            //     setOpenDeleteSupplier(true);
                                                            // }}
                                                            />
                                                        )}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </>
    )
}

export default AutoConsumption

const renderHeader = ({ userRole }) => {
    return (
        <thead>
            <tr className="bg-gray-100">
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Sl
                </th>
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Service
                </th>
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Product
                </th>
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Volume
                </th>
                <th className="px-5 py-3 border-2 border-gray-200 text-sm font-semibold text-gray-600 tracking-wide">
                    <span>Actions</span>
                </th>
            </tr>
        </thead>
    )
}