/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { BiSolidUserCircle } from "react-icons/bi";
import { jwtDecode } from "jwt-decode";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../Common/localStorageUtils";
import profile_pic from "../../Assets/user.png";
import { useDispatch, useSelector } from "react-redux";
import { logOutUser } from "../../state-management/main/actions";
import BranchSwitch from "./BranchSwitch";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import { toast } from "react-toastify";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    borderRadius: "4px",
    paddingTop: "0",
    marginTop: "8px",
    marginRight: "12px",
    overflow: "visible",
  },
  [`& .MuiTooltip-arrow`]: {
    color: "orange",
    fontSize: "1.5rem",
  },
  [`& .MuiTooltip-arrow::before`]: {
    backgroundColor: "orange",
    // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
  },
});

const AdminProfileDropDown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openTooltip, setOpenTooltip] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = decryptData()?.userTypeData?.url || "";
  const userRole = decryptData()?.user?.role;
  const userData = decryptData()?.user;
  const token = localStorage.getItem("refresh_token");
  const decoded = token ? jwtDecode(token) : null;
  const branch = useSelector(state => state?.main?.branch);

  const handleProfileDropdown = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenTooltip((prevState) => !prevState);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    setOpenTooltip(false);
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("encryptedData");
    localStorage.removeItem("branchDetails");
    dispatch(logOutUser());
    navigate("/logout");
  };

  const profileDropdownClose = () => {
    setAnchorEl(null);
    setOpenTooltip(false);
  };

  const handleProfile = () => {
    profileDropdownClose();
    setOpenTooltip(false);
    navigate(`/${userRole}/profile`);
  };

  // useEffect(() => {
  //   if (!decoded) {
  //     handleLogout();
  //     return;
  //   }
  //   const expirationTime = decoded.exp * 1000;
  //   const currentTime = new Date().getTime();

  //   if (currentTime >= expirationTime) {
  //     handleLogout();
  //     return;
  //   }

  //   const orgData = decoded.orgnizationData?.find(ele => ele?._id === branch?._id);
  //   if (orgData) {
  //     const subscription = orgData?.subscriptions?.[0];

  //     if (orgData.status !== 'active') {
  //       toast.error('Your organization is inactive. You will be logged out.');
  //       handleLogout();
  //       return;
  //     }

  //     if (subscription && subscription?.subscriptionHistory) {
  //       const subscriptionExpiration = new Date(subscription?.subscriptionHistory?.expiredAt)?.getTime();
  //       if (currentTime >= subscriptionExpiration) {
  //         toast.error('Your subscription has expired. You will be logged out.');
  //         setTimeout(() => {
  //           handleLogout()
  //         }, 3000)
  //         return;
  //       }
  //     }
  //   }

  //   const timeUntilExpiration = expirationTime - currentTime;
  //   const timer = setTimeout(handleLogout, timeUntilExpiration);
  //   return () => clearTimeout(timer);
  // }, [decoded, handleLogout]);

  return (
    <div>
      <CustomTooltip
        title={
          openTooltip && (
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={profileDropdownClose}
              className="mt-3"
            >
              <div className="border-b border-gray-200 py-2 px-6">
                <MenuItem>
                  <div className="flex gap-3 justify-center items-center">
                    <img
                      src={url || profile_pic}
                      alt="Profile Pic"
                      className="w-16 h-16 object-cover rounded-full"
                    />
                    <div className="flex flex-col gap-2">
                      <h1 className="text-black font-bold tracking-wide text-[20px]">
                        {userData?.firstName}
                      </h1>
                      <h2 className="text-gray-400 font-thin tracking-wider text-[18px]">
                        {userData?.email}
                      </h2>
                    </div>
                  </div>
                </MenuItem>
              </div>

              <div className="flex flex-col justify-center">
                <div className="p-2 border-b border-gray-200">
                  <MenuItem onClick={handleProfile}>
                    <BiSolidUserCircle size={22} className="mr-2" />
                    <p className="text-[20px] text-primaryColor tracking-wide">
                      Profile
                    </p>
                  </MenuItem>
                </div>

                <div className="p-2">
                  <MenuItem onClick={handleLogout}>
                    <RiLogoutCircleRLine
                      size={20}
                      color="red"
                      className="mr-2"
                    />
                    <p className="text-[20px] text-primaryColor tracking-wide">
                      Logout
                    </p>
                  </MenuItem>
                </div>
              </div>

              <div className="md:hidden">
                <MenuItem>
                  <div>
                    <BranchSwitch />
                  </div>
                </MenuItem>
              </div>
            </Menu>
          )
        }
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          setOpenTooltip(false);
        }}
        disableHoverListener
        disableFocusListener
        disableTouchListener
        placement="bottom"
        arrow
      >
        <div
          onClick={handleProfileDropdown}
          className="flex items-center justify-center transition ease-in-out hover:text-primaryColor hover:duration-300 cursor-pointer"
        >
          <div className="w-10 h-10 rounded-full overflow-hidden border-2 border-gray-400 transition ease-in-out hover:border-primaryColor hover:duration-300">
            <img
              src={url || profile_pic}
              alt="Profile Pic"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </CustomTooltip>
    </div>
  );
};

export default AdminProfileDropDown;
