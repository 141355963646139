import { useState, useRef, useEffect } from "react";
import { FaEye } from "react-icons/fa";

const AvailableServicesPopover = ({ availableClientServices }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef(null);

  const togglePopover = (e) => {
    e.stopPropagation(); 
    setIsPopoverOpen((prev) => !prev);
  };

  const closePopover = (e) => {
    if (popoverRef.current && !popoverRef.current.contains(e.target)) {
      setIsPopoverOpen(false);
    }
  };

  useEffect(() => {
    if (isPopoverOpen) {
      document.addEventListener("click", closePopover);
    }
    return () => {
      document.removeEventListener("click", closePopover);
    };
  }, [isPopoverOpen]);

  return (
    <div className="relative flex flex-col items-center">
      <p className="text-[18px] font-semibold">Available Services</p>
      <button
        onClick={togglePopover}
        className="text-gray-500 hover:text-gray-700"
        aria-label="View available services"
      >
        <FaEye className="text-[24px] text-primaryColor" />
      </button>

      {isPopoverOpen && (
        <div
          ref={popoverRef}
          className={`absolute top-12 right-0 z-50 w-64 p-4 bg-white rounded-lg shadow-lg border border-gray-200 transition-all transform ${isPopoverOpen ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'} transition-opacity duration-500 ease-in-out`}
          onClick={(e) => e.stopPropagation()} 
        >
          <h3 className="text-lg font-bold mb-2">Available Services</h3>
          <div className="max-h-[200px] overflow-y-auto">
            {availableClientServices?.length > 0 ? (
              <ul className="divide-y divide-gray-200">
                {availableClientServices.map((service) => (
                  <li
                    key={service?.inventoryId}
                    className="py-2 flex justify-between items-center"
                  >
                    <span className="font-medium">{service?.name}</span>
                    <span className="text-gray-500">
                      ({service?.quantityAvailable})
                    </span>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500">No services available</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AvailableServicesPopover;
