/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import AppointmentModal from "./AppointmentModal";
import { useSelector } from "react-redux";
import NewMembershipModal from "../../Membership/MembershipModals/NewMembershipModal";
import axios from "axios";
import {
  decryptData,
  getEncryptItems,
} from "../../../Screens/Common/localStorageUtils";
import { RiDeleteBin5Line } from "react-icons/ri";
import { IoEyeOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import {
  allCustomerWithMembership,
  allMembershipList,
  clearBillForm,
  clearClient,
  setAppointmentFromCalendar,
  setBillForm,
  storeClient,
  storeDraftBill,
} from "../../../state-management/main/actions";
import InvoicePreviewModal from "./InvoicePreviewModal";
import { toast } from "react-toastify";
import ClientDetails from "../../Dashboard/ClientDetails";
import {
  allMembershipData,
  fetchCustomerWithMembership,
} from "../../../Screens/Common/initData";
import clear from "../../../Assets/billClear.png";
import Plus from "../../../Assets/billAdd.png";
import minus from "../../../Assets/billRemove.png";
import InvoiceDropdown from "./InvoiceDropdown";
import Tooltip from "./Tooltip";
import { tostifyErr } from "../../../Screens/Common/tostifyMessages";
import NewLoader from "../../../Screens/Common/NewLoader";
import { addProductField, addServiceField, combineServices, handleCheckboxChange, handleDeleteMembership, handleGenderSelect, handleProductFieldChange, removeProductField, removeServiceField } from "./billModalMethods";

const tabsName = ["Bill", "Appointment"];

function getCurrentDateTimes(date) {
  if (!(date instanceof Date) || isNaN(date)) {
    date = new Date();
  }
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = `${date.getDate()}`.padStart(2, "0");
  const hour = `${date.getHours()}`.padStart(2, "0");
  const minute = `${date.getMinutes()}`.padStart(2, "0");
  return `${year}-${month}-${day}T${hour}:${minute}`;
}

const BillModal = ({ closeModal, setSelectedRow, }) => {
  const appointmentDetailFromCalendar = useSelector((state) => state?.main?.appointmentDetailFromCalendar);
  const appointment = appointmentDetailFromCalendar?.appointment;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const branch = useSelector(state => state?.main?.branch) || "";
  let userRole = decryptData()?.user?.role;
  const userRoleName = getEncryptItems("customRoles");
  const dispatch = useDispatch();
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentTab, setCurrentTab] = useState("Bill");
  const [viewModal, setViewModal] = useState(false);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [searchResultBox, setSearchResultBox] = useState(false);
  const [client, setClient] = useState({});
  const [existingMemberships, setExistingMemberships] = useState([]);
  const [isNewMembershipModalOpen, setIsNewMembershipModalOpen] = useState(
    false
  );
  const [addMember, setAddMember] = useState([]);
  const [openClientDetailsModal, setOpenClientDetailsModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [draftBillCreated, setDraftBillCreated] = useState(false);
  const [clientFormData, setClientFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    gender: "",
    dateOfBirth: "",
    dateOfAnniversary: "",
    organizationId: "",
  });
  const initialState = {
    name: "",
    phoneNumber: "",
    email: "",
    gender: "",
    dateOfBirth: "",
    dateOfAnniversary: "",
    organizationId: "",
    updatedCustomer: "",
    dateTime: getCurrentDateTimes(new Date()),
    requiredServices: [
      {
        serviceName: "",
        duration: "",
        staff: "",
        staffName: "",
        discount: "",
        discountType: "",
        serviceId: "",
        price: "",
        priceBeforeDiscount: "",
        itemPerPrice: "",
        seat: "",
        quantity: "",
        discountText: "",
      },
    ],
    requiredProducts: [
      {
        productName: "",
        price: "",
        staff: "",
        staffName: "",
        discount: "",
        discountType: "",
        priceBeforeDiscount: "",
        itemPerPrice: "",
        quantity: "",
        productId: "",
        discountText: "",
      },
    ],
  };
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showDateOfBirth, setShowDateOfBirth] = useState(false);
  const [showDateOfAnniversary, setShowDateOfAnniversary] = useState(false);
  const isInvoiceForEdit = useSelector(state => state?.main?.isInvoiceForEdit);
  const staff = useSelector((state) => state?.main?.staffList)?.filter(ele => ele?.organizationId === branch?._id);
  const customerWithMembershipList = useSelector((state) => state?.main?.customerWithMembershipList);
  const customerWhileEditInvoice = useSelector(state => state?.main?.clientDuringBill)

  const AllCustomer = Array.isArray(customerWithMembershipList)
    ? customerWithMembershipList?.filter(ele => ele?.organization === branch?._id)
    : [];

  const inventory = useSelector((state) => state?.main?.inventoryService)
    ?.filter((item) => item?.organizationId?._id === branch?._id && !item.isDeleted);

  const product = useSelector((state) => state?.main?.productList)?.filter((item) => item?.organization?._id === branch?._id);

  const filteredMembership = useSelector((state) =>
    state?.main?.membershipList?.filter((memb) => memb?.organizationId?._id === branch?._id));

  const membershipData = filteredMembership?.filter((pack) =>
    addMember?.includes(pack)
  );
  const formattedMembershipData = membershipData
    ?.map((membership) => {
      const currentDate = new Date();
      const membershipValidity = membership?.validity;

      if (
        typeof membershipValidity === "number" &&
        !isNaN(membershipValidity)
      ) {
        const expirationDate = new Date(currentDate);
        expirationDate.setMonth(expirationDate.getMonth() + membershipValidity);

        if (!isNaN(expirationDate)) {
          const formattedExpDate = expirationDate.toISOString().split("T")[0];

          return {
            membershipId: membership?._id,
            expireDate: formattedExpDate,
            startDate: currentDate?.toISOString().split("T")[0],
          };
        }
      }

      return null;
    })
    .filter((data) => data !== null);

  const storedDraftBill = useSelector(state => state?.main?.draftBill)

  const getCustmerWithMembership = async () => {
    const data = await fetchCustomerWithMembership();
    dispatch(allCustomerWithMembership(data));
  };

  const totalServiceCharge = formData?.requiredServices?.reduce(
    (acc, service) => acc + (service.price),
    0
  )
  const totalProductCharge = formData?.requiredProducts?.reduce((acc, product) => acc + Number(product?.price), 0);
  const totalMembershipPrice = addMember?.[0]?.price || 0;
  const membershipPackageData = async () => {
    const data = await allMembershipData();
    dispatch(allMembershipList(data));
  };
  const setMyCards = (value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    if (isInvoiceForEdit && storedDraftBill) {
      const mappedServices = storedDraftBill?.services?.map(service => {
        const originalPrice = service?.quantity * service?.itemPerPrice;
        let newPrice = originalPrice;
        let discountAmount = 0;
        if (service?.discount) {
          if (service?.discountType === "percentage") {
            discountAmount = (originalPrice * service.discount) / 100;
            newPrice = originalPrice - discountAmount;
          } else if (service?.discountType === "flat") {
            discountAmount = service.discount;
            newPrice = originalPrice - discountAmount;
          }
        }
        return {
          serviceName: service?.serviceName || "",
          duration: service?.duration || "",
          staff: service?.staff || "",
          staffName: service?.staffName || "",
          discount: service?.discount || "",
          discountType: service?.discountType || "",
          serviceId: service?.serviceId || "",
          price: newPrice,
          priceBeforeDiscount: originalPrice,
          itemPerPrice: service?.itemPerPrice || "",
          seat: service?.seat || "",
          quantity: service?.quantity || "",
          discountText: discountAmount > 0 ? `Price After Discount ${Number(newPrice).toFixed(2)}` : ""
        };
      }) || [];

      const mappedProducts = storedDraftBill?.products?.map(product => ({
        productName: product?.productName || "",
        price: product?.price || "",
        staff: product?.staff || "",
        staffName: product?.staffName || "",
        discount: product?.discount || "",
        discountType: product?.discountType || "",
        priceBeforeDiscount: product?.priceBeforeDiscount || "",
        itemPerPrice: product?.itemPerPrice || "",
        quantity: product?.quantity || "",
        productId: product?.productId || "",
        discountText: product?.discountText || ""
      })) || [];

      const finalProducts = mappedProducts.length > 0 ? mappedProducts : [initialState.requiredProducts[0]];

      const newFormData = {
        ...initialState,
        name: storedDraftBill?.billingInfo?.name || "",
        phoneNumber: storedDraftBill?.billingInfo?.phone || "",
        email: storedDraftBill?.billingInfo?.email || "",
        gender: customerWhileEditInvoice?.gender || "",
        dateOfBirth: storedDraftBill?.dateOfBirth || "",
        dateOfAnniversary: storedDraftBill?.dateOfAnniversary || "",
        organizationId: storedDraftBill?.organizationId || "",
        updatedCustomer: storedDraftBill?.updatedCustomer || {},
        dateTime: storedDraftBill?.dateTime || getCurrentDateTimes(new Date()),
        requiredServices: mappedServices.length > 0 ? mappedServices : initialState.requiredServices,
        requiredProducts: finalProducts,
      };

      setClient(customerWhileEditInvoice)
      setAddMember(storedDraftBill?.membershipIds)
      setFormData(newFormData);
    } else {
      setFormData(initialState);
    }
  }, [isInvoiceForEdit]);

  useEffect(() => {
    const selectedDate = new Date(appointmentDetailFromCalendar?.selectedDate);
    if (!isNaN(selectedDate.getTime())) {
      setFormData(prev => ({
        ...prev,
        dateTime: getCurrentDateTimes(selectedDate) || getCurrentDateTimes(new Date())
      }));
    }
  }, [appointmentDetailFromCalendar?.selectedDate, branch]);

  useEffect(() => {
    if (appointment) {
      const clientFromAppointment = AllCustomer?.find(
        (item) => item?.phoneNumber === appointment?.customerPhone
      );
      setClient(clientFromAppointment);
      setFormData((prev) => ({
        ...prev,
        name: clientFromAppointment?.name,
        phoneNumber: clientFromAppointment?.phoneNumber,
        gender: clientFromAppointment?.gender,
        email: clientFromAppointment?.email,
      }));
    }
  }, [appointment]);

  useEffect(() => {
    setCurrentTab(appointmentDetailFromCalendar?.selectedItem);
  }, [appointmentDetailFromCalendar?.selectedItem]);

  useEffect(() => {
    getCustmerWithMembership();
    membershipPackageData();
  }, [viewModal, branch]);

  useEffect(() => {
    if (formData?.phoneNumber?.length === 0) {
      setFormData(initialState);
      dispatch(clearBillForm());
      setClient({});
      setClientFormData({ ...clientFormData });
    }
  }, [formData?.phoneNumber?.length, branch]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "name") {
      setClientFormData({
        ...clientFormData,
        nameErr: false,
        name: value,
      });
    }
    else if (name === "dateOfBirth") {
      setClientFormData({
        ...clientFormData,
        dateOfBirth: value,
      });
    } else if (name === "dateOfAnniversary") {
      setClientFormData({
        ...clientFormData,
        dateOfAnniversary: value,
      });
    }
    if (name === "phoneNumber") {
      handleSearch(value);
    }
  };

  const handleSearch = (phoneNumber) => {
    if (phoneNumber?.trim()?.length >= 3) {
      const filteredUsers = AllCustomer?.filter((user) =>
        user?.phoneNumber?.toLowerCase()?.includes(phoneNumber?.toLowerCase())
      );
      setSearchedUsers(filteredUsers);
      if (filteredUsers?.length) {
        setSearchResultBox(true);
      } else {
        setSearchResultBox(false);
      }
      setClientFormData({
        ...clientFormData,
      });
    } else {
      setSearchResultBox(false);
      setSearchedUsers([]);
    }
  };

  const calculatePrice = useCallback((quantity, itemPrice) => quantity * itemPrice, []);

  const handleDiscountChange = useCallback((currentService, discount) => {
    if (!currentService.discountType) {
      alert("Please select a Discount Type First");
      return null;
    }

    discount = parseFloat(discount);
    if (isNaN(discount) || discount < 0) {
      return {
        price: calculatePrice(currentService.quantity, currentService.itemPerPrice),
        priceBeforeDiscount: calculatePrice(currentService.quantity, currentService.itemPerPrice),
        discountText: "",
        discount: 0
      };
    }

    const originalPrice = calculatePrice(currentService.quantity, currentService.itemPerPrice);
    let discountAmount, newPrice;

    if (currentService.discountType === "flat") {
      if (discount >= originalPrice) {
        alert("Invalid Discount");
        return null;
      }
      discountAmount = discount;
      newPrice = originalPrice - discount;
    } else if (currentService.discountType === "percentage") {
      if (discount >= 100) {
        alert("Invalid Discount");
        return null;
      }
      discountAmount = (originalPrice * discount) / 100;
      newPrice = originalPrice - discountAmount;
    }

    return {
      price: newPrice,
      priceBeforeDiscount: originalPrice,
      discountText: `Price After Discount ${Number(newPrice).toFixed(2)}.`,
      discount
    };
  }, [calculatePrice]);

  const handleServiceChange = useCallback((e, index, fieldName) => {
    const { value } = e.target;

    setFormData(prevFormData => {
      const updatedServices = [...prevFormData.requiredServices];
      let currentService = { ...updatedServices[index] };

      const updateService = (updates) => {
        currentService = { ...currentService, ...updates };
      };

      switch (fieldName) {
        case "serviceName":
          const selectedService = inventory?.find(service => service._id === value);
          if (selectedService) {
            updateService({
              serviceName: selectedService?.name,
              duration: `${selectedService.duration}`,
              price: selectedService.price,
              priceBeforeDiscount: selectedService?.price,
              itemPerPrice: selectedService.price,
              serviceId: selectedService._id,
              quantity: 1,
            });
          } else {
            updateService({
              serviceName: "",
              duration: "",
              price: 0,
              priceBeforeDiscount: 0,
              serviceId: "",
              quantity: 0,
            });
          }
          break;

        case "staff":
          const selectedStaff = staff.find(s => s._id === value);
          if (selectedStaff) {
            updateService({ staff: value, staffName: selectedStaff?.userId?.firstName });
          } else {
            updateService({ staff: "", staffName: "" });
          }
          break;

        case "quantity":
          const quantity = parseInt(value);
          if (!isNaN(quantity)) {
            const newPrice = calculatePrice(quantity, currentService?.itemPerPrice);
            updateService({
              quantity,
              price: newPrice,
              priceBeforeDiscount: newPrice,
              discount: 0,
              discountText: ""
            });
          } else {
            updateService({ quantity: 0 });
          }
          break;

        case "flat":
        case "percentage":
          updateService({
            discountType: fieldName,
            discount: "",
            price: calculatePrice(currentService.quantity, currentService.itemPerPrice),
            priceBeforeDiscount: calculatePrice(currentService.quantity, currentService.itemPerPrice),
            discountText: ""
          });
          break;

        case "discount":
          const discountUpdate = handleDiscountChange(currentService, value);
          if (discountUpdate) {
            updateService(discountUpdate);
          }
          break;

        default:
          updateService({ [fieldName]: value });
      }

      updatedServices[index] = currentService;

      return {
        ...prevFormData,
        requiredServices: updatedServices,
      };
    });
  }, [inventory, staff, calculatePrice, handleDiscountChange]);


  const handleAddServiceField = () => {
    addServiceField(formData, setFormData);
  };

  // Usage of removeServiceField
  const handleRemoveServiceField = (index) => {
    removeServiceField(formData, setFormData, index);
  };

  const handleProductChange = (e, index, fieldName) => {
    handleProductFieldChange(e, index, fieldName, formData, setFormData, product);
  };
  const handleClearProduct = (index) => {
    const updatedProducts = [...formData.requiredProducts];
    updatedProducts[index] = {
      ...updatedProducts[index],
      productName: "",
      price: 0,
      itemPerPrice: 0,
      productId: "",
      quantity: 0,
      discount: 0,
      discountText: "",
      staff: "",
      discountType: "percentage"
    };

    setFormData(prevFormData => ({
      ...prevFormData,
      requiredProducts: updatedProducts
    }));
  };


  const handleAddProductField = () => {
    addProductField(formData, setFormData);
  };

  const handleRemoveProductField = (index) => {
    removeProductField(formData, setFormData, index);
  };

  const handleCheckbox = (e) => {
    handleCheckboxChange(e, setShowDateOfBirth, setShowDateOfAnniversary);
  };

  const handleMembershipDelete = (index) => {
    handleDeleteMembership(index, storedDraftBill, client, addMember, setIsLoading, setClient, dispatch, storeClient, setAddMember);
  };

  const handleGenderChange = (value) => {
    handleGenderSelect(value, setFormData);
  };
  const handleGenderClear = () => {
    setFormData((prev) => ({
      ...prev,
      gender: '',
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handlePreviewModal = async () => {
    try {
      if (!formData?.name || !formData?.phoneNumber || !formData.gender) {
        setFormData((prev) => ({
          ...prev,
          errorText: "Please Fill All required Fields With * Marks",
        }));
        toast.error("Please Fill All required Fields With * Marks", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }

      if ((formData?.phoneNumber)?.trim()?.length !== 10) {
        setFormData(prev => ({
          ...prev,
          errorText: "Invalid phone number"
        }));
        toast.error("Invalid Phone Number.", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }

      if (
        formData?.requiredServices?.[0]?.serviceId === "" &&
        formData?.requiredProducts?.[0]?.productId === "" &&
        addMember?.[0] === undefined
      ) {
        setFormData((prev) => ({
          ...prev,
          errorText:
            "Please Select At least One Service Or One Product Or One Membership",
        }));
        return;
      }

      setFormData((prev) => ({
        ...prev,
        errorText: "",
      }));

      if (!client?._id) {
        await handleClient();
      } else {
        await handleUpdateCustomer();
      }
    } catch (error) {
      console.error("Error in handlePreviewModal:", error);
      toast.error("An error occurred while processing your request. Please try again.");
    }
  };

  const handleClient = async () => {
    // const membershipObj = (formattedMembershipData?.[0]) || {};
    const dataObj = {
      organizationId: branch?._id,
      name: formData?.name,
      email: formData?.email,
      gender: formData?.gender,
      dateOfBirth: formData?.dateOfBirth,
      phoneNumber: `+91${formData?.phoneNumber}`,
      dateOfAnniversary: formData?.dateOfAnniversary || "",
      // dueAmount: Number(totalServiceCharge + totalProductCharge + (totalMembershipPrice || 0))
    };

    // if (Object.keys(membershipObj).length > 0) {
    //   dataObj.membershipObj = membershipObj;
    // }

    const data = JSON.stringify(dataObj);

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/customer/add`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      setIsLoading(true);
      const response = await axios.request(config);
      setClient(response?.data?.data?.customer)
      dispatch(storeClient(response?.data?.data?.customer));
      if (response?.status === 201) {
        await handleCreateBill(response?.data?.data?.customer?._id);
      }
    } catch (error) {
      console.error("Error in handleClient:", error);
      if (error?.response?.data?.message === "Invalid phone number") {
        toast.error("Invalid phone number. Please check and try again.");
      } else {
        toast.error("An error occurred while creating the client. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateCustomer = async () => {
    let data = JSON.stringify({
      membershipObj: formattedMembershipData,
      gender: client?.gender,
      // dueAmount: Number(totalServiceCharge + totalProductCharge + (totalMembershipPrice || 0))
    });
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/customer/update/${client?._id}`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };
    setIsLoading(true);
    try {
      // if (addMember?.length && addMember?.[0]?._id !== storedDraftBill?.membershipIds?.[0]?._id) {
      // const response = await axios.request(config);
      // setClient(response?.data?.data?.updatedCustomer);
      // dispatch(storeClient(response?.data?.data?.updatedCustomer));
      // handleCreateBill(client?._id);
      // }
      // else {
      dispatch(storeClient(client));
      handleCreateBill(client?._id);
      // }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      tostifyErr(error?.response?.data?.message);
    }
  };
  const handleCreateBill = async (clientId) => {
    let billData = {
      customerId: clientId,
      adminId: branch?.adminId,
      date: new Date(formData?.dateTime)?.toISOString()?.slice(0, 10),
      organizationId: branch?._id,
      name: formData?.name,
      dueAmount: Number((totalServiceCharge || 0) + (totalProductCharge || 0) + (totalMembershipPrice || 0)),
      phone: formData?.phoneNumber,
      email: formData?.email,
      membershipIds: addMember?.map((item) => item?._id),
      walletApplied: 0,
      gstPercentage: `0`,
      previousClientDue: client?.dueAmount,
      dateOfBirth: formData?.dateOfBirth,
      paymentMethod: "",
      paymentStatus: "due",
      paymentArray: [],
      totalServiceCharge: `${totalServiceCharge}`,
      gstChargeAmount: 0,
      finalPrice: `${Number((totalServiceCharge || 0) + (totalProductCharge || 0) + (totalMembershipPrice || 0))}`,
      gender: formData?.gender,
      paidAmount: `${0}`,
      services: formData?.requiredServices,
      products: formData?.requiredProducts,
      // priceAfterService: (client?.walletBalance || 0) + (addMember?.[0]?.packagePrice || 0),
      paymentMethods: [],
      serviceBilledOn: formData?.requiredServices,
      freeServices: {},
      freeServiceApplied: false,
      servicesIncludedInMembership: [],
      isDraftInvoice: true,
    };
    if ((storedDraftBill && Object.keys(storedDraftBill).length > 0) || draftBillCreated) {
      delete billData.organizationId;
      delete billData.customerId;
      delete billData.adminId;
    }

    const ExistUserdata = JSON.stringify(billData);
    const config = {
      method: (
        (storedDraftBill && Object.keys(storedDraftBill).length > 0) || draftBillCreated) ? "put" : "post",
      maxBodyLength: Infinity,
      url: ((storedDraftBill && Object.keys(storedDraftBill).length > 0) || draftBillCreated)
        ? `${process.env.REACT_APP_API_URL}/billing/update/${storedDraftBill._id}`
        : `${process.env.REACT_APP_API_URL}/billing/add`,

      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: ExistUserdata,
    };
    // console.log("ExistUserdata", JSON.parse(ExistUserdata));
    // return;

    try {
      setIsLoading(true);
      const response = await axios.request(config);
      dispatch(
        setBillForm({
          ...formData,
          membership: formattedMembershipData?.[0],
          client: {
            walletBalance: Number(formattedMembershipData?.[0]?.packagePrice || 0),
            clientId: client?._id || "",
            dueAmount: client?.dueAmount || 0,
            services: combineServices(client?.services, addMember?.[0]?.services) || null,
          },
          membershipAray: addMember,
          paidPrice: 0,
        })
      );
      setDraftBillCreated(true);
      dispatch(storeDraftBill(response?.data?.data?.Bill));
      setViewModal(true);
      // await getCustmerWithMembership();
    } catch (error) {
      console.error("Error in handleCreateBill:", error);
      if (error.response?.status === 400 && error.response?.data?.message?.includes("You are not allowed to update")) {
        tostifyErr("You are not allowed to update certain fields. Please refresh and try again.");
      } else {
        tostifyErr(error?.response?.data?.data?.message || "An error occurred while creating the bill. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setFormData(initialState);
    setExistingMemberships([]);
    setClient({});
    dispatch(clearClient());
    setAddMember([]);
    // setFormData((prev) => ({
    //   ...prev,
    //   name: "",
    //   phoneNumber: "",
    //   email: "",
    //   gender: "",
    //   dateOfBirth: "",
    //   dateOfAnniversary: "",
    // }));
    dispatch(setAppointmentFromCalendar({
      appointment: {},
      selectedItem:
        appointmentDetailFromCalendar?.selectedItem,
      selectedDate: new Date(),
    })
    );
    dispatch(clearBillForm());
    setClientFormData({ ...clientFormData });
  }
  return (
    <React.Fragment>
      <style>
        {`
          input[type="checkbox"]{
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 18px;
            height: 18px;
            border: 2px solid gray;
            outline: none;
            cursor: pointer;
            position: relative;
          }

          input[type="checkbox"]:checked {
            border: 2px solid #fe8740;
          }

          input[type="checkbox"]:checked::after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background: #fe8740;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        `}
      </style>
      <div className="h-auto w-full mt-4">
        <div className="flex justify-center items-center h-full">
          <div className="no-scrollbar bg-white py-8 px-20 w-full rounded shadow overflow-y-auto relative">
            <div className="flex gap-2 md:gap-5 items-start md:flex-row flex-col">
              <div className="flex gap-5">
                <div className="flex md:mb-8 gap-1  rounded-full bg-gray-200 font-medium">
                  {tabsName.map((ele, index) => {
                    return (
                      <div
                        className={` text-[16px] flex flex-row items-center gap-2 ${currentTab === ele
                          ? "bg-primaryColor text-white font-medium h-10 w-full p-4 flex content-center items-center justify-center text-center text-md rounded-full cursor-pointer  transition ease-in-out duration-300 transform"
                          : "text-black h-10 w-full p-4 flex content-center items-center justify-center text-center text-md  bg-transparent  rounded-full cursor-pointer transition ease-in-out duration-300 transform"
                          } px-9 py-[7px] rounded-full transition-all duration-200 cursor-pointer`}
                        key={index}
                        style={{ width: "50%" }}
                        onClick={() => setMyCards(ele)}
                      >
                        {ele}
                      </div>
                    );
                  })}
                </div>

                <div
                  onClick={() => handleClear()}
                  className="bg-primaryColor text-white font-medium h-8 w-8 flex items-center
              text-md rounded-full cursor-pointer mt-1"
                >
                  <img src={clear} alt="clear" className="w-8 h-8" />
                </div>
              </div>

              <div className="text-red-600 text-center">
                {formData?.errorText && formData?.errorText}
              </div>
            </div>

            {currentTab === "Bill" && (
              <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                <div className="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                  <div className="w-full">
                    <label
                      className=" text-gray-700 text-sm font-bold mb-2"
                      htmlFor="name"
                    >
                      Phone Number <sup className="text-[#FF782D] text-[14px]">*</sup>
                    </label>
                    <div className="relative">
                      <input
                        id="phoneNumber"
                        name="phoneNumber"
                        className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow 
                        focus:outline-none focus:ring-1 ${(formData?.phoneNumber)?.trim()?.length !== 10 ? "ring-red-600" : "ring-blue-600"} w-full ease-linear transition-all duration-150
                        `}
                        autoComplete="off"
                        placeholder="Search by phoneNumber"
                        type="text"
                        value={formData?.phoneNumber}
                        onChange={handleChange}
                      />
                      {client && Object.keys(client).length > 0 && (
                        <div
                          onClick={() => { dispatch(storeClient(client)); setOpenClientDetailsModal(true) }}
                          className="absolute -top-10 left-1/2 w-fit transform cursor-pointer -translate-x-1/2 bg-primaryColor text-white rounded-md shadow-md px-3 py-2"
                          style={{
                            maxWidth: "200px",
                            zIndex: 1000,
                          }}
                        >
                          <div className="text-sm font-medium">
                            {client?.dueAmount ? "Due Payment" : "No Due"}
                          </div>
                          {client?.dueAmount > 0 ? (
                            <div className="text-xs">
                              Amount:  ₹ {client?.dueAmount}
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className="tooltip-arrow"
                            style={{ marginLeft: "-6px", marginTop: "-4px" }}
                          />
                        </div>
                      )}

                      <div
                        className={`${searchResultBox ? "" : "hidden"
                          } absolute bg-gray-200 shadow-lg rounded w-full mt-1 border z-30 border-gray-200`}
                      >
                        {searchedUsers?.length > 0 && (
                          <ul className="divide-y divide-gray-200">
                            {searchedUsers?.map((ele, i) => (
                              <li
                                onClick={() => {
                                  setClient(ele);
                                  setExistingMemberships(ele?.membership)
                                  setClientFormData({
                                    ...clientFormData,
                                    phoneErr: false,
                                    name: ele?.name,
                                    phoneNumber: ele?.phoneNumber?.slice(3),
                                    email: ele?.email,
                                    gender: ele?.gender,
                                    dateOfBirth: ele?.dateOfBirth,
                                    dateOfAnniversary: ele?.dateOfAnniversary,
                                  });
                                  setFormData({
                                    ...formData,
                                    name: ele?.name,
                                    phoneNumber: ele?.phoneNumber?.slice(3),
                                    email: ele?.email,
                                    gender: ele?.gender,
                                    dateOfBirth: ele?.dateOfBirth,
                                    dateOfAnniversary: ele?.dateOfAnniversary,
                                  });
                                  setSearchedUsers([]);
                                  setSearchResultBox(false);
                                }}
                                className="px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                                key={i}
                              >
                                {ele?.phoneNumber} {ele?.name}
                              </li>
                            ))}
                            <button
                              onClick={() => setSearchResultBox(false)}
                              className="absolute top-0 right-0 px-2 py-1 text-sm text-gray-500 hover:text-gray-700 focus:outline-none"
                            >
                              X
                            </button>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <label
                      className=" text-gray-700 text-sm font-bold mb-2"
                      htmlFor="phoneNumber"
                    >
                      Name{" "}
                      <sup className="text-[#FF782D] text-[14px]">*</sup>
                    </label>
                    <input
                      className={
                        clientFormData?.nameErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150 pr-10"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-blue-600 w-full ease-linear transition-all duration-150 pr-10"
                      }
                      type="text"
                      id="name"
                      name="name"
                      disabled={client?.name}
                      autoComplete="off"
                      placeholder="Enter Name"
                      value={formData?.name}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-1"
                      htmlFor="gender"
                    >
                      Gender
                      <sup className="text-[#FF782D] text-[14px]">*</sup>
                    </label>
                    <div className="w-full">
                      <InvoiceDropdown
                        items={["Male", "Female", "Other"]}
                        onSelect={handleGenderChange}
                        autoComplete="off"
                        onClear={handleGenderClear}
                        selectedItem={formData?.gender}
                        label={"Select Gender"}
                        className="w-full"
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <label
                      className=" text-gray-700 text-sm font-bold mb-2"
                      htmlFor="dateTime"
                    >
                      Date & Time
                    </label>
                    <input
                      className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow 
                      focus:outline-none focus:ring-1 ring-blue-600 w-full ease-linear transition-all duration-150"
                      type="datetime-local"
                      id="dateTime"
                      name="dateTime"
                      value={formData?.dateTime}
                      min={
                        userRole === "admin" ||
                          (userRole === "staff" &&
                            userRoleName?.roleId?.permissions?.dashboard
                              ?.dashBoardBackDateBilling)
                          ? null
                          : new Date().toISOString().split("T")[0]
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">

                  <div className="flex gap-2 flex-col items-center cursor-pointer">
                    <div className="flex gap-2 justify-start w-full">
                      <input
                        type="checkbox"
                        name="showDateOfBirth"
                        checked={showDateOfBirth}
                        onChange={handleCheckbox}
                      />
                      <span>Date Of Birth</span>
                    </div>

                    <div className={`w-full transition-all overflow-scroll no-scrollbar duration-700 ease-in-out ${showDateOfBirth ? 'max-h-20 px-1 pb-4' : 'max-h-0'}`}>
                      <label
                        className=" text-gray-700 text-sm font-bold mb-2"
                        htmlFor="dateOfBirth"
                      >
                        Date of Birth
                      </label>
                      <input
                        className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-blue-600 w-full ease-linear transition-all duration-150"
                        type="date"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        value={formData?.dateOfBirth}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 items-center cursor-pointer">
                    <div className="flex w-full justify-start gap-2 items-center">
                      <input
                        type="checkbox"
                        name="showDateOfAnniversary"
                        checked={showDateOfAnniversary}
                        onChange={handleCheckbox}
                      />
                      <span>Anniversary</span>
                    </div>
                    <div className={`w-full transition-all no-scrollbar duration-700 ease-in-out ${showDateOfAnniversary ? 'max-h-20 px-1 pb-4' : 'max-h-0'}`}>
                      <label
                        className=" text-gray-700 text-sm font-bold mb-2"
                        htmlFor="dateOfAnniversary"
                      >
                        Anniversary
                      </label>
                      <input
                        className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow 
                        focus:outline-none focus:ring-1 ring-blue-600 w-full ease-linear transition-all duration-150"
                        type="date"
                        id="dateOfAnniversary"
                        name="dateOfAnniversary"
                        value={formData?.dateOfAnniversary}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="flex gap-2 flex-col mb-3">
                    <div className="flex gap-2 items-baseline">
                      <h1 className="text-gray-700 text-sm font-bold mb-4">Membership</h1>
                      <button onClick={() => setIsNewMembershipModalOpen(true)}>
                        <p className="text-[#FF782D] text-[20px] font-bold">+</p>
                      </button>
                    </div>
                    <table className="w-full border border-collapse">
                      <tbody>
                        {addMember?.map((ele, ind) => (
                          <tr key={ind}>
                            <td className="px-3 py-2 text-black font-bold">
                              {ele?.packageName}
                            </td>
                            <td className="px-3 py-2 text-black font-bold">
                              <Tooltip
                                text={ele}
                                showTooltip={showTooltip}
                                setShowTooltip={setShowTooltip}
                              >
                                <IoEyeOutline size={25} />
                              </Tooltip>
                            </td>
                            <td className="px-3 py-2">
                              <button
                                className="text-white font-bold py-2 px-4 rounded"
                                onClick={() => handleMembershipDelete(ind)}
                              >
                                <RiDeleteBin5Line style={{ color: "red" }} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>

                  {existingMemberships?.length > 0 && <div>
                    <label
                      className=" text-gray-700 text-sm font-bold mb-2"
                      htmlFor="existingMembership"
                    >
                      Existing Memberhsip
                    </label>
                    <div className=" max-h-24 no-scrollbar overflow-y-auto z-20">
                      {existingMemberships?.map((ele, ind) => (
                        <div key={ind} className="bg-white border rounded-lg p-2 flex justify-between items-center">
                          <div className="flex-grow">
                            <h4 className="text-lg font-semibold text-gray-700 flex gap-2">{ele?.membershipName}
                            </h4>
                            <div className="mt-2 text-sm text-gray-600">
                              <p>
                                <span className="font-medium">Start Date:</span> {ele?.startDate}
                              </p>
                              <p>
                                <span className="font-medium">Expiry:</span> {ele?.expireDate}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>}

                </div>
                {formData?.requiredServices?.map((service, index) => {
                  const selectedStaff = formData.requiredServices[index]?.staff || "";
                  const isStaffSelected = selectedStaff !== "";
                  const discountTypes = [
                    { value: "percentage", name: "%" },
                    { value: "flat", name: "₹" }
                  ];

                  const handleServiceClear = () => {
                    setFormData(prevFormData => {
                      const updatedServices = [...prevFormData.requiredServices];
                      updatedServices[index] = {
                        ...updatedServices[index],
                        serviceName: "",
                        duration: "",
                        price: 0,
                        priceBeforeDiscount: 0,
                        serviceId: "",
                        quantity: 0,
                        discount: "",
                        discountText: "",
                        discountType: "percentage"
                      };
                      return {
                        ...prevFormData,
                        requiredServices: updatedServices,
                      };
                    });
                  };

                  const handleStaffClear = () => {
                    setFormData(prevFormData => {
                      const updatedServices = [...prevFormData.requiredServices];
                      updatedServices[index] = {
                        ...updatedServices[index],
                        staff: "",
                        staffName: ""
                      };
                      return {
                        ...prevFormData,
                        requiredServices: updatedServices,
                      };
                    });
                  };

                  return (
                    <div key={index}>
                      <div className="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-center gap-6">
                        <div className="">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor={`service-${index}`}
                          >
                            Select Service
                          </label>
                          <InvoiceDropdown
                            label="Select Service"
                            items={inventory?.map(ele => ({ name: ele?.name, duration: ele?.duration, id: ele?._id, price: ele?.price })) || []}
                            onSelect={(selectedService) => handleServiceChange({ target: { value: selectedService.id } }, index, "serviceName")}
                            onClear={handleServiceClear}
                            selectedItem={formData?.requiredServices?.[index]?.serviceName || ""}
                            itemName={`service-${index}`}
                            className="w-full"
                            showDuration={true}
                          />
                        </div>

                        <div className="flex gap-4 items-center">
                          <div className="w-1/2">
                            <label
                              className=" text-gray-700 text-sm font-bold mb-2 -mt-1"
                              htmlFor="quantity"
                            >
                              Quantity
                            </label>
                            <input
                              className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow 
              focus:outline-none focus:ring-1 ring-blue-600 w-full ease-linear transition-all duration-150"
                              type="number"
                              id={`service-${index}`}
                              name={`service-${index}`}
                              value={service.quantity}
                              autoComplete="off"
                              placeholder="Quantity"
                              onChange={(e) =>
                                handleServiceChange(e, index, "quantity")
                              }
                              min={0}
                            />
                          </div>
                          <div className="w-1/2">
                            <label
                              className=" text-gray-700 text-sm font-bold mb-2"
                              htmlFor="price"
                            >
                              Price
                            </label>
                            <input
                              className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow 
              focus:outline-none focus:ring-1 ring-blue-600 w-full ease-linear transition-all duration-150"
                              type="text"
                              id={`service-${index}`}
                              name={`service-${index}`}
                              value={service?.priceBeforeDiscount}
                              disabled={true}
                              autoComplete="off"
                              placeholder="Price"
                            />
                          </div>
                        </div>

                        <div className="">
                          <div className=" flex flex-col">
                            <div className="flex gap-5 items-start">
                              <label
                                className="block text-gray-700 text-sm font-bold mb-1"
                                htmlFor={`discount-${index}`}
                              >
                                Discount
                              </label>
                            </div>

                            <div className="flex gap-2">
                              <input
                                className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-blue-600 w-full ease-linear transition-all duration-150"
                                type="text"
                                id={`discount-${index}`}
                                name={`discount-${index}`}
                                disabled={service?.price === ""}
                                value={service?.discount}
                                onChange={(e) => handleServiceChange(e, index, "discount")}
                                placeholder={`Enter ${service?.discountType === "percentage" ? "percentage" : "flat amount"}`}
                              />
                              <InvoiceDropdown
                                label="type"
                                items={discountTypes}
                                onSelect={(item) => handleServiceChange({ target: { value: item?.value } }, index, item?.value)}
                                // onClear={handleDiscountTypeClear}
                                selectedItem={discountTypes?.find(type => type.value === service?.discountType)?.name}
                                className="w-20"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-1"
                            htmlFor={`staff-${index}`}
                          >
                            Select Staff
                          </label>
                          <InvoiceDropdown
                            label="Select Staff"
                            items={staff?.map(ele => ({ id: ele?._id, name: ele?.userId?.firstName })) || []}
                            onSelect={(selectedStaff) => handleServiceChange({ target: { value: selectedStaff.id } }, index, "staff")}
                            onClear={handleStaffClear}
                            selectedItem={staff?.find(s => s._id === selectedStaff)?.userId?.firstName || "Select Staff"}
                            itemName={`staff-${index}`}
                            className={
                              !isStaffSelected
                                ? "text-[#132c4a] focus:outline-none focus:ring-1 ring-red-500 w-full ease-linear transition-all duration-150"
                                : "text-[#132c4a] focus:outline-none focus:ring-1 ring-blue-600 w-full ease-linear transition-all duration-150"
                            }
                          />
                        </div>
                      </div>

                      <div className="mb-4">
                        <div className="flex justify-end flex-wrap items-center gap-6 w-full">
                          <div className="flex justify-between">
                            {service?.discountText && service?.discountText}
                            <div className="ml-5 flex  items-center justify-end gap-4 flex-1">
                              {index === formData.requiredServices.length - 1 && (
                                <div className="flex items-center gap-2">
                                  <img
                                    src={Plus}
                                    alt="Add"
                                    onClick={handleAddServiceField}
                                    className="cursor-pointer w-8 h-8 "
                                  />
                                  <img
                                    src={minus}
                                    alt="Remove"
                                    onClick={() => handleRemoveServiceField(index)}
                                    className="cursor-pointer w-8 h-8"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {formData?.requiredProducts?.map((products, index) => {
                  const selectedStaff = formData.requiredProducts[index]?.staff || "";
                  const isStaffSelected = selectedStaff !== "";
                  const discountTypes = [
                    { value: "percentage", name: "%" },
                    { value: "flat", name: "₹" }
                  ]
                  return (
                    <div key={index}>
                      <div className="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-center gap-6">
                        <div className="">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor={`products-${index}`}
                          >
                            Select Product
                          </label>
                          <InvoiceDropdown
                            label="Select Product"
                            items={product?.map(ele => ele?.name) || []}
                            onSelect={(selectedProduct) => handleProductChange({ target: { value: selectedProduct } }, index, "productName")}
                            selectedItem={formData?.requiredProducts[index]?.productName || ""}
                            itemName={`products-${index}`}
                            onClear={() => handleClearProduct(index)}
                            className="w-full"
                          />
                        </div>
                        <div className="flex gap-4 items-center">
                          <div className="w-1/2">
                            <label
                              className=" text-gray-700 text-sm font-bold mb-2"
                              htmlFor="quantity"
                            >
                              Quantity
                            </label>
                            <input
                              className="text-[#132c4a] mt-1 border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow 
                            focus:outline-none focus:ring-1 ring-blue-600 w-full ease-linear transition-all duration-150"
                              type="number"
                              id={`products-${index}`}
                              name={`products-${index}`}
                              value={products.quantity}
                              placeholder="Quantity"
                              onChange={(e) =>
                                handleProductChange(e, index, "quantity")
                              }
                              min={0}
                            />
                          </div>
                          <div className="w-1/2">
                            <label
                              className="block text-gray-700 text-sm font-bold mb-2"
                              htmlFor="price"
                            >
                              Price
                            </label>
                            <input
                              className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow 
                          focus:outline-none focus:ring-1 ring-blue-600 w-full ease-linear transition-all duration-150"
                              id={`products-${index}`}
                              name={`products-${index}`}
                              value={products?.price}
                              autoComplete="off"
                              placeholder="Price"
                              disabled={products?.price}
                              onChange={(e) =>
                                handleProductChange(e, index, "price")
                              }
                            />
                          </div>
                        </div>

                        <div className="w-full">
                          <div className="flex gap-5 items-start">
                            <label
                              className="block text-gray-700 text-sm font-bold mb-1"
                              htmlFor={`products-${index}`}
                            >
                              Discount
                            </label>
                          </div>

                          <div className="flex gap-2">
                            <input
                              className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow 
                            focus:outline-none focus:ring-1 ring-blue-600 w-full ease-linear transition-all duration-150"
                              type="text"
                              id={`discountp-${index}`}
                              name={`discounpt-${index}`}
                              autoComplete="off"
                              disabled={products?.price === ""}
                              value={products?.discount}
                              onChange={(e) =>
                                handleProductChange(e, index, "discount")
                              }
                              placeholder={`Enter ${products?.discountType === "percentage"
                                ? "percentage"
                                : "flat amount"
                                }`}
                            />
                            <InvoiceDropdown
                              label="type"
                              items={discountTypes}
                              onSelect={(item) => handleProductChange({ target: { value: item.value } }, index, "discountType")}
                              selectedItem={discountTypes.find(type => type.value === products?.discountType)?.name}
                              className="w-20"
                            />
                          </div>
                        </div>
                        <div className=" w-full">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="staff"
                          >
                            Select Staffs
                          </label>
                          <InvoiceDropdown
                            label="Select Staff"
                            items={staff?.map(ele => ({ id: ele?._id, name: ele?.userId?.firstName })) || []}
                            onSelect={(selectedStaff) => handleProductChange({ target: { value: selectedStaff.id } }, index, "staff")}
                            selectedItem={staff?.find(s => s._id === selectedStaff)?.userId?.firstName || "Select Staff"}
                            onClear={() => handleProductChange({ target: { value: "" } }, index, "staff")}
                            itemName={`staff-${index}`}
                            className={
                              !isStaffSelected
                                ? "text-[#132c4a] focus:outline-none focus:ring-1 ring-red-500 w-full ease-linear transition-all duration-150"
                                : "text-[#132c4a] focus:outline-none focus:ring-1 ring-blue-600 w-full ease-linear transition-all duration-150"
                            }
                          />

                        </div>
                      </div>

                      <div className="flex items-end flex-wrap gap-5 my-2 justify-between">



                        <div>
                          {products?.discountText && products?.discountText}
                        </div>

                        <div className="mb-4 flex justify-end items-end gap-4">
                          {index === formData?.requiredProducts?.length - 1 && (
                            <div className="flex items-center lg:gap-2 gap-2">
                              <img
                                src={Plus}
                                alt="Add"
                                onClick={handleAddProductField}
                                className="cursor-pointer w-8 h-8"
                              />
                              <img
                                src={minus}
                                alt="Remove"
                                onClick={() => handleRemoveProductField(index)}
                                className="cursor-pointer w-8 h-8"
                              />
                            </div>
                          )}
                        </div>

                      </div>
                    </div>
                  )
                })}

                <div className="mb-4 flex justify-between items-center gap-2">
                  <div className="w-full md:w-1/2 lg:w-1/3">
                    <label
                      className=" text-gray-700 text-sm font-bold mb-2"
                      htmlFor="name"
                    >
                      Custom GST field
                    </label>
                    <input
                      className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow 
                      focus:outline-none focus:ring-1 ring-blue-600 w-full ease-linear transition-all duration-150"
                      type="text"
                      id="name"
                      placeholder="Enter Gst No."
                    />
                  </div>
                  <div className="flex justify-end mt-6 gap-4">
                    <button
                      className="bg-primaryColor text-white py-2 px-4 rounded-md hover:bg-primaryHoverColor focus:outline-none focus:ring-2 focus:ring-blue-600"
                      onClick={handlePreviewModal}
                    >
                      {isLoading ? <NewLoader />
                        : "Preview & Complete"}
                    </button>
                  </div>
                </div>

              </form>
            )}

            {currentTab === "Appointment" && (
              <AppointmentModal
                closeModal={closeModal}
                setSelectedRow={setSelectedRow}
              />
            )}
          </div>
        </div>
      </div>
      {/* </Modal> */}
      <InvoicePreviewModal
        setDraftBillCreated={setDraftBillCreated}
        initialState={initialState}
        viewModal={viewModal}
        setViewModal={setViewModal}
        setFormData={setFormData}
        setAddMember={setAddMember}
        closeModal={closeModal}
        isEditModalOpen={isEditModalOpen}
        setIsEditModalOpen={setIsEditModalOpen}
        setClient={setClient}
      />
      <NewMembershipModal
        openAddMembershipModal={isNewMembershipModalOpen}
        setOpenAddMembershipModal={setIsNewMembershipModalOpen}
        client={client}
        setAddMember={setAddMember}
        addMember={addMember}
      />
      <ClientDetails
        openClientDetailsModal={openClientDetailsModal}
        setOpenClientDetailsModal={setOpenClientDetailsModal}
        client={client}
      />
      <style jsx>{`
        .tooltip-arrow {
          position: absolute;
          bottom: -4px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-top: 4px solid #fe8740;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
        }
      `}</style>
    </React.Fragment>
  );
};

export default BillModal;

