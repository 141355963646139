import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { decryptData, getEncryptItems } from "../../../Screens/Common/localStorageUtils";
import { useState } from "react";
import axios from "axios";
import { tostifyErr, tostifySuccess } from "../../../Screens/Common/tostifyMessages";
import regex from "../../../Screens/Common/validation";
import { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import Plus from "../../../Assets/billAdd.png";
import minus from "../../../Assets/billRemove.png";
import InvoiceDropdown from "../../Invoices/InvoiceModal/InvoiceDropdown";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
};

const categoryOptions = [
  { name: "Fixed Wallet" },
  { name: "Fixed Discount" },
  { name: "Service Based" }
];

const AddPackages = (props) => {
  const decryptedData = decryptData();
  const adminId = decryptedData?.user?.role === "admin" ? decryptedData?.userTypeData?._id : decryptedData?.userTypeData?.adminId;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const branch = getEncryptItems("branchDetails");
  const serviceList = useSelector(
    (state) => state?.main?.inventoryService
  )?.filter((item) => item?.organizationId?._id === branch?._id);
  const organization = useSelector((state) => state?.main?.orgList);
  const orgByAdminId = organization?.filter((org) => org._id === branch?._id);
  const [isLoading, setIsLoading] = useState(false);
  const membershipType = props.membershipType;
  const [combinedPrice, setCombinedPrice] = useState(0);

  const { register, control, handleSubmit, watch, setValue, formState: { errors } } = useForm({
    defaultValues: {
      name: "",
      orgId: branch?._id,
      price: "",
      packagePrice: "",
      validity: "",
      category: membershipType === "All" ? "" : membershipType,
      discount: "",
      services: [],
      description: ""
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "services"
  });

  const watchServices = watch("services");
  const watchCategory = watch("category");

  useEffect(() => {
    let total = 0;
    watchServices.forEach(service => {
      const serviceItem = serviceList.find(item => item._id === service?.serviceId);
      if (serviceItem) {
        total += serviceItem.price * (service?.quantity || 0);
      }
    });
    setCombinedPrice(total);
  }, [watchServices, serviceList]);

  const handleCategorySelect = (category) => {
    setValue("category", category.name);
  };

  const handleServiceSelect = (service, index) => {
    setValue(`services.${index}.serviceId`, service?._id);
  };

  const onSubmit = async (data) => {
    if (data.category === "Fixed Discount" && data.discount > 100) {
      tostifyErr("Discount must be between 0 and 100");
      return;
    }

    const formattedServices = data.services.map(service => {
      const selectedService = serviceList.find(item => item._id === service?.serviceId);
      return {
        serviceId: service?.serviceId,
        serviceName: selectedService?.name,
        quantity: parseInt(service?.quantity)
      };
    });

    const submitData = {
      packageName: data.name,
      price: data.price,
      validity: data.validity,
      organizationId: branch?._id,
      category: data.category,
      adminId: adminId,
      packagePrice: data.packagePrice,
      discount: data.discount,
      services: formattedServices,
      description: data.description,
    };

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/membership-package/add`,
        submitData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      props?.fetchMembershipData();
      tostifySuccess(response?.data?.message);
      props?.setOpenAddPackageModal(false);
      setIsLoading(false);
    } catch (error) {
      props?.setOpenAddPackageModal(false);
      tostifyErr(error?.response?.data?.message);
      setIsLoading(false);
    }
  };
  return (
    <React.Fragment>
      <Modal
        open={props.openAddPackageModal}
        onClose={() => props.setOpenAddPackageModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <div className="no-scrollbar max-h-[80dvh] over overflow-y-scroll overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() => props.setOpenAddPackageModal(false)}
                className="absolute top-6 right-5 text-3xl cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">
                Add Membership
              </div>
            </div>

            <div className="flex-auto mt-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    {...register("name", { required: true })}
                    className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.name ? "ring-[#f7003f]" : "ring-[#845ff6]"
                      } w-full ease-linear transition-all duration-150`}
                    placeholder="Enter Package Name"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Description
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    {...register("description", { required: true })}
                    className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.description ? "ring-[#f7003f]" : "ring-[#845ff6]"
                      } w-full ease-linear transition-all duration-150`}
                    placeholder="Enter Description"
                  />
                </div>

                {membershipType === "All" && (
                  <div className="flex justify-center items-center mb-3 w-full">
                    <div className="relative w-full mb-3">
                      <div className="text-[#132c4a] block text-sm mb-2">
                        Category
                        <span className="text-red-600 text-lg"> *</span>
                      </div>
                      <InvoiceDropdown
                        className={`w-full`}
                        label="Select Category"
                        items={categoryOptions}
                        onSelect={handleCategorySelect}
                        selectedItem={watchCategory}
                      />
                    </div>
                  </div>
                )}

                <div className="grid grid-cols-2 gap-4">
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Price
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      {...register("price", {
                        required: true,
                        pattern: regex.priceRegex
                      })}
                      className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.price ? "ring-[#f7003f]" : "ring-[#845ff6]"
                        } w-full ease-linear transition-all duration-150`}
                      placeholder="Enter Price"
                    />
                    <p className="text-sm text-gray-400 mt-1">
                      {combinedPrice ? `Total Price: ${combinedPrice}` : ''}
                    </p>
                  </div>

                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Package Validity (No. Of Months)
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      type="number"
                      {...register("validity", {
                        required: true,
                        pattern: regex.priceRegex
                      })}
                      className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.validity ? "ring-[#f7003f]" : "ring-[#845ff6]"
                        } w-full ease-linear transition-all duration-150`}
                      placeholder="Enter No. of Months"
                    />
                  </div>
                </div>

                {watch("category") === "Fixed Wallet" && (
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Wallet Balance
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      {...register("packagePrice", { required: true })}
                      className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.packagePrice ? "ring-[#f7003f]" : "ring-[#845ff6]"
                        } w-full ease-linear transition-all duration-150`}
                      placeholder="Enter Wallet Balance"
                    />
                  </div>
                )}

                {watchCategory === "Service Based" && (
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Services
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <div className="space-y-2">
                      {fields?.map((field, index) => (
                        <div key={field.id} className="flex gap-2">
                          <InvoiceDropdown
                            label="Select Service"
                            className={`w-full`}
                            items={serviceList}
                            onSelect={(service) => handleServiceSelect(service, index)}
                            selectedItem={serviceList.find(s => s._id === field.serviceId)?.name}
                            showDuration={true}
                          />
                          <input
                            type="number"
                            {...register(`services.${index}.quantity`, {
                              required: true,
                              min: 1
                            })}
                            placeholder="Qty"
                            className="w-24 text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6]"
                          />
                          <img
                            height={20}
                            width={20}
                            className="cursor-pointer object-contain"
                            src={minus}
                            onClick={() => remove(index)}
                            alt="Remove"
                          />
                        </div>
                      ))}
                      <img
                        height={25}
                        width={25}
                        className="cursor-pointer"
                        src={Plus}
                        onClick={() => append({ serviceId: "", quantity: "" })}
                        alt="Add"
                      />
                    </div>
                  </div>
                )}

                {watch("category") === "Fixed Discount" && (
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Discount Percentage
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      type="number"
                      {...register("discount", {
                        required: true,
                        min: 0,
                        max: 100
                      })}
                      className={`text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ${errors.discount ? "ring-[#f7003f]" : "ring-[#845ff6]"
                        } w-full ease-linear transition-all duration-150`}
                      placeholder="Enter Discount Amount"
                    />
                    {errors.discount && (
                      <div className="text-[#828282] text-xs mt-2">
                        Discount value must be between 0 to 100
                      </div>
                    )}
                  </div>
                )}

                <div className="w-full flex items-center justify-center mt-5">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="h-12 w-2/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                  >
                    {isLoading ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Create</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default AddPackages;