import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import { decryptData, getEncryptItems } from "../../../Screens/Common/localStorageUtils";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  tostifyErr,
  tostifySuccess,
} from "../../../Screens/Common/tostifyMessages";
import regex from "../../../Screens/Common/validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  outline: "none",
  p: 4,
};

const AddServices = (props) => {
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [price, setPrice] = useState("");
  const [priceErr, setPriceErr] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [durationErr, setDurationErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const decryptedData = decryptData();
  const adminId = decryptedData?.user?.role === "admin" ? decryptedData?.userTypeData?._id : decryptedData?.userTypeData?.adminId;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const branch = useSelector(state => state?.main?.branch) || "";

  const clearInputField = () => {
    setName("");
    setNameErr("");
    setPrice("");
    setPriceErr("");
    setDescription("");
    setDuration("");
    setDurationErr("");
  };

  const handleAddService = async (e) => {
    e.preventDefault();
    if (!name) {
      setNameErr(true);
    } else if (!regex.priceRegex.test(price)) {
      setPriceErr(true);
    } else if (!duration) {
      setDurationErr(true);
    } else {
      let data = JSON.stringify({
        name: name,
        price: price,
        category: "Service",
        adminId: adminId,
        duration: duration,
        organizationId: branch?._id,
        description: description,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/inventory/add/`,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      setIsLoading(true);
      axios
        .request(config)
        .then((response) => {
          props.setOpenAddServicesModal(false);
          clearInputField();
          tostifySuccess(response?.data?.message);
          props.fetchInventory();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("add service error", error);
          // clearInputField();
          tostifyErr(error?.response?.data?.message);
          props.setOpenAddServicesModal(false);
          setIsLoading(false);
        });
    }
  };

  return (
    <React.Fragment >
      <Modal
        open={props.openAddServicesModal}
        onClose={() => {
          // clearInputField();
          props.setOpenAddServicesModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <div className="no-scrollbar max-h-[500px] min-w-[400px] sm:min-w-[450px] overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() => props.setOpenAddServicesModal(false)}
                className="absolute top-6  right-5 text-3xl  cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">
                Add Inventory Service
              </div>
            </div>

            <div className="flex-auto mt-2">
              <form onSubmit={(e) => handleAddService(e)}>
                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Service  Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="name"
                    autoComplete="off"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameErr(false);
                    }}
                    className={
                      nameErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Inventory Name"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Price
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="number"
                    id="price"
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                      setPriceErr(false);
                    }}
                    className={
                      priceErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Price"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Duration(Mins.)
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="number"
                    id="duration"
                    value={duration}
                    onChange={(e) => {
                      setDuration(e.target.value);
                      setDurationErr(false);
                    }}
                    className={
                      durationErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Duration"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Descriptions
                  </div>
                  <input
                    id="description"
                    type="text"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    className={
                      "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Inventory Description"
                  />
                </div>

                <div className="w-full flex items-center justify-center mt-5">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="h-12 w-2/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                  >
                    {isLoading ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default AddServices;
