import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { FaUserCircle } from "react-icons/fa";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import InvoiceViewModal from "../Invoices/InvoiceModal/InvoiceViewModal";
import { useDispatch, useSelector } from "react-redux";
import { storeDraftBill } from "../../state-management/main/actions";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    p: 4,
};

const ClientDetails = (props) => {
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const [setselectedItem, setSetselectedItem] = useState("Bills");
    const [viewModal, setViewModal] = useState(false);
    const [client, setClient] = useState([]);
    const [singleInvoice, setSingleInvoice] = useState({});
    const [availableServices, setAvailableServices] = useState([]);
    const dispatch = useDispatch();
    const allBillData = useSelector(state => state?.main?.billList);
    const handleClick = (bill) => {
        const selectedBill = allBillData?.find(item => item?._id === bill?._id);
        dispatch(storeDraftBill(selectedBill));
        setSingleInvoice(selectedBill);
    }
    useEffect(() => {
        fetchClient();
    }, [props?.client?.phoneNumber]);
    const fetchClient = async () => {
        if (props.client?.phoneNumber) {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/customer/singleCustomer?phone=${props?.client?.phoneNumber}`,
                headers: {
                    'Authorization': token
                }
            };

            axios.request(config)
                .then((response) => {
                    setClient(response?.data?.data?.customer);
                    setAvailableServices(response?.data?.data?.availableServices)
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const classes = {
        active:
            "h-10 w-full p-4 flex content-center items-center justify-center text-center text-md text-white bg-primaryColor rounded-xl cursor-pointer hover:bg-primaryHoverColor transition ease-in-out duration-300 transform",
        inactive:
            "h-10 w-full p-4 flex content-center items-center justify-center text-center text-md  text-[#7f8296] bg-transparent hover:bg-[#febc95] hover:text-gray-900 rounded-xl cursor-pointer transition ease-in-out duration-300 transform",
    };

    return (
        <React.Fragment>
            <InvoiceViewModal
                viewModal={viewModal}
                setViewModal={setViewModal}
                singleInvoice={singleInvoice}
            />
            <Modal
                open={props.openClientDetailsModal}
                onClose={() => {
                    // props.setPhone("");
                    setAvailableServices([])
                    props.setOpenClientDetailsModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="relative">
                    <div className="h-[420px] overflow-y-scroll no-scrollbar  px-4">
                        <div className="btn-wrapper text-center">
                            <span
                                onClick={() => {
                                    setAvailableServices([]);
                                    props.setOpenClientDetailsModal(false)
                                }}
                                className="absolute top-6  right-5 text-3xl  cursor-pointer"
                            >
                                x
                            </span>
                            <div className="text-xl text-[#2b2f32] font-bold">
                                Customer Details
                            </div>
                            <div className="flex flex-col lg:flex-row h-auto mt-5 p-4 gap-4">
                                <div className="w-full lg:w-[30%] bg-gray-100 flex flex-col gap-5 rounded-lg">
                                    <div className="flex justify-center items-center mt-5 p-4">
                                        <FaUserCircle size={80} color="gray" />
                                    </div>
                                    <div className="flex justify-center lg:justify-normal">
                                        <div className="flex flex-col gap-4 p-4">
                                            {[
                                                { label: "Name:", value: client?.name },
                                                { label: "Phone:", value: client?.phoneNumber },
                                                { label: "Email:", value: client?.email },
                                                { label: "Due:", value: client?.dueAmount }
                                            ].map((item, index) => (
                                                <div key={index} className="flex justify-start items-center gap-2">
                                                    <span className="font-medium">{item.label}</span>
                                                    <span>{item.value}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                                <div className="w-full ">
                                    <div className="mx-auto">
                                        <div className="scroll-container overflow-x-auto inset-x-0 rounded-xl bg-white">
                                            <div className="min-w-full leading-normal">
                                                <div className="flex justify-between items-center gap-4">
                                                    <div
                                                        onClick={() => setSetselectedItem("Bills")}
                                                        className={
                                                            setselectedItem === "Bills"
                                                                ? classes.active
                                                                : classes.inactive
                                                        }
                                                    >
                                                        Invoices
                                                    </div>

                                                    <div
                                                        onClick={() => setSetselectedItem("Memberships")}
                                                        className={
                                                            setselectedItem === "Memberships"
                                                                ? classes.active
                                                                : classes.inactive
                                                        }
                                                    >
                                                        Memberships
                                                    </div>
                                                    <div
                                                        onClick={() => setSetselectedItem("Available Services")}
                                                        className={
                                                            setselectedItem === "Available Services"
                                                                ? classes.active
                                                                : classes.inactive
                                                        }
                                                    >
                                                        Available Services
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mx-auto">
                                        {setselectedItem === "Bills"
                                            && <BillTable
                                                handleClick={handleClick}
                                                setViewModal={setViewModal}
                                                client={client}
                                                setOpenClientDetailsModal={props.setOpenClientDetailsModal}
                                            />}
                                    </div>
                                    <div className="mx-auto">
                                        {setselectedItem === "Memberships" && <Memberships client={client} />}
                                    </div>
                                    <div className="mx-auto">
                                        {setselectedItem === "Available Services" && <Services availableServices={availableServices} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default ClientDetails;

const BillTable = ({ client, setViewModal, handleClick, setOpenClientDetailsModal }) => {
    return (
        <div className="overflow-x-auto overflow-y-scroll max-h-[400px] mt-4">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-4 text-center py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Date
                        </th>
                        <th scope="col" className="px-4 text-center py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Bill Amount
                        </th>
                        <th scope="col" className="px-4 text-center py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Paid Amount
                        </th>
                        <th scope="col" className="px-4 text-center py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Due Amount
                        </th>
                        <th scope="col" className="px-4 text-center py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Due Received
                        </th>
                        <th scope="col" className="px-4 text-center py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Services
                        </th>
                    </tr>
                </thead>
                <tbody
                    // onClick={() => set}
                    className="bg-white divide-y divide-gray-200 cursor-pointer">
                    {client?.billings?.map((bill, index) => (
                        <tr
                            onClick={() => {
                                handleClick(bill);
                                setViewModal(true);
                                setOpenClientDetailsModal(false);
                            }}
                            key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                            <td className="px-4 py-4 whitespace-nowrap">
                                {bill?.date}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap">
                                {bill?.finalPrice}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap">
                                {bill?.paidAmount}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap">
                                {bill?.dueAmount}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap">
                                {Math.max(bill?.paidAmount - bill?.finalPrice, 0)}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap">
                                {bill?.services?.map((service, index) => (
                                    <React.Fragment key={index}>
                                        <span>{service?.serviceName}</span>
                                        {index < bill.services.length - 1 && <span className="mx-1">&</span>}
                                    </React.Fragment>
                                ))}
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}


const Memberships = ({ client }) => {
    return (
        <div className=" h-[400px] overflow-y-scroll mt-4">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Membership Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Category
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Start Date
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Expire Date
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Remaining Amount
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {client?.membership?.map((ele, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                            <td className="px-6 py-4 whitespace-nowrap">
                                {ele?.membershipId?.packageName}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                {ele?.membershipId?.category}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                {ele?.startDate}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                {ele?.expireDate}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                {ele?.membershipId?.price}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
const Services = ({ availableServices }) => {
    return (
        <div className="h-[400px] overflow-y-scroll mt-4">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Service Name
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Quantity Available
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {availableServices?.map((service, index) => (
                        <tr
                            key={service?.inventoryId}
                            className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                        >
                            <td className="px-6 py-4 whitespace-nowrap">{service?.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                {service?.quantityAvailable}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
