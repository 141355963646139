import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import axios from "axios";
import { tostifyErr, tostifySuccess } from "../../../Screens/Common/tostifyMessages";
import { useSelector } from "react-redux";
import InvoiceDropdown from "../../Invoices/InvoiceModal/InvoiceDropdown";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 4,
};

const CreateAutoConsumption = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        service: "",
        serviceName: "",
        product: "",
        productName: "",
        volume: ""
    });
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const branch = useSelector((state) => state?.main?.branch);
    const allProduct = useSelector((state) =>
        state?.main?.productList?.filter(
            (prod) => prod?.organization?._id === branch?._id
        )
    );
    const allService = useSelector((state) =>
        state?.main?.inventoryService?.filter(
            (ele) => ele?.organizationId?._id === branch?._id
        )
    );
    const handleChange = (e) => {
        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const handleSelectService = (selectedService) => {
        setFormData((prev) => ({
            ...prev,
            service: selectedService._id,
            serviceName: selectedService.name
        }));
    };

    const handleSelectProduct = (selectedProduct) => {
        setFormData((prev) => ({
            ...prev,
            product: selectedProduct._id,
            productName: selectedProduct.name
        }));
    };

    const handleSubmit = async () => {
        const { service, product, volume } = formData;
        if (!service || !product || !volume) {
            tostifyErr("Please fill in all required fields.");
            return;
        }

        setIsLoading(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/issue/add-auto-consumption-stock`,
                {
                    productId: product,
                    volume,
                    serviceId: service,
                },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            if (response.data.success) {
                tostifySuccess(response.data.message);
                props.setOpenAutoConsumption(false);
            } else {
                tostifyErr(response.data.message);
            }
        } catch (error) {
            tostifyErr("Failed to create autoconsumption");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <React.Fragment>
            <Modal
                open={props.openAutoConsumption}
                onClose={() => props.setOpenAutoConsumption(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="max-h-[400px] overflow-x-auto p-4">
                        <div className="flex-auto">
                            <div className="relative w-full mb-3">
                                <div className="text-[#132c4a] block text-sm mb-2">
                                    Select Service
                                    <span className="text-red-600 text-lg"> *</span>
                                </div>
                                <InvoiceDropdown
                                    label="Select Service"
                                    items={allService}
                                    onSelect={handleSelectService}
                                    selectedItem={formData.serviceName}
                                    itemName="name"
                                    className="w-full"
                                />
                            </div>
                        </div>

                        <div className="flex-auto">
                            <div className="relative w-full mb-3">
                                <div className="text-[#132c4a] block text-sm mb-2">
                                    Select Product
                                    <span className="text-red-600 text-lg"> *</span>
                                </div>
                                <InvoiceDropdown
                                    label="Select Product"
                                    items={allProduct}
                                    onSelect={handleSelectProduct}
                                    selectedItem={formData.productName}
                                    itemName="name"
                                    className="w-full"
                                />
                            </div>
                        </div>

                        <div className="flex-auto">
                            <div className="relative w-full mb-3">
                                <div className="text-[#132c4a] block text-sm mb-2">
                                    Volume
                                    <span className="text-red-600 text-lg"> *</span>
                                </div>
                                <input
                                    name="volume"
                                    id="volume"
                                    type="text"
                                    value={formData.volume}
                                    placeholder="Enter Volume"
                                    onChange={handleChange}
                                    className={
                                        0
                                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                    }
                                />
                            </div>
                        </div>

                        <div className="flex justify-end">
                            <button
                                className="bg-primaryHoverColor text-white px-4 py-2 rounded"
                                onClick={handleSubmit}
                                disabled={isLoading}
                            >
                                {isLoading ? "Submitting..." : "Submit"}
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default CreateAutoConsumption;