import React, { useEffect, useState } from "react";
import axios from "axios";
import Tostify from "../../Screens/Common/Tostify";
import {
  decryptData,
  getEncryptItems,
} from "../../Screens/Common/localStorageUtils";
import { ImSearch } from "react-icons/im";
import AddPackages from "./MembershipModals/AddPackages";
import { allMembershipList } from "../../state-management/main/actions";
import { useDispatch, useSelector } from "react-redux";
import SkeletonTableLoader from "../../Screens/Common/Loading/SkeletonTableLoader";
import { RiDeleteBin5Fill, RiEdit2Fill } from "react-icons/ri";
import EditPackage from "./MembershipModals/EditPackage";
import DeletePackage from "./MembershipModals/DeletePackage";
import add from '../../Assets/AddFrame(1).png'
import Dropdown from "../../Screens/Common/Dropdown";
import DownloadTableData from "../../Screens/Common/downloadData";


const MembershipTable = () => {
  const dispatch = useDispatch();
  const [statisticsData, setStatisticsData] = useState([]);
  const [openAddPackageModal, setOpenAddPackageModal] = useState(false);
  const [openDeletePackage, setOpenDeletePackage] = useState(false);
  const [openEditPackage, setOpenEditPackage] = useState(false);
  const [initialValue, setInitialValue] = useState();
  const [id, setId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [membershipType, setMembershipType] = useState("");
  const [results, setResults] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const packages = useSelector(state => state?.main?.membershipList);
  const branch = useSelector(state => state?.main?.branch)?._id;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  let userRole = decryptData()?.user?.role;
  const userRoleName = getEncryptItems("customRoles");
  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = results?.length
    ? results?.slice(
      indexOfFirstItem,
      indexOfLastItem)
    : packages?.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

  const itmesAfterFilter = membershipType && membershipType !== "All" ? currentItems?.filter(ele => ele?.category === membershipType) : currentItems
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const todayDateStr = new Date()?.toISOString()?.slice(0, 10);
  const currentMonth = new Date()?.getMonth();

  let todaysPrice = 0;
  let todaysMembers = [];
  let monthsRevenue = 0;
  let monthsData = 0;

  statisticsData?.forEach(item => {
    const itemDate = new Date(item.date);
    const itemDateStr = itemDate?.toISOString()?.slice(0, 10);

    if (itemDateStr === todayDateStr) {
      todaysPrice += item?.price;
      todaysMembers.push(item);
    }

    if (itemDate.getMonth() === currentMonth) {
      monthsRevenue += item?.price;
      monthsData++;
    }
  });

  useEffect(() => {
    fetchMembershipData();
    fetchMembershipPurchase();
  }, [branch]);

  const fetchMembershipPurchase = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/membership-revenue/allRevenue/${branch}`,
      headers: {
        Authorization: token,
      },
    };

    setIsLoading(true);
    await axios
      .request(config)
      .then((response) => {
        setStatisticsData(response?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (searchItem?.length === 0) {
      setResults([])
    }
  }, [searchItem])

  const handleSearch = (e) => {
    setSearchItem(e.target.value);
    const filteredItems = packages?.filter(
      (item) =>
        item?.packageName
          ?.toLowerCase()
          ?.includes(searchItem?.toLowerCase())
    );
    setResults(filteredItems);
  };

  const handleClear = () => {
    setResults([]);
    setSearchItem("");
  }

  const fetchMembershipData = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/membership-package/get-membership/${branch}`,
      headers: {
        Authorization: token,
      },
    };

    setIsLoading(true);
    await axios
      .request(config)
      .then((response) => {
        dispatch(allMembershipList(response?.data?.data));
        // dispatch(allMemberships(response?.data?.data));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleOpenEdit = (id) => {
    setId(id);
    const initial = packages?.find((memb) => memb._id === id);
    setInitialValue(initial);
    setOpenEditPackage(true);
  };

  const handleSelectMemberhsipType = (value) => {
    setMembershipType(value)
  }

  const rowData = itmesAfterFilter?.map(data => ({
    PakcageName: data?.packageName,
    Category: data?.category,
    Validity: data?.validity,
    Discount: data?.discount,
    Services: data?.services?.reduce((acc, cur) => {
      return acc + (acc ? ' ,' : ' ') + cur.serviceName;
    }, '') || "------",
    Price: data?.price,
    WalletBalance: data?.packagePrice
  }));
  const headerData = [
    { label: "Package Name", fiele: "PackageName" },
    { label: "Category", field: "Category" },
    { label: "Validity", field: "Validity" },
    { label: "WalletBalance", field: "WalletBalance" },
    { label: "Discount", field: "Discount" },
    { label: "Services", field: "Services" },
    { label: "Price", field: "Price" },
  ];
  return (
    <React.Fragment>
      <Tostify />

      <DeletePackage
        openDeletePackage={openDeletePackage}
        setOpenDeletePackage={setOpenDeletePackage}
        fetchMembershipData={fetchMembershipData}
        id={id}
        setId={setId}
      />

      {initialValue && (
        <EditPackage
          openEditPackage={openEditPackage}
          setOpenEditPackage={setOpenEditPackage}
          fetchMembershipData={fetchMembershipData}
          id={id}
          setId={setId}
          initialValue={initialValue}
          handleOpenEdit={handleOpenEdit}
        />
      )}

      {(userRole === "admin" ||
        (userRole === "staff" &&
          userRoleName?.roleId?.permissions?.members?.sidebarMembers)) && (
          <>
            <AddPackages
              membershipType={membershipType}
              openAddPackageModal={openAddPackageModal}
              setOpenAddPackageModal={setOpenAddPackageModal}
              fetchMembershipData={fetchMembershipData}
            />

            <div className="mx-auto mt-4">
              <div className="px-5 py-5 bg-white w-full border-t rounded-t-lg flex flex-wrap justify-between gap-3">
                <div className="flex gap-2 items-center">
                  <div className="font-bold text-2xl tracking-wide">
                    Memberhsip
                  </div>
                  <div>
                    <Dropdown
                      items={['All', 'Fixed Discount', 'Fixed Wallet', 'Service Based']}
                      label="Select Memberhsip Type"
                      onSelect={handleSelectMemberhsipType}
                    />
                  </div>
                </div>

                <div className="flex justify-center mt-2 xs:mt-0">
                  <div className="bg-white rounded-lg flex p-1 relative border border-gray-200 hover:border-primaryHoverColor hover:border-1">
                    <span className="flex items-center justify-center transition ease-in-out duration-300 ml-2">
                      <ImSearch color="gray" size={15} />
                    </span>
                    <input
                      id="searchData"
                      className="border-0 rounded px-4 py-1 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none w-full pr-6"
                      placeholder="Search By Name"
                      type="text"
                      value={searchItem}
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                    {searchItem && (
                      <span
                        className="absolute right-14 top-2 cursor-pointer"
                        onClick={handleClear}
                      >
                        X
                      </span>
                    )}
                  </div>
                </div>

                <div className="font-bold text-2xl tracking-wide flex  justify-end gap-2">
                  <div className="flex flex-row items-center justify-center gap-2 flex-wrap mb-2">
                    <DownloadTableData
                      rowData={rowData}
                      headerData={headerData}
                    />
                    {(userRole === "admin" ||
                      (userRole === "staff" &&
                        userRoleName?.roleId?.permissions?.members
                          ?.createMembershipPackage)) && (

                        <button
                          onClick={() => setOpenAddPackageModal(true)}
                        >
                          {0 ? (
                            <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                          ) : (
                            <span className="flex justify-center items-center">
                              <img
                                src={add}
                                alt="add"
                                className="text-primaryColor w-7 h-7"
                              />
                            </span>
                          )}
                        </button>
                      )}
                  </div>
                </div>
              </div>

              {isLoading ?
                <SkeletonTableLoader /> :
                searchItem && results.length === 0 ?
                  (<div className="bg-white py-4 shadow-md text-center tracking-wider text-xl">
                    No Memberships Found
                  </div>) :
                  (userRole === "admin" ||
                    (userRole === "staff" &&
                      userRoleName?.roleId?.permissions?.members
                        ?.viewMembershipPackage)) && (
                    <div className="shadow rounded-lg overflow-x-auto">
                      <table className="min-w-full leading-normal">
                        <thead>
                          <tr className="bg-gray-100">
                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                              Sl
                            </th>
                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                              Package Name
                            </th>
                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                              Category
                            </th>
                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                              Discount
                            </th>
                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                              Services
                            </th>
                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                              WalletBalance
                            </th>
                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                              Validity
                            </th>
                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                              Price
                            </th>
                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {itmesAfterFilter?.map((v, i) => {
                            return (
                              <tr key={i}>
                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                  <div className="text-gray-900 whitespace-no-wrap">
                                    {currentPage * 10 + i - 9}
                                  </div>
                                </td>

                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                  <div className="text-gray-900 whitespace-no-wrap">
                                    {v?.packageName}{v?.description && ` | (${v.description})`}
                                  </div>
                                </td>

                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                  <div className="text-gray-900 whitespace-no-wrap">
                                    {v?.category}
                                  </div>
                                </td>

                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                  <div className="text-gray-900 whitespace-no-wrap">
                                    {v?.discount}
                                  </div>
                                </td>

                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                  <div className="text-gray-900 whitespace-no-wrap">
                                    {v?.services?.reduce((acc, cur) => {
                                      return acc + (acc ? ' ,' : ' ') + cur.serviceName;
                                    }, '') || "------"}
                                  </div>
                                </td>
                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                  <div className="whitespace-no-wrap flex">
                                    {v?.packagePrice}
                                  </div>
                                </td>
                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                  <div className="whitespace-no-wrap flex">
                                    {v?.validity} {" "} months
                                  </div>
                                </td>
                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                  <div className="whitespace-no-wrap flex">
                                    {v?.price}
                                  </div>
                                </td>
                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                  <div className="whitespace-no-wrap flex">
                                    {(userRole === "admin" ||
                                      (userRole === "staff" &&
                                        userRoleName?.roleId?.permissions?.members
                                          ?.editMembershipPackage)) && (
                                        <RiEdit2Fill
                                          size={15}
                                          onClick={() => {
                                            handleOpenEdit(v?._id);
                                          }}
                                          className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                                        />
                                      )}

                                    {(userRole === "admin" ||
                                      (userRole === "staff" &&
                                        userRoleName?.roleId?.permissions?.permissions?.members
                                          ?.deleteMembers)) && (
                                        <RiDeleteBin5Fill
                                          className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                                          size={15}
                                          onClick={() => {
                                            setId(v?._id);
                                            setOpenDeletePackage(true);
                                          }}
                                        />
                                      )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {itmesAfterFilter?.length !== 0 ? (
                        <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
                          <span className="text-xs xs:text-sm text-gray-900">
                            Showing {indexOfFirstItem + 1} to{" "}
                            {indexOfFirstItem + itmesAfterFilter?.length} of{" "}
                            {results?.length ? results?.length : itmesAfterFilter?.length} Entries
                          </span>
                          <div className="inline-flex mt-2 xs:mt-0">
                            <button
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                              className={`text-sm ${currentPage === 1
                                ? "bg-gray-200"
                                : "bg-gray-300 hover:bg-gray-400"
                                } text-gray-800 font-semibold py-2 px-4 rounded-l`}
                            >
                              Prev
                            </button>
                            <button
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={
                                currentPage ===
                                Math.ceil(results?.length ? results?.length : itmesAfterFilter?.length / itemsPerPage)
                              }
                              className={`text-sm ${currentPage ===
                                Math.ceil(results?.length ? results?.length / itemsPerPage : itmesAfterFilter?.length / itemsPerPage)
                                ? "bg-gray-200"
                                : "bg-gray-300 hover:bg-gray-400"
                                } text-gray-800 font-semibold py-2 px-4 rounded-r`}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
            </div>
          </>
        )}
    </React.Fragment>
  );
};

export default MembershipTable;
