import React, { useEffect, useRef, useState } from "react";
import { FaHandHoldingUsd } from "react-icons/fa";
import { BsBuildingFillCheck } from "react-icons/bs";
import { RiUserUnfollowFill } from "react-icons/ri";
import { FaCreditCard } from "react-icons/fa";
import axios from "axios";
import {
  decryptData,
  getEncryptItems,
} from "../../Screens/Common/localStorageUtils";
import { useDispatch, useSelector } from "react-redux";
import MonthHeaderCards from "./MonthHeaderCards";
import EventCalendar from "./EventCalendar";
import { setAppointmentFromCalendar } from "../../state-management/main/actions";
import Header from "./Header";
import HeaderLoader from "./HeaderLoader";
import DashBoardCard from "./DashBoardCard";

const HeaderCards = () => {
  const main = useSelector((state) => state?.main);
  const appointmentDetailFromCalendar = main?.appointmentDetailFromCalendar;
  const dispatch = useDispatch();
  const [active, setActive] = useState([]);
  const [showAppointments, setShoAppointments] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [weekMonthBtnChange, setWeekMonthBtnChange] = useState("day");
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [todaysStatisticsData, setTodaysStatisticsData] = useState({});
  const [monthsStatisticsData, setMonthsStatisticsData] = useState({});
  const calendarIconRef = useRef(null);
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-CA");
  const branch = useSelector(state => state?.main?.branch) || "";
  let userRole = decryptData()?.user?.role;
  const userRoleName = getEncryptItems("customRoles");
  const todaysAppointment = useSelector(
    (state) => state?.main?.appointmentList
  )?.filter(
    (app) =>
      app?.appointmentDate?.toString()?.substring(0, 10) === formattedDate
  );

  async function getStatistics(date) {
    try {
      if (weekMonthBtnChange === "day") {
        const responseToday = await axios.get(`${process.env.REACT_APP_API_URL}/laser/statistics_for_today/${date}/${branch?._id}`);
        setTodaysStatisticsData(responseToday?.data);
      }

      if (weekMonthBtnChange === "month") {
        const responseMonth = await axios.get(`${process.env.REACT_APP_API_URL}/laser/statistics_for_month/${date}/${branch?._id}`);
        setMonthsStatisticsData(responseMonth?.data);
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    const date = new Date()?.toISOString()?.slice(0, 10);
    if (date && Object.keys(branch)?.length > 0) {
      getStatistics(date);
    }
  }, [branch, visible, weekMonthBtnChange])


  useEffect(() => {
    // fetchAppointment();
    fetchStaffData();
  }, [branch]);

  const fetchStaffData = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/staff/find-staff`,
      headers: {
        Authorization: token,
      },
    };

    setIsLoading(true);
    try {
      const res = await axios.request(config);
      const staffsByAdminId = res?.data?.data.filter(
        (staff) => staff?.organizationId === branch?._id
      );
      const activeStaffs = staffsByAdminId?.filter(
        (staff) => staff.status === "active"
      );
      setActive(activeStaffs);
      setIsLoading(false);
    } catch (error) {
      console.log("Staffs Error:", error);
      setIsLoading(false);
    }
  };


  const handleToggle = () => {
    setVisible((prev) => !prev);
  };

  const toggleCalendarModal = () => {
    setShowCalendarModal(!showCalendarModal);
  };
  const prevDate = () => {
    const newDate = new Date(appointmentDetailFromCalendar?.selectedDate);
    newDate.setDate(appointmentDetailFromCalendar?.selectedDate?.getDate() - 1);
    dispatch(
      setAppointmentFromCalendar({
        ...appointmentDetailFromCalendar,
        selectedDate: newDate,
      })
    );
  };

  const nextDate = () => {
    const newDate = new Date(appointmentDetailFromCalendar?.selectedDate);
    newDate.setDate(newDate?.getDate() + 1);
    dispatch(
      setAppointmentFromCalendar({
        ...appointmentDetailFromCalendar,
        selectedDate: newDate,
      })
    );
  };

  const selectToday = () => {
    dispatch(
      setAppointmentFromCalendar({
        ...appointmentDetailFromCalendar,
        selectedDate: new Date(),
      })
    );
  };

  const dashboardData = [
    { icon: BsBuildingFillCheck, title: "Appointments", value: todaysAppointment?.length || 0, onClick: () => setShoAppointments(prev => !prev) },
    { icon: RiUserUnfollowFill, title: "Active Staffs", value: active?.length || 0 },
    { icon: FaHandHoldingUsd, title: "Today's Revenue", value: todaysStatisticsData?.data?.todaysRevenue || 0 },
    { icon: FaHandHoldingUsd, title: "Today's Cash", value: todaysStatisticsData?.data?.todaysCash || 0 },
    { icon: FaCreditCard, title: "Credit/Debit/UPI", value: todaysStatisticsData?.data?.todaysOnline || 0 },
    { icon: FaHandHoldingUsd, title: "Realized Revenue", value: todaysStatisticsData?.data?.todaysRealizedRevenue || 0 },
    { icon: FaHandHoldingUsd, title: "Net Revenue", value: todaysStatisticsData?.data?.todayNetRevenue || 0 },
    { icon: FaCreditCard, title: "New Clients", value: todaysStatisticsData?.data?.todaysClientsCount || 0 },
    { icon: FaCreditCard, title: "Old Clients", value: todaysStatisticsData?.data?.oldClientsCount || 0 }
  ];

  return (
    <div className={`bg-[#fff] h-auto  rounded-lg px-4 w-full mt-4 shadow`}>
      <div className="overflow-hidden  p-2 rounded-t-lg">
        <Header
          weekMonthBtnChange={weekMonthBtnChange}
          setWeekMonthBtnChange={setWeekMonthBtnChange}
          handleToggle={handleToggle}
          visible={visible}
          calendarIconRef={calendarIconRef}
          prevDate={prevDate}
          nextDate={nextDate}
          selectToday={selectToday}
          toggleCalendarModal={toggleCalendarModal}
          showCalendarModal={showCalendarModal}
        />
      </div>
      {(userRole === "admin" ||
        (userRole === "staff" &&
          userRoleName?.roleId?.permissions?.dashboard?.dashboardStatistics)) &&
        visible && (
          <div>
            {weekMonthBtnChange === "day" && (
              <div style={{ overflowX: "auto" }} className="no-scrollbar">
                <div className="flex gap-4 mb-4">
                  <div className="text-[#2B2F32] bg-[#fff] p-2 rounded-lg">
                    {isLoading ? (
                      <div className="flex gap-4 justify-center">
                        {[...Array(7)]?.map((_, i) => (
                          <HeaderLoader key={i} />
                        ))}
                      </div>
                    ) : (
                      <div
                        style={{ opacity: visible ? 1 : 0 }}
                        className={`flex gap-4 mt-2 ${visible ? "animate-fadeIn" : "animate-fadeOut"} transition-opacity duration-500`}
                      >
                        {dashboardData?.map((item, index) => (
                          <DashBoardCard onClick={() => setShoAppointments(prev => !prev)} key={index} {...item} />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {weekMonthBtnChange === "month" && <MonthHeaderCards
              isLoading={isLoading}
              visible={visible}
              monthsStatisticsData={monthsStatisticsData?.data}
              setShoAppointments={setShoAppointments} />}
          </div>
        )}
      {(userRole === "admin" ||
        (userRole === "staff" &&
          userRoleName?.roleId?.permissions?.dashboard
            ?.dashboardAppointmentList)) &&
        visible &&
        <EventCalendar
          showAppointments={showAppointments}
          setVisible={setVisible} />}
    </div>
  );
};

export default HeaderCards;
