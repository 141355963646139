import axios from "axios";
// Function to fetch service report data
export const fetchServiceReport = async (
  organizationId,
  dateFrom,
  dateTo,
  filterType
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/reports/service-report/${organizationId}`,
      {
        params: { dateFrom, dateTo, filterType },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching service report:", error);
    return {
      success: false,
      message: "Failed to fetch service report data",
      error: error.message,
    };
  }
};

// Function to fetch product report data
export const fetchProductReport = async (
  organizationId,
  dateFrom,
  dateTo,
  filterType
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/reports/product-report/${organizationId}`,
      {
        params: { dateFrom, dateTo, filterType },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching product report:", error);
    return {
      success: false,
      message: "Failed to fetch product report data",
      error: error.message,
    };
  }
};

// Function to fetch membership report data
export const fetchMembershipReport = async (
  organizationId,
  dateFrom,
  dateTo,
  filterType
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/reports/membership-report/${organizationId}`,
      {
        params: { dateFrom, dateTo, filterType },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching membership report:", error);
    return {
      success: false,
      message: "Failed to fetch membership report data",
      error: error.message,
    };
  }
};

// Function to fetch service vs staff report data
export const fetchServiceVsStaffReport = async (
  organizationId,
  dateFrom,
  dateTo,
  filterType
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/reports/serviceVsStaff-report/${organizationId}`,
      {
        params: { dateFrom, dateTo, filterType },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching service vs staff report:", error);
    return {
      success: false,
      message: "Failed to fetch service vs staff report data",
      error: error.message,
    };
  }
};

// Function to fetch product vs staff report data
export const fetchProductVsStaffReport = async (
  organizationId,
  dateFrom,
  dateTo,
  filterType
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/reports/productVsStaff-report/${organizationId}`,
      {
        params: { dateFrom, dateTo, filterType },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching product vs staff report:", error);
    return {
      success: false,
      message: "Failed to fetch product vs staff report data",
      error: error.message,
    };
  }
};
