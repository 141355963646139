import * as actionTypes from "./actionTypes";

export const userLoginData = (userData) => {
  return {
    type: actionTypes.USER_LOGIN_DATA,
    payload: userData,
  };
};

export const allOrgList = (orgList) => {
  return {
    type: actionTypes.ALL_ORG_LIST,
    payload: orgList,
  };
};

export const allManagerList = (managers) => {
  return {
    type: actionTypes.ALL_MANAGER_LIST,
    payload: managers,
  };
};

export const allStaffList = (staffs) => {
  return {
    type: actionTypes.ALL_STAFF_LIST,
    payload: staffs,
  };
};

export const allClientList = (clients) => {
  return {
    type: actionTypes.ALL_CLIENT_LIST,
    payload: clients,
  };
};

export const allInventoryService = (service) => {
  return {
    type: actionTypes.ALL_INVENTORY_SERVICE,
    payload: service,
  };
};
export const allMembershipList = (membershipList) => {
  return {
    type: actionTypes.All_MEMBERSHIPS_LIST,
    payload: membershipList,
  };
};

export const allLaserList = (lasers) => {
  return {
    type: actionTypes.ALL_LASER_LIST,
    payload: lasers,
  };
};

export const allDiscountList = (discounts) => {
  return {
    type: actionTypes.ALL_DISCOUNT_LIST,
    payload: discounts,
  };
};

export const allAppointmentList = (appoints) => {
  return {
    type: actionTypes.ALL_APPOINTMENT_LIST,
    payload: appoints,
  };
};

export const allBillList = (bills) => {
  return {
    type: actionTypes.ALL_BILL_LIST,
    payload: bills,
  };
};

export const logOutUser = () => {
  return {
    type: actionTypes.LOGOUT_USER,
  };
};

export const allMembershipRevenue = (revenue) => {
  return {
    type: actionTypes.ALL_MEMBERSHIP_REVENUE,
    payload: revenue,
  };
};

export const allSupplierList = (suppliers) => {
  return {
    type: actionTypes.ALL_SUPPLIER_LIST,
    payload: suppliers,
  };
};

export const allProductList = (product) => {
  return {
    type: actionTypes.ALL_PRODUCT_LIST,
    payload: product,
  };
};

export const allCustomerWithMembership = (servicData) => {
  return {
    type: actionTypes.ALL_CUSTOMER_WITH_MEMBERSHIP,
    payload: servicData,
  };
};

export const allPackageList = (packageData) => {
  return {
    type: actionTypes.ALL_PACKAGE_LIST,
    payload: packageData,
  };
};

export const serviceVsStaff = (data) => {
  return {
    type: actionTypes.SERVICE_VS_STAFF,
    payload: data,
  };
};

export const setBillForm = (data) => {
  return {
    type: actionTypes.BILL_FORM,
    payload: data,
  };
};

export const clearBillForm = () => {
  return {
    type: actionTypes.CLEAR_BILL_FORM,
  };
};

export const clearNewBill = () => {
  return {
    type: actionTypes.CLEAR_NEW_BILL,
  };
};

export const clearClient = () => {
  return {
    type: actionTypes.CLEAR_CLIENT_WHILE_BILLING,
  };
};

export const clearDraftBill = () => {
  return {
    type: actionTypes.CLEAR_DRAFT_BILL,
  };
};

export const storeNewBill = (data) => {
  return {
    type: actionTypes.NEW_CREATED_BILL,
    payload: data,
  };
};

export const storeDraftBill = (data) => {
  return {
    type: actionTypes.DRAFT_BILL,
    payload: data,
  };
};
export const storeClient = (data) => {
  return {
    type: actionTypes.CLIENT_WHILE_BILLING,
    payload: data,
  };
};
export const setAppointmentFromCalendar = (data) => {
  return {
    type: actionTypes.APPOINTMENT_FROM_CALENDAR,
    payload: data,
  };
};

export const setBranch = (data) => {
  return {
    type: actionTypes.SET_BRANCH,
    payload: data,
  };
};

export const setIsInvoiceForEdit = (data) => {
  return {
    type: actionTypes.IS_INVOICE_FOR_EDIT,
    payload: data,
  };
};
