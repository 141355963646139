import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Plus from "../../../Assets/billAdd.png";
import minus from "../../../Assets/billRemove.png";
import edit from "../../../Assets/edit.png";
import recyclebin from "../../../Assets/recyclebin.png";
import {
  decryptData,
} from "../../../Screens/Common/localStorageUtils";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  allBillData,
  fetchCustomerWithMembership,
  handleSell,
  updateBilling,
  updateCustomerWallet,
} from "../../../Screens/Common/initData";
import {
  allBillList,
  allCustomerWithMembership,
  storeClient,
  storeDraftBill,
} from "../../../state-management/main/actions";
import { tostifyErr, tostifySuccess } from "../../../Screens/Common/tostifyMessages";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "800px",
  maxHeight: "80%",
  height: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  outline: "none",
  p: 4,
  overflowY: "auto",
};

const PaymentModal = ({
  isOpen,
  onClose,
  setPaymentStatus,
  paymentStatus,
  isWalletApplied
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const draftBill = useSelector(state => state?.main?.draftBill);
  const clientDuringBill = useSelector(state => state?.main?.clientDuringBill);
  const initialPaymentMethod = {
    id: 1,
    payment: "",
    paymentDate: new Date()?.toISOString()?.slice(0, 10),
    amount: "",
  };
  const [paymentMethods, setPaymentMethods] = useState([
    initialPaymentMethod
  ]);

  useEffect(() => {
    if (draftBill?.paymentMethods) {
      const mergedPaymentMethods = [...paymentMethods];

      draftBill?.paymentMethods?.forEach((newMethod) => {
        const exists = mergedPaymentMethods.some(
          (method) => method.id === newMethod.id && method.payment === newMethod.payment
        );

        if (!exists) {
          mergedPaymentMethods.push(newMethod);
        }
      });

      setPaymentMethods(mergedPaymentMethods);
    }
  }, [draftBill?.paymentMethods]);

  const token = "Bearer " + localStorage.getItem("refresh_token");
  const BillData = useSelector(state => state?.main?.billForm);
  const dispatch = useDispatch();
  const branch = useSelector(state => state?.main?.branch) || "";

  const getCustmerWithMembership = async () => {
    const data = await fetchCustomerWithMembership();
    dispatch(allCustomerWithMembership(data));
  };

  const totalServiceCharge = draftBill?.freeServicesApplied ? draftBill?.serviceBilledOn?.reduce(
    (acc, service) => acc + (service.price),
    0
  ) : draftBill?.services?.reduce(
    (acc, service) => acc + (service?.price),
    0
  );
  const allServiceCharge = BillData?.requiredServices?.reduce(
    (acc, service) => acc + service.price,
    0
  )
  console.log("BillData=======>", BillData)
  const totalServiceChargeBeforeFree = draftBill?.serviceToBeBilled?.reduce((acc, service) => acc + service.price, 0);
  const freeServiceAmount = Number(allServiceCharge || 0) - Number(totalServiceChargeBeforeFree || 0);

  const totalProductCharge = draftBill?.products?.reduce((acc, product) => acc + Number(product.price), 0);
  const totalMembershipPrice = draftBill?.membershipIds?.[0]?.price || 0;

  const getBillData = async () => {
    const data = await allBillData();
    dispatch(allBillList(data));
  };

  const handleChange = (e, index, name) => {
    setPaymentError("");
    const { value } = e.target;
    const updatedPaymentMethods = [...paymentMethods];
    if (name === "payment") {
      updatedPaymentMethods[index] = {
        ...updatedPaymentMethods[index],
        payment: value,
      };
    } else if (name === "paymentDate") {
      updatedPaymentMethods[index] = {
        ...updatedPaymentMethods[index],
        paymentDate: value,
      };
    } else if (name === "amount") {
      const amount = parseInt(value);
      updatedPaymentMethods[index] = {
        ...updatedPaymentMethods[index],
        amount: amount,
      };
    }
    setPaymentMethods(updatedPaymentMethods);
  };

  const payments = paymentMethods?.map((method) => ({
    paymentMethod: method.payment,
    amount: method.amount,
    paymentDate: method.paymentDate,
  }));
  const allPayment =
    Number(
      payments?.reduce((acc, item) => {
        acc += Number(item.amount || 0);
        return acc;
      }, 0)
    );
  console.log("draftBill?.membershipIds", draftBill?.membershipIds)
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (allPayment === 0) {
      setPaymentError("Please Enter Payment Amount");
      return;
    }

    const hasIncompletePayment = paymentMethods?.some(method => {
      return !method.payment || !method.amount || !method.paymentDate;
    });

    if (hasIncompletePayment) {
      setPaymentError("Please Enter Payment Details");
      return;
    }

    const dueAmountForBill = draftBill?.walletApplied > 0 || draftBill?.freeServicesApplied ?
      Math.max(clientDuringBill?.dueAmount - (allPayment - draftBill?.walletApplied))
      : Math.max(Number((draftBill?.finalPrice || 0) + clientDuringBill?.dueAmount - allPayment), 0)
    setIsFormFilled(true);
    setIsLoading(true);
    const updatedBill = await updateBilling(draftBill?._id,
      {
        ...draftBill,
        dueAmount: dueAmountForBill,
        // dueAmount: Math.max(
        //   Number(draftBill?.finalPrice - allPayment - (draftBill?.freeServicesApplied ? freeServiceAmount : 0)), 0),
        gstPercentage: `0`,
        paymentStatus: dueAmountForBill > 0 ? "due" : "paid",
        totalServiceCharge: `${totalServiceCharge}`,
        gstChargeAmount: 0,
        paidAmount: `${allPayment}`,
        paymentMethods: paymentMethods,
        serviceBilledOn: BillData?.serviceToBeBilled,
        freeServices: BillData?.freeServices,
        freeServicesApplied: BillData?.freeServicesApplied,
        servicesIncludedInMembership: BillData?.freeServicesApplied ? BillData?.servicesIncludedInMembership : [],
        isDraftInvoice: false,
      })
    const duringDueAmount = draftBill?.walletApplied > 0 || draftBill?.freeServicesApplied ?
      clientDuringBill?.dueAmount - (allPayment - draftBill?.walletApplied)
      : Math.max(Number((draftBill?.finalPrice || 0) + clientDuringBill?.dueAmount - allPayment), 0);
    // const duringDueAmount = draftBill?.walletApplied > 0 ?
    //   clientDuringBill?.dueAmount - (allPayment - draftBill?.walletApplied)
    //   : Math.max(Number((draftBill?.finalPrice || 0) + clientDuringBill?.dueAmount - allPayment), 0);
    // console.log("duringDueAmount", duringDueAmount);
    // return;
    const updatedClient = await updateCustomerWallet(draftBill?.walletApplied > 0 || draftBill?.freeServicesApplied ?
      (clientDuringBill?.walletBalance || 0)
      : Number(clientDuringBill?.walletBalance || 0) + Number(BillData?.membershipAray?.[0]?.packagePrice || 0),
      clientDuringBill._id, duringDueAmount);
    dispatch(storeClient(updatedClient));
    await getCustmerWithMembership();
    await getBillData();
    if (draftBill?.products?.length) { await sellProducts() }
    if (draftBill?.service?.length) { await handleSendNotification(); }
    // if (draftBill?.freeServicesApplied) {
    //   await adjustServices(updatedBill?.customer);
    // }
    if (draftBill?.membershipIds?.length) {
      await handleCreateMembershipRevenue(updatedBill?.customer)
    }
    setIsLoading(false);
    onClose();
    dispatch(storeDraftBill(updatedBill))
    setPaymentStatus("completed")
  };

  const sellProducts = async () => {
    const createdBy =
      decryptData()?.user?.role === "admin"
        ? {
          name: decryptData()?.user?.firstName + " " + decryptData()?.user?.lastName,
          adminId: decryptData()?.userTypeData?._id,
        }
        : {
          name: decryptData()?.user?.firstName + decryptData()?.user?.lastName,
          staffId: decryptData()?.userTypeData?._id,
        };

    for (let i = 0; i < draftBill?.products?.length; i++) {
      await handleSell({
        product: draftBill?.products[i],
        createdBy: createdBy,
        draftBill: draftBill,
        branch: branch,
      });
    }
  };

  const handleCreateMembershipRevenue = async (customerId) => {
    try {
      const data = JSON.stringify({
        customerId: customerId,
        adminId: branch?.adminId,
        membershipPackageId: draftBill?.membershipIds?.[0]?._id,
        price: draftBill?.membershipIds?.[0]?.price,
        packagePrice: draftBill?.membershipIds?.[0]?.packagePrice,
        organizationId: branch?._id,
        date: BillData?.membership?.startDate,
        expireDate: BillData?.membership?.expireDate,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/membership-revenue/add`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      };

      const response = await axios.request(config);
    } catch (error) {
      console.error(error);
      tostifyErr(error?.response?.data?.message);
    }
  };

  const handleSendNotification = async () => {
    let data = JSON.stringify({
      organizationId: branch?._id,
      adminId: branch?._id,
      services: draftBill?.services,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/notification/add`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        tostifySuccess("Notification sent to Staff")
      })
      .catch((error) => {
        console.log(error);
        tostifyErr(error?.response?.data?.message);
      });

  };

  const handleAddPayment = (e) => {
    e.preventDefault();
    setPaymentMethods([
      ...paymentMethods,
      {
        id: new Date().getTime(),
        payment: "",
        paymentDate: new Date()?.toISOString()?.slice(0, 10),
        amount: "",
      },
    ]);
  };

  const removePaymentField = (index, method) => {
    if (paymentMethods?.length === 1) {
      return;
    }
    if (paymentMethods?.length === 2 && paymentMethods?.find(ele => ele?.payment === "WalletBalance")) {
      return;
    }
    const updatedMethods = paymentMethods?.filter((method, i) => i !== index);
    setPaymentMethods(updatedMethods);
  };
  const adjustServices = async (id) => {
    let data = JSON.stringify({
      remainingServices: BillData?.remainingServices,
      customerId: id,
    });

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/customer/adjustServices`,
      headers: {
        'Authorization': token, 'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
      });

  }
  return (
    <React.Fragment>
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={style} className="no-scrollbar">
          <div className="mx-auto">
            <div className="btn-wrapper text-center">
              <span
                className="absolute top-6 right-5 text-3xl cursor-pointer"
                onClick={() => {
                  setIsFormFilled(false);
                  if (paymentStatus === "completed") {
                    setPaymentMethods([{
                      id: 1,
                      payment: "",
                      paymentDate: "",
                      amount: "",
                    }])
                  }
                  onClose()
                }}
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold text-center">
                Add Payment Method To Pay:{" "}
                <span className="text-red-400">{draftBill?.walletApplied || paymentStatus === "completed" || draftBill?.freeServicesApplied
                  ? clientDuringBill?.dueAmount
                  : (draftBill?.finalPrice || 0) + (clientDuringBill?.dueAmount || 0) - draftBill?.paymentMethods?.reduce((acc, cur) => acc + parseFloat(cur.amount), 0)}</span>
                {/* <span className="text-red-400">{(clientDuringBill?.dueAmount || 0) - draftBill?.paymentMethods?.reduce((acc, cur) => acc + parseFloat(cur.amount), 0)}</span> */}
              </div>
            </div>
            <div className="flex md:flex-row flex-col justify-center items-center gap-2 md:gap-8">
              <div className="flex gap-2">
                <p className="text-[20px] font-bold">
                  {" "}
                  Wallet Balance:
                  <span
                    className={`text-red-400  ${Number(BillData?.membershipAray?.[0]?.packagePrice) === 0 ? "text-gray-400" : ""
                      }`}
                  >
                    {clientDuringBill?.walletBalance}
                  </span>
                </p>
              </div>
              <div className="flex gap-2">
                <p className="text-[20px] font-bold ">
                  Due Amount:
                  <span className="text-red-400">{clientDuringBill?.dueAmount || 0}</span>
                </p>
              </div>
            </div>
            <div
              // onSubmit={handleSubmit}
              className="mt-4">
              {paymentMethods?.map((method, index) => (
                <div key={method.id} className="flex flex-col">
                  {method.payment !== "WalletBalance" && (
                    <div className="mb-4 flex flex-col md:flex-row md:items-center md:gap-2">
                      <div className="w-full md:w-1/3">
                        <label
                          className="text-[#132c4a] block text-sm mb-2"
                          htmlFor={`payment-${index}`}
                        >
                          Payment Method
                        </label>
                        <select
                          className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full"
                          id={`payment-${index}`}
                          name={`payment-${index}`}
                          value={method.payment}
                          onChange={(e) => handleChange(e, index, "payment")}
                        >
                          <option value="">Select</option>
                          <option value="g-pay">Google Pay</option>
                          <option value="phone-pay">Phone Pay</option>
                          <option value="paytm">Paytm</option>
                          {/* <option value="WalletBalance">WalletBalance</option> */}
                          <option value="Creditcard">Credit Card</option>
                          <option value="Debitcard">Debit Card</option>
                          <option value="Cash">Cash</option>
                          <option value="Card">Card</option>
                          <option value="UPI">UPI</option>
                        </select>
                      </div>
                      <div className="w-full md:w-1/3">
                        <label
                          className="text-[#132c4a] block text-sm mb-2"
                          htmlFor={`paymentDate-${index}`}
                        >
                          Date
                        </label>
                        <input
                          className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full"
                          type="date"
                          id={`paymentDate-${index}`}
                          name={`paymentDate-${index}`}
                          value={method.paymentDate}
                          onChange={(e) => handleChange(e, index, "paymentDate")}
                        />
                      </div>
                      <div className="w-full md:w-1/3">
                        <label
                          className="text-[#132c4a] block text-sm mb-2"
                          htmlFor={`amount-${index}`}
                        >
                          Amount
                        </label>
                        <input
                          className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full"
                          id={`amount-${index}`}
                          name={`amount-${index}`}
                          type="number"
                          value={method.amount}
                          onChange={(e) => handleChange(e, index, "amount")}
                        />
                      </div>
                    </div>
                  )}
                  {method.payment !== "WalletBalance" && <div className="flex justify-end gap-4">
                    <img
                      src={Plus}
                      width={30}
                      height={30}
                      alt="Add"
                      onClick={handleAddPayment}
                      className="cursor-pointer"
                    />
                    <img
                      src={minus}
                      width={30}
                      height={30}
                      alt="Remove"
                      onClick={() => removePaymentField(index, method)}
                      className="cursor-pointer"
                    />
                  </div>}
                </div>
              ))}

              <div className="flex md:flex-row flex-col justify-between items-center mt-4">
                <div className="flex flex-col">
                  <div className="flex items-center gap-3">
                    <p className="text-lg">
                      Bill Amount:{" "}
                      <span className="text-green-400">
                        {console.log("checking", draftBill?.freeServicesApplied, totalServiceCharge, allServiceCharge)}
                        {Math.max(0, ((totalServiceCharge || 0) + (totalProductCharge || 0) + (totalMembershipPrice || 0)))
                          // - (draftBill?.paymentMethods?.reduce((acc, cur) => acc + parseFloat(cur.amount), 0) || 0))
                        }</span>
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <p className="text-lg">
                      Total Amount (Including Previous Due):{" "}
                      {/* <span className="text-green-400">{totalServiceCharge + totalProductCharge + totalMembershipPrice + (clientDuringBill?.previousDueAmount || 0)
                        - draftBill?.paymentMethods?.reduce((acc, cur) => acc + parseFloat(cur.amount), 0)}</span> */}
                      <span className="text-green-400">{draftBill?.walletApplied || paymentStatus === "completed" || draftBill?.freeServicesApplied
                        ? clientDuringBill?.dueAmount
                        : (totalServiceCharge || 0) +
                        (totalProductCharge || 0) +
                        (totalMembershipPrice || 0) +
                        (clientDuringBill?.dueAmount || 0) - (allPayment || 0)
                      }</span>
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <p className="text-lg">
                      Pending Amount:{" "}
                      <span className="text-red-400">
                        {draftBill?.walletApplied || paymentStatus === "completed" || draftBill?.freeServicesApplied
                          ? clientDuringBill?.dueAmount
                          : Math.max(
                            0,
                            Number(
                              (totalServiceCharge || 0) +
                              (totalProductCharge || 0) +
                              (totalMembershipPrice || 0) +
                              (isWalletApplied ? 0 : clientDuringBill?.previousDueAmount || 0) -
                              // (clientDuringBill?.previousDueAmount || 0) -
                              (allPayment || 0)
                            )
                          )}
                      </span>

                    </p>
                    {paymentError && <span className="text-red-500 text-md tracking-wide">{paymentError}</span>}
                  </div>
                </div>
              </div>

              {isFormFilled && (
                <div className="overflow-x-auto mt-4 no-scrollbar">
                  <table className="w-full mb-4 border-collapse border border-gray-300 overflow-x-auto">
                    <thead>
                      <tr>
                        <th className="border border-gray-300 px-4 py-2">
                          Payment Method
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Date
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Reference
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Amount
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentMethods?.map((method, index) => (
                        <tr key={method.id}>
                          <td className="border border-gray-300 px-4 py-2">
                            {method?.payment}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {method?.paymentDate}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {BillData?.name}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {method?.amount}
                          </td>
                          <td className="border border-gray-300 px-4 py-2 flex gap-2">
                            <img
                              src={edit}
                              alt="Edit"
                              style={{ width: "20px", height: "20px" }}
                            />
                            <button>
                              <img
                                src={recyclebin}
                                alt="Delete"
                                style={{ width: "20px", height: "20px" }}
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="w-full flex items-center justify-center mt-8">
                <button
                  // onClick={() => sellProducts()}
                  onClick={(e) => handleSubmit(e)}
                  disabled={isLoading || paymentStatus == "completed"}
                  className={`h-12 w-1/5 flex items-center justify-center text-lg font-semibold text-white ${paymentStatus == "completed"
                    ? "bg-green-500"
                    : "bg-primaryColor hover:bg-primaryHoverColor"
                    } rounded-xl shadow-lg transition ease-in-out duration-300`}
                >
                  {isLoading ? (
                    <div className="w-8 h-8 rounded-full animate-spin border-b-4 border-dashed border-white border-t-transparent"></div>
                  ) : (
                    <span>{paymentStatus == "completed" ? "Paid" : "Pay"}</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default PaymentModal;
