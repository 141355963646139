import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import {
  tostifyErr,
  tostifySuccess,
} from "../../../Screens/Common/tostifyMessages";
import { decryptData } from "../../../Screens/Common/localStorageUtils";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  outline: "none",
  p: 4,
};

const EditPackage = (props) => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");
  const [price, setPrice] = useState("");
  const [priceErr, setPriceErr] = useState("");
  const [packagePrice, setPackagePrice] = useState("");
  const [packagePriceErr, setPackagePriceErr] = useState("");
  const [validity, setValidity] = useState("");
  const [validityErr, setValidityErr] = useState("");
  const [noOfService, setNoOfService] = useState("");
  const [noOfServiceErr, setNoOfServiceErr] = useState("");

  useEffect(() => {
    setName(props?.initialValue?.packageName || "");
    setPrice(props?.initialValue?.price || "");
    setPackagePrice(props?.initialValue?.packagePrice || 0);
    setValidity(props?.initialValue?.validity || "");
    setNoOfService(props?.initialValue?.numberOfServices || "");
    setDescription(props?.initialValue?.description || "");
  }, [props?.handleOpenEdit]);

  const clearInputField = () => {
    setName("");
    setNameErr("");
    setPrice("");
    setPriceErr("");
    setPackagePrice("");
    setPackagePriceErr("");
    setValidity("");
    setValidityErr("");
    setNoOfService("");
    setNoOfServiceErr("");
    setDescription("");
    setDescriptionErr("");
  };

  const handleUpdatePackage = async (e) => {
    e.preventDefault();
    if (!name) {
      setNameErr(true);
    } else if (!price) {
      setPriceErr(true);
    } else if (packagePrice === "") {
      setPackagePriceErr(true);
    } else if (!validity) {
      setValidityErr(true);
    } else {
      let data = JSON.stringify({
        packageName: name,
        price: price,
        validity: validity,
        packagePrice: packagePrice,
        numberOfServices: noOfService,
        description: description
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/membership-package/update/${props.id}`,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: data,
      };
      setIsLoading(true);
      await axios
        .request(config)
        .then((response) => {
          props.fetchMembershipData();
          tostifySuccess(response?.data?.message);
          props.setOpenEditPackage(false);
          clearInputField();
          setIsLoading(false);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("edit Product error:", error);
          props.setOpenEditPackage(false);
          tostifyErr(error?.response?.data?.message);
          clearInputField();
          setIsLoading(false);
        });
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={props.openEditPackage}
        onClose={() => {
          clearInputField();
          props.setOpenEditPackage(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <div className="no-scrollbar max-h-[400px] min-w-[400px] sm:min-w-[450px] overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() => props.setOpenEditPackage(false)}
                className="absolute top-6  right-5 text-3xl  cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">
                Edit Package Details
              </div>
            </div>

            <div className="flex-auto mt-2">
              <form onSubmit={(e) => handleUpdatePackage(e)}>
                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="name"
                    autoComplete="off"
                    value={name}
                    onChange={(e) => {
                      setNameErr(false);
                      setName(e.target.value);
                    }}
                    className={
                      nameErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Package Name"
                  ></input>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Description
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="description"
                    autoComplete="off"
                    value={description}
                    onChange={(e) => {
                      setDescriptionErr(false);
                      setDescription(e.target.value);
                    }}
                    className={
                      descriptionErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Description"
                  ></input>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Price
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="price"
                    value={price}
                    onChange={(e) => {
                      setPriceErr(false);
                      setPrice(e.target.value);
                    }}
                    className={
                      priceErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Price"
                  ></input>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Package Price
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="packagePrice"
                    value={packagePrice}
                    onChange={(e) => {
                      setPackagePriceErr(false);
                      setPackagePrice(e.target.value);
                    }}
                    className={
                      packagePriceErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Package Price"
                  ></input>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Package Validity
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="validity"
                    value={validity}
                    onChange={(e) => {
                      setValidityErr(false);
                      setValidity(e.target.value);
                    }}
                    className={
                      validityErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Validity"
                  ></input>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Number Of Service
                    {/* <span className="text-red-600 text-lg"> *</span> */}
                  </div>
                  <input
                    type="text"
                    id="serives"
                    value={noOfService}
                    onChange={(e) => {
                      setNoOfServiceErr(false);
                      setNoOfService(e.target.value);
                    }}
                    className={
                      noOfServiceErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Number Of Service"
                  ></input>
                </div>

                <div className="w-full flex items-center justify-center mt-5">
                  <button
                    disabled={isLoading}
                    className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                  >
                    {isLoading ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Update</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default EditPackage;
