import React, { useEffect, useState } from "react";
import { ImSearch } from "react-icons/im";
import { RiEdit2Fill, RiDeleteBin5Fill } from "react-icons/ri";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  decryptData,
  getEncryptItems,
} from "../../Screens/Common/localStorageUtils";
import AddSupplier from "./Products Modal/Supplier Modal/AddSupplier";
import DeleteSupplier from "./Products Modal/Supplier Modal/DeleteSupplier";
import EditSupplier from "./Products Modal/Supplier Modal/EditSupplier";
import { allSupplierList } from "../../state-management/main/actions";
import DownloadTableData from "../../Screens/Common/downloadData";
import add from '../../Assets/AddFrame(1).png'
import SkeletonTableLoader from "../../Screens/Common/Loading/SkeletonTableLoader";

const Supplier = () => {
  const [openDeleteSupplier, setOpenDeleteSupplier] = useState(false);
  const [openEditSupplier, setOpenEditSupplier] = useState(false);
  const [openAddSupplier, setOpenAddSupplier] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialValue, setInitialValue] = useState();
  const [searchData, setSearchData] = useState("");
  const [clicked, setClicked] = useState(false);
  const [results, setResults] = useState([]);
  const [id, setId] = useState("");
  const main = useSelector((state) => state?.main);
  const token = "Bearer " + localStorage.getItem("refresh_token");
  let userRole = decryptData()?.user?.role;
  const userRoleName = getEncryptItems("customRoles");
  const branch = useSelector(state => state?.main?.branch) || getEncryptItems("branchDetails");
  const dispatch = useDispatch();
  const filteredSuppliers = main?.supplierList?.filter(
    (item) => item?.organizationId === branch?._id
  );

  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredSuppliers?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(filteredSuppliers?.length / itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    fetchSupplier();
  }, [branch]);

  const handleOpenEdit = (id) => {
    setId(id);
    const initial = main?.supplierList?.find((product) => product?._id === id);
    setInitialValue(initial);
    setOpenEditSupplier(true);
  };

  const fetchSupplier = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/supplier/find-supplier`,
      headers: {
        Authorization: token,
      },
    };

    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        dispatch(allSupplierList(response?.data?.data));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchData(e.target.value);
    const result = filteredSuppliers?.filter((item) =>
      item?.name?.toLowerCase()?.includes(e.target.value?.toLowerCase())
    );
    setClicked(true);
    setResults(result);
  };

  const handleClear = () => {
    setSearchData("");
    setClicked(false);
    setResults([]);
  };

  const headerData = [
    { label: "Name", field: "Name" },
    { label: "Phone", field: "Phone" },
    { label: "Status", field: "Status" },
  ];
  const rowData = results?.length ? results?.map(data => ({
    Name: data?.name,
    Phone: data?.phone,
    Status: data?.isActive ? "Active" : "InActive"
  })) : filteredSuppliers?.map(data => ({
    Name: data?.name,
    Phone: data?.phone,
    Status: data?.isActive ? "Active" : "InActive"
  }));
  const canEditOrDeleteSupplier = userRole === "staff" &&
    (userRoleName?.roleId?.permissions?.products?.editSupplier || userRoleName?.roleId?.permissions?.products?.deleteSupplier);
  return (
    <React.Fragment>
      <AddSupplier
        fetchSupplier={fetchSupplier}
        openAddSupplier={openAddSupplier}
        setOpenAddSupplier={setOpenAddSupplier}
      />

      <DeleteSupplier
        openDeleteSupplier={openDeleteSupplier}
        setOpenDeleteSupplier={setOpenDeleteSupplier}
        fetchSupplier={fetchSupplier}
        id={id}
        setId={setId}
      />

      {initialValue && (
        <EditSupplier
          setOpenEditSupplier={setOpenEditSupplier}
          openEditSupplier={openEditSupplier}
          fetchSupplier={fetchSupplier}
          handleOpenEdit={handleOpenEdit}
          initialValue={initialValue}
          setId={setId}
          id={id}
        />
      )}

      <div className="mx-auto mt-4">
        <div className="px-5 py-5 bg-white w-full border-t rounded-t-lg grid grid-flow-row md:grid-flow-col justify-between">
          <div className="font-bold text-2xl tracking-wide w-full">
            Suppliers
          </div>

          <div className="flex w-full justify-center mt-2 xs:mt-0">
            <div className="bg-white rounded-lg w-full xs:w-72 flex p-1 relative border border-gray-200 hover:border-primaryHoverColor hover:border-1">
              <span className="flex items-center justify-center transition ease-in-out duration-300 ml-2">
                <ImSearch color="gray" size={15} />
              </span>
              <input
                id="searchData"
                className="border-0 rounded px-4 py-1 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none w-full pr-6"
                placeholder="Search Supplier"
                type="text"
                value={searchData}
                onChange={(e) => {
                  setCurrentPage(1);
                  handleSearch(e);
                }}
              />
              {searchData && (
                <span
                  className="absolute right-14 top-2 cursor-pointer"
                  onClick={handleClear}
                >
                  X
                </span>
              )}
            </div>
          </div>

          <div className="font-bold text-2xl tracking-wide w-full flex  justify-end gap-2">
            <div className="flex flex-row items-center justify-center gap-2 flex-wrap mb-2">
              <DownloadTableData
                rowData={rowData}
                headerData={headerData}
              />
              {(userRole === "admin" ||
                (userRole === "staff" &&
                  userRoleName?.roleId?.permissions?.products?.createSupplier)) && (
                  <>
                    <button
                      onClick={() => setOpenAddSupplier(true)}
                    >
                      {0 ? (
                        <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                      ) : (
                        <span className="flex justify-center items-center">
                          <img
                            src={add}
                            alt="add"
                            className="text-primaryColor w-7 h-7"
                          />
                        </span>
                      )}
                    </button>
                  </>
                )}
            </div>
          </div>
        </div>
        {isLoading ? (
          <SkeletonTableLoader />
        ) : filteredSuppliers?.length === 0 ? (
          <div className="flex justify-center bg-[#fff] shadow-md py-4">
            No Supplier found
          </div>
        ) : searchData && results.length > 0 ? (
          <div className="shadow rounded-t-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Sl
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Name
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Mobile
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Status
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-sm font-semibold text-gray-600 tracking-wide">
                    {(userRole === "admin" || canEditOrDeleteSupplier) ? (
                      <span>Actions</span>
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {results?.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {i + 1}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.name}
                        </div>
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.phone}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.isActive ? "Active" : "InActive"}
                        </div>
                      </td>
                      <td className="px-5 py-5 border flex justify-center border-gray-200 bg-white text-sm">
                        <div className="whitespace-no-wrap flex">
                          {(userRole === "admin" ||
                            (userRole === "staff" &&
                              userRoleName?.roleId?.permissions?.products
                                ?.editSupplier)) && (
                              <RiEdit2Fill
                                size={15}
                                onClick={() => {
                                  handleOpenEdit(v?._id);
                                }}
                                className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                              />
                            )}

                          {(userRole === "admin" ||
                            (userRole === "staff" &&
                              userRoleName?.roleId?.permissions?.products
                                ?.deleteSupplier)) && (
                              <RiDeleteBin5Fill
                                className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                                size={15}
                                onClick={() => {
                                  setId(v?._id);
                                  setOpenDeleteSupplier(true);
                                }}
                              />
                            )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : searchData && results.length === 0 && clicked ? (
          <div className="flex justify-center bg-[#fff] w-full shadow-md py-4">
            No Product found
          </div>
        ) : (
          <div className="shadow rounded-t-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Sl
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Name
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Mobile
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Status
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-sm font-semibold text-gray-600 tracking-wide">
                    {(userRole === "admin" || canEditOrDeleteSupplier) ? (
                      <span>Actions</span>
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {i + 1}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.name}
                        </div>
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.phone}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.isActive ? "Active" : "InActive"}
                        </div>
                      </td>
                      <td className="px-5 py-5 border flex justify-center border-gray-200 bg-white text-sm">
                        <div className="whitespace-no-wrap flex">
                          {(userRole === "admin" ||
                            (userRole === "staff" &&
                              userRoleName?.roleId?.permissions?.products
                                ?.editSupplier)) && (
                              <RiEdit2Fill
                                size={15}
                                onClick={() => {
                                  handleOpenEdit(v?._id);
                                }}
                                className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                              />
                            )}

                          {(userRole === "admin" ||
                            (userRole === "staff" &&
                              userRoleName?.roleId?.permissions?.products
                                ?.deleteSupplier)) && (
                              <RiDeleteBin5Fill
                                className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                                size={15}
                                onClick={() => {
                                  setId(v?._id);
                                  setOpenDeleteSupplier(true);
                                }}
                              />
                            )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {(results?.length === 0 && filteredSuppliers?.length === 0) ||
          (searchData && results?.length === 0 && clicked) ? null : (
          <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {indexOfFirstItem + 1} to{" "}
              {results?.length
                ? indexOfFirstItem + results?.length
                : indexOfFirstItem + currentItems?.length}{" "}
              of {results?.length ? results?.length : filteredSuppliers?.length} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`text-sm ${currentPage === 1
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-l`}
              >
                Prev
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil(filteredSuppliers?.length / itemsPerPage)
                }
                className={`text-sm ${currentPage ===
                  Math.ceil(filteredSuppliers?.length / itemsPerPage)
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-r`}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Supplier;
