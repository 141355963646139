import React, { useState, useEffect } from "react";
import { ImSearch } from "react-icons/im";
import { RiEdit2Fill, RiDeleteBin5Fill } from "react-icons/ri";
import { MdInventory2 } from "react-icons/md";
import AddServices from "./InventoryModal/AddServices";
import Category from "./InventoryModal/Label/Category";
import Tostify from "../../Screens/Common/Tostify";
import DeleteService from "./InventoryModal/DeleteService";
import EditService from "./InventoryModal/EditService";
import { useSelector } from "react-redux";
import {
  decryptData,
  getEncryptItems,
} from "../../Screens/Common/localStorageUtils";
import BulkAddInventoryModal from "./InventoryModal/BulkAddInventoryModal";
import DownloadTableData from "../../Screens/Common/downloadData";
import add from "../../Assets/AddFrame(1).png";
import bulkAdd from "../../Assets/addExcel.png";

const Services = ({ fetchInventory, isLoading }) => {
  document.title = `${process.env.REACT_APP_NAME} | Admin - Inventory Services`;

  const main = useSelector((state) => state.main);
  const [openAddServicesModal, setOpenAddServicesModal] = useState(false);
  const [openBulkAddServiceModal, setOpenBulkAddServiceModal] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [openDeleteService, setOpenDeleteService] = useState(false);
  const [openEditService, setOpenEditService] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [results, setResults] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [clicked, setClicked] = useState(false);
  const [id, setId] = useState("");
  const [initialValue, setInitialValue] = useState();
  const decryptedData = decryptData();
  const branch = useSelector(state => state?.main?.branch) || "";
  let userRole = decryptData()?.user?.role;
  const userRoleName = getEncryptItems("customRoles");
  const filteredServices =
    main?.inventoryService?.filter(
      (inv) =>
        inv?.organizationId?._id === branch?._id &&
        inv?.organizationId?._id === branch?._id
    ) || [];

  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredServices?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  console.log("decryptedData", decryptedData)
  const isAdmin = decryptedData?.user?.role === 'admin';
  const canCreateInventory = isAdmin ||
    decryptedData?.userTypeData?.roleId?.permissions?.inventory?.createInventoryService;
  const canEditInventory =
    isAdmin ||
    decryptedData?.userTypeData?.roleId?.permissions?.inventory?.editInventoryService;
  const canDeleteInventory =
    isAdmin ||
    decryptedData?.userTypeData?.roleId?.permissions?.inventory?.deleteInventoryService;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(filteredServices?.length / itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    if (searchData?.length === 0) {
      setResults([]);
    }
  }, [searchData]);

  const handleOpenEdit = (id) => {
    setId(id);
    const initial = main?.inventoryService?.filter((inv) => inv._id === id);
    setInitialValue(initial);
    setOpenEditService(true);
  };
  const handleChange = (e) => {
    setClicked(false);
    setResults([]);
    setSearchData(e.target.value);
  };

  const handleSearch = (e) => {
    setSearchData(e.target.value);
    const result = filteredServices?.filter((item) =>
      item?.name?.toLowerCase().includes(searchData.toLowerCase())
    );
    // setClicked(true);
    setResults(result);
  };

  const handleClear = () => {
    setSearchData("");
    // setClicked(false);
    setResults([]);
  };

  const rowData = filteredServices?.map((data) => ({
    Name: data?.name,
    Price: data?.price,
    Duration: data?.duration,
    Description: data?.description,
  }));
  const headerData = [
    { label: "Name", field: "Name" },
    { label: "Price", field: "Price" },
    { label: "Duration", field: "Duration" },
    { label: "Description", field: "Description" },
  ];

  // console.log("filteredServices", filteredServices);

  return (
    <React.Fragment>
      {/* <InventoryContents /> */}

      <AddServices
        openAddServicesModal={openAddServicesModal}
        setOpenAddServicesModal={setOpenAddServicesModal}
        fetchInventory={fetchInventory}
      />

      <BulkAddInventoryModal
        openBulkAddServiceModal={openBulkAddServiceModal}
        setOpenBulkAddServiceModal={setOpenBulkAddServiceModal}
      />

      <DeleteService
        openDeleteService={openDeleteService}
        setOpenDeleteService={setOpenDeleteService}
        id={id}
        setId={setId}
        fetchInventory={fetchInventory}
        handleClear={handleClear}
      />

      {initialValue && (
        <EditService
          openEditService={openEditService}
          setOpenEditService={setOpenEditService}
          fetchInventory={fetchInventory}
          id={id}
          setId={setId}
          initialValue={initialValue}
          handleOpenEdit={handleOpenEdit}
          handleClear={handleClear}
        />
      )}

      <Category
        openCategoryModal={openCategoryModal}
        setOpenCategoryModal={setOpenCategoryModal}
      />

      <Tostify />

      <div className="mt-4 flex justify-between items-center flex-wrap gap-5">
        {/* <form onSubmit={(e) => handleSearch(e)}>
          <div className="bg-white rounded shadow w-52 flex p-1 relative">
            <input
              id="searchData"
              className="border-0 rounded px-3 py-3 h-10 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-6"
              placeholder="Search Service"
              type="text"
              value={searchData}
              onChange={handleChange}
            />
            {searchData && (
              <span
                className="absolute right-14 top-3 cursor-pointer"
                onClick={handleClear}
              >
                X
              </span>
            )}
            <button
              onClick={handleSearch}
              className="flex items-center justify-center bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 h-10 w-12 rounded ml-2"
            >
              <ImSearch color="white" />
            </button>
          </div>
        </form> */}
      </div>

      <div className="mx-auto mt-4">
        <div className="px-5 py-5 bg-white w-full border-t rounded-t-lg grid grid-flow-row md:grid-flow-col gap-3 justify-between">
          <div className="w-full gap-2 items-center lg:block hidden">
            <div className="font-bold text-2xl tracking-wide">Services</div>
          </div>

          <div className="flex w-full justify-center mt-2 xs:mt-0">
            <div className="bg-white rounded-lg w-full lg:w-72 flex p-1 relative border border-gray-200 hover:border-primaryHoverColor hover:border-1">
              <span className="flex items-center justify-center transition ease-in-out duration-300 ml-2">
                <ImSearch color="gray" size={15} />
              </span>
              <input
                id="searchData"
                className="border-0 rounded px-4 py-1 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none w-full pr-6"
                placeholder="Search By Name"
                type="text"
                value={searchData}
                onChange={(e) => {
                  handleSearch(e);
                  setCurrentPage(1);
                }}
              />
              {searchData && (
                <span
                  className="absolute right-14 top-2 cursor-pointer"
                  onClick={handleClear}
                >
                  X
                </span>
              )}
            </div>
          </div>

          <div className="flex items-center gap-2">
            <DownloadTableData rowData={rowData} headerData={headerData} />
            { }
            <button onClick={() => setOpenBulkAddServiceModal(true)}>
              <img src={bulkAdd} alt="bulkAdd" className="w-7 h-7" />
            </button>
            {canCreateInventory && <button
              onClick={() => setOpenAddServicesModal(true)}
            // className="h-12 w-48 flex content-center items-center justify-center text-md font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
            >
              <img src={add} alt="add" className="w-8 h-8" />
            </button>}
          </div>
        </div>
        {isLoading ? (
          <div className="shadow rounded-t-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3, 4, 5].map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : filteredServices?.length === 0 ? (
          <div className="flex justify-center bg-[#fff] w-full shadow-md py-4">
            No Services found
          </div>
        ) : searchData && results.length > 0 ? (
          <div className="shadow rounded-b-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Sl
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Name
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Price
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Duration
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Descriptions
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-sm font-semibold text-gray-600 tracking-wide">
                    {(userRole === "admin" ||
                      (userRole === "staff" &&
                        (userRoleName?.roleId?.permissions?.inventory
                          ?.editInventoryService ||
                          userRoleName?.roleId?.permissions?.client
                            ?.deleteInventoryService))) && <span>Actions</span>}
                  </th>
                </tr>
              </thead>
              <tbody>
                {results?.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {i + 1}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.name}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.price}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.duration || 0} Mins
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.description}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="whitespace-no-wrap flex justify-center">
                          {(userRole === "admin" ||
                            (userRole === "staff" &&
                              userRoleName?.roleId?.permissions?.inventory
                                ?.editInventoryService)) && (
                              <RiEdit2Fill
                                size={15}
                                onClick={() => {
                                  handleOpenEdit(v?._id);
                                }}
                                className="text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                              />
                            )}

                          {(userRole === "admin" ||
                            (userRole === "staff" &&
                              userRoleName?.roleId?.permissions?.inventory
                                ?.editInventoryService)) && (
                              <RiDeleteBin5Fill
                                className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                                size={15}
                                onClick={() => {
                                  setId(v?._id);
                                  setOpenDeleteService(true);
                                }}
                              />
                            )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : searchData && results.length === 0 ? (
          <div className="flex justify-center bg-[#fff] w-full shadow-md py-4">
            No Services found
          </div>
        ) : (
          <div className="shadow rounded-t-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Sl
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Name
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Price
                  </th>

                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Duration
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Descriptions
                  </th>
                  <th className="px-5 py-3 border-2 text-center border-gray-200 text-sm font-semibold text-gray-600 tracking-wide">
                    {(userRole === "admin" ||
                      (userRole === "staff" &&
                        (userRoleName?.roleId?.permissions?.inventory
                          ?.editInventoryService ||
                          userRoleName?.roleId?.permissions?.client
                            ?.deleteInventoryService))) && <span>Actions</span>}
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {currentPage * 5 + i - 4}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.name}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.price}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.duration || 0} Mins
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.description}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="whitespace-no-wrap flex items-center justify-center gap-2">
                          {(userRole === "admin" ||
                            (userRole === "staff" &&
                              userRoleName?.roleId?.permissions?.inventory
                                ?.editInventoryService)) && (
                              <RiEdit2Fill
                                size={15}
                                onClick={() => {
                                  handleOpenEdit(v?._id);
                                }}
                                className="text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                              />
                            )}

                          {(userRole === "admin" ||
                            (userRole === "staff" &&
                              userRoleName?.roleId?.permissions?.inventory
                                ?.deleteInventoryService)) && (
                              <RiDeleteBin5Fill
                                className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                                size={15}
                                onClick={() => {
                                  setId(v?._id);
                                  setOpenDeleteService(true);
                                }}
                              />
                            )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {(filteredServices?.length !== 0 && (!searchData || (searchData && results?.length > 0))) ? (
          <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {indexOfFirstItem + 1} to{" "}
              {Math.min(indexOfLastItem, (searchData ? results.length : filteredServices.length))} of{" "}
              {searchData ? results.length : filteredServices.length} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`text-sm ${currentPage === 1
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-l`}
              >
                Prev
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil((searchData ? results.length : filteredServices.length) / itemsPerPage)
                }
                className={`text-sm ${currentPage ===
                  Math.ceil((searchData ? results.length : filteredServices.length) / itemsPerPage)
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-r`}
              >
                Next
              </button>
            </div>
          </div>
        ) : null}
      </div>
      {/* <div className="mt-4 bg-white rounded-t-lg shadow h-80 w-full flex p-4">
        <div className="font-bold text-2xl tracking-wide w-full">Services</div>
      </div> */}
    </React.Fragment>
  );
};

export default Services;
