import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { decryptData, getEncryptItems } from "../../../Screens/Common/localStorageUtils";
import axios from "axios";
import {
  tostifyErr,
  tostifySuccess,
} from "../../../Screens/Common/tostifyMessages";
import regex from "../../../Screens/Common/validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
  outline: "none",
};

const AddProducts = (props) => {
  const initialState = {
    name: "",
    // company: "",
    mrp: "",
    priceProcurement: "",
    quantity: "",
    volume: "",
    code: "",
    mfg: "",
    exp: "",
    hsn: "",
    type: "",
    category: "",
    brand: "",
    orgId: "",
    nameErr: "",
    companyErr: "",
    mrpErr: "",
    priceProcurementErr: "",
    quantityErr: "",
    volumeErr: "",
    codeErr: "",
    mfgErr: "",
    typeErr: "",
    categoryErr: "",
    brandErr: "",
    expErr: "",
    hsnErr: "",
    orgIdErr: ""
  };
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [volumeUnit, setVolumeUnit] = useState("Ml");
  const [image, setImage] = useState();
  const [imageErr, setImageErr] = useState();
  const decryptedData = decryptData();
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const branch = useSelector(state => state?.main?.branch) || getEncryptItems("branchDetails");

  const clearInputField = () => {
    setFormData(prev => ({
      ...prev,
      orgId: "",
      orgIdErr: "",
      name: "",
      nameErr: "",
      // company: "",
      companyErr: "",
      mrp: "",
      mrpErr: "",
      priceProcurement: "",
      priceProcurementErr: "",
      quantity: "",
      quantityErr: "",
      volume: "",
      volumeErr: "",
      code: "",
      codeErr: "",
      mfg: "",
      mfgErr: "",
      exp: "",
      expErr: "",
      hsn: "",
      hsnErr: ""
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
      [`${name}Err`]: "",
    });
  };

  const createNewProductHistory = async (id) => {
    const createdBy =
      decryptData()?.user?.role === "admin"
        ? {
          name: decryptData()?.user?.firstName + " " + decryptData()?.user?.lastName,
          adminId: decryptData()?.userTypeData?._id,
        }
        : {
          name: decryptData()?.user?.firstName + decryptData()?.user?.lastName,
          staffId: decryptData()?.userTypeData?._id,
        };
    const data1 = JSON.stringify({
      productId: id,
      date: new Date(),
      type: "IN",
      stockType: "New Product",
      adminId: branch?.adminId,
      organizationId: branch?._id,
      quantity: formData?.quantity,
      beforeStock: 0,
      afterStock: formData.quantity,
      createdBy: createdBy,
    });

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/stockLedger/add`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: data1,
    };

    try {
      const response = await axios(config);
      console.log("---------", response?.data);
    } catch (error) {
      console.log("rrrrrrrr", error);
    }
  }


  const handleAddProduct = async (e) => {
    e.preventDefault();

    // Validate name
    if (!formData.name.trim()) {
      setFormData((prev) => ({ ...prev, nameErr: "Name is required" }));
      return;
    }

    // Validate type
    if (!formData.type) {
      setFormData((prev) => ({ ...prev, typeErr: "Type is required" }));
      return;
    }

    // Validate category
    if (!formData.category) {
      setFormData((prev) => ({ ...prev, categoryErr: "Category is required" }));
      return;
    }

    // Validate brand
    if (!formData.brand.trim()) {
      setFormData((prev) => ({ ...prev, brandErr: "Brand is required" }));
      return;
    }

    // Validate mrp
    if (!formData.mrp) {
      setFormData((prev) => ({ ...prev, mrpErr: "MRP is required" }));
      return;
    }

    // Validate quantity
    if (!formData.quantity.trim()) {
      setFormData((prev) => ({ ...prev, quantityErr: "Quantity is required" }));
      return;
    }

    // Validate volume
    if (!formData.volume) {
      setFormData((prev) => ({ ...prev, volumeErr: "Volume is required" }));
      return;
    }

    // Validate mfg date
    if (!formData.mfg) {
      setFormData((prev) => ({ ...prev, mfgErr: "Manufacturing Date is required" }));
      return;
    }

    // Validate exp date
    if (!formData.exp) {
      setFormData((prev) => ({ ...prev, expErr: "Expiration Date is required" }));
      return;
    }
    let data2;
    setIsLoading(true);
    if (image) {
      const formData2 = new FormData();
      formData2.append('file', image);
      formData2.append('upload_preset', 'i_bar_profile');


      const Response = await fetch(
        `https://api.cloudinary.com/v1_1/dprc07xle/image/upload`,
        {
          method: 'POST',
          body: formData2,
        }
      )
      data2 = await Response.json();
    }

    let data = {
      name: formData.name,
      company: formData.company,
      costPriceMRP: formData.mrp,
      priceProcurementPrice: formData.priceProcurement,
      quantity: formData.quantity,
      volume: volumeUnit === "Ltr" ? formData.volume * 1000 : formData.volume,
      code: formData.code,
      mfgDate: formData.mfg,
      expDate: formData.exp,
      HSN_SAC: formData.hsn,
      type: formData.type,
      brand: formData.brand,
      volumeUnit: volumeUnit,
      category: formData.category,
      url: data2?.secure_url ? data2?.secure_url : "",
      organizationId: branch?._id,
      totalVolume: volumeUnit === "Ltr" ? formData?.volume * formData?.quantity * 1000 : formData?.volume * formData?.quantity
    };

    setIsLoading(true)

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/product/add`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios
      .request(config)
      .then(async (response) => {
        await props.fetchProducts();
        await createNewProductHistory(response?.data?.data?._id);
        tostifySuccess(response?.data?.message);
        props.setOpenAddProduct(false);
        clearInputField();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("add Product error:", error);
        props.setOpenAddProduct(false);
        tostifyErr(error?.response?.data?.message);
        // clearInputField();
        setIsLoading(false);
      });

  };
  return (
    <React.Fragment >
      <Modal
        open={props.openAddProduct}
        onClose={() => {
          // clearInputField();
          props.setOpenAddProduct(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <div className="no-scrollbar max-h-[500px] overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() => props.setOpenAddProduct(false)}
                className="absolute top-6  right-5 text-3xl  cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">
                Add Product
              </div>
            </div>

            <div className="flex-auto mt-2">
              <form onSubmit={(e) => handleAddProduct(e)}>
                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Product Image
                    {/* <span className="text-red-600 text-lg"> *</span> */}
                  </div>
                  <input
                    type="file"
                    id="file"
                    autoComplete="off"
                    onChange={(e) => {
                      setImageErr(false);
                      setImage(e.target.files[0]);
                    }}
                    className={
                      imageErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Select Photo"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="name"
                    autoComplete="off"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className={
                      formData.nameErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Product Name"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Type
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <select
                    name="type" id="type"
                    value={formData.type}
                    onChange={handleInputChange}
                    className={
                      formData.typeErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                    }
                  >
                    <option value="">Select Type</option>
                    <option value="retail">Retail</option>
                    <option value="consumable">Consumable</option>
                    {/* <option value="both">Both</option> */}
                  </select>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Category
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  {/* <input
                    type="text"
                    id="category"
                    autoComplete="off"
                    name="category"
                    value={formData.category}
                    onChange={handleInputChange}
                    className={
                      formData.categoryErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Product Category"
                  /> */}
                  <select name="category" id=""
                    onChange={handleInputChange}
                    className={
                      formData.categoryErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                  >
                    <option value="">Choose Category</option>
                    <option value="Hair">Hair</option>
                    <option value="Waxing">Waxing</option>
                    <option value="Skin">Skin</option>
                  </select>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Brand
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="brand"
                    autoComplete="off"
                    name="brand"
                    value={formData.brand}
                    onChange={handleInputChange}
                    className={
                      formData.brandErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Brand Name"
                  />
                </div>

                {/* <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Company
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    autoComplete="off"
                    value={formData.company}
                    onChange={handleInputChange}
                    className={
                      formData.companyErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Product Company"
                  ></input>
                </div> */}

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    MRP
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="mrp"
                    name="mrp"
                    value={formData.mrp}
                    onChange={handleInputChange}
                    className={
                      formData.mrpErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter MRP"
                  ></input>
                </div>

                <div className="relative w-full mb-5">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    PriceProcurement
                  </div>
                  <input
                    value={formData.priceProcurement}
                    id="priceprocurement"
                    name="priceProcurement"
                    onChange={handleInputChange}
                    className={
                      formData.priceProcurementErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150 pr-10"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-10"
                    }
                    placeholder="Price Procurement"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Quantity
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    value={formData.quantity}
                    name="quantity"
                    id="quantity"
                    onChange={handleInputChange}
                    className={
                      formData.quantityErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Qantity"
                  ></input>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    {volumeUnit === "Pcs" ? "Pcs" : "Volume"} <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <div className="flex">
                    <input
                      type="text"
                      id="volume"
                      name="volume"
                      value={formData.volume || ""}
                      onChange={handleInputChange}
                      className={
                        formData.volumeErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-3/4 ease-linear transition-all duration-150"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-3/4 ease-linear transition-all duration-150"
                      }
                      placeholder={`Enter Product ${volumeUnit === "Pcs" ? "Pcs" : "Volume"}`}
                    />
                    <select
                      value={volumeUnit}
                      onChange={(e) => setVolumeUnit(e.target.value)}
                      className="ml-2 px-3 py-3 bg-white rounded shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-1/4 ease-linear transition-all duration-150"
                    >
                      <option value="Ml">Ml</option>
                      <option value="Ltr">Ltr</option>
                      <option value="Pcs">Pcs</option>
                    </select>
                  </div>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Code
                  </div>
                  <input
                    type="text"
                    id="code"
                    name="code"
                    value={formData.code}
                    onChange={handleInputChange}
                    className={
                      formData.codeErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Product Code"
                  ></input>
                </div>

                <div className="flex justify-center items-center mb-3 w-96">
                  <div className="relative w-full">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Mfg
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      type="date"
                      id="date"
                      name="mfg"
                      value={formData.mfg}
                      onChange={handleInputChange}
                      className={
                        formData.mfgErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                      }
                      placeholder="Enter Mfg Date"
                    />
                  </div>

                  <div className="relative w-full ml-6">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Exp
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      type="date"
                      id="expdate"
                      name="exp"
                      value={formData.exp}
                      onChange={handleInputChange}
                      className={
                        formData.expErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                      }
                      placeholder="Enter Exp Date"
                    />
                  </div>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    HSN_SAC
                  </div>
                  <input
                    type="text"
                    id="hsnsac"
                    name="hsn"
                    value={formData.hsn}
                    onChange={handleInputChange}
                    className={
                      formData.hsnErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="HSN_SAC"
                  ></input>
                </div>

                <div className="w-full flex items-center justify-center mt-5">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="h-12 w-2/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                  >
                    {isLoading ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default AddProducts;
