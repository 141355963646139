import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import {
  decryptData,
  getEncryptItems,
} from "../../../Screens/Common/localStorageUtils";
import axios from "axios";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import {
  tostifyErr,
  tostifySuccess,
} from "../../../Screens/Common/tostifyMessages";
import { useSelector } from "react-redux";
import Tostify from "../../../Screens/Common/Tostify";
import regex from "../../../Screens/Common/validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "90%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  outline: "none",
  borderRadius: "15px",
  p: 4,
  overflowY: "auto",
};

const NewAddStaff = (props) => {
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fname, setFname] = useState("");
  const [fnameErr, setFnameErr] = useState("");
  const [lname, setLname] = useState("");
  const [gender, setGender] = useState("");
  const [bGroup, setBGroup] = useState("");
  const [marital, setMarital] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [aadharErr, setAadharErr] = useState("");
  const [empId, setEmpId] = useState("");
  const [empIdErr, setEmpIdErr] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  // const [email, setEmail] = useState("");
  // const [emailErr, setEmailErr] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [designation, setDesignation] = useState("");
  const [designationErr, setDesignationErr] = useState("");
  const [mangId, setMangId] = useState("");
  const [image, setImage] = useState();
  const [url, setUrl] = useState();
  const [selectedFeatures, setSelectedFeatures] = useState();
  const [documents, setDocuments] = useState([]);
  const updatedPhone = "+91" + phone;
  const decryptedData = decryptData();
  const adminId = decryptedData?.userTypeData?._id;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const branch = useSelector(state => state?.main?.branch);
  // console.log("documents", documents);

  const handleDcouments = (files) => {
    const selectedFiles = Array.from(files);
    setDocuments((prevDocuments) => [...prevDocuments, ...selectedFiles]);
  };

  const handleClick = () => {
    setVisible((prev) => !prev);
  };

  const handleCheckBoxChange = (value) => {
    const isSelected = selectedFeatures.includes(value);
    if (isSelected) {
      const newSelectedItems = selectedFeatures.filter(
        (item) => item !== value
      );
      setSelectedFeatures(newSelectedItems);
    } else {
      setSelectedFeatures([...selectedFeatures, value]);
    }
  };
  const clearInputField = () => {
    setMangId("");
    setFname("");
    setLname("");
    setGender("");
    setBGroup("");
    setMarital("");
    setAadhar("");
    setDesignation("");
    setEmpId("");
    setPassword("");
    // setEmail("");
    setCurrentAddress("");
    setPermanentAddress("");
    setPhone("");
  };

  const handleAddStaff = async (e) => {
    e.preventDefault();

    // Perform validation checks
    if (!fname) {
      setFnameErr(true);
    } else if (!regex.nameRegEx.test(fname)) {
      setFnameErr(true);
    } else if (aadhar && !regex.twelveDigitsAdhaarRegex.test(aadhar)) {
      setAadharErr(true);
    } else if (!empId) {
      setEmpIdErr(true);
    } else if (!designation) {
      setDesignationErr(true);
    } else if (phone && !regex.phoneRegEx.test(phone)) {
      setPhoneErr(true);
    } else if (password && !regex.passwordRegEx.test(password)) {
      setPasswordErr(true);
    } else {
      setIsLoading(true);
      let imageUrl = '';

      if (image) {
        const formData = new FormData();
        formData.append("file", image);
        formData.append("upload_preset", "i_bar_profile");

        const response = await fetch(
          `https://api.cloudinary.com/v1_1/dprc07xle/image/upload`,
          {
            method: "POST",
            body: formData,
          }
        );
        const data2 = await response.json();
        imageUrl = data2.secure_url;
      }

      const staffData = {
        firstName: fname,
        lastName: lname,
        password: password,
        gender: gender,
        bloodGroup: bGroup,
        aadhaar: aadhar,
        employeeId: empId,
        martialStatus: marital,
        designation: designation,
        organizationId: branch?._id,
        adminId: adminId,
        currentAddress: currentAddress,
        permanentAddress: permanentAddress,
        features: selectedFeatures,
        url: imageUrl,
      };

      // Add phone to the data object only if it is valid and not empty
      if (phone && phone.length === 10) {
        staffData.phone = "+91" + phone;
      }

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/staff/add`,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(staffData),
      };

      try {
        const response = await axios.request(config);
        console.log("AddStaffModal response:", response.data);
        props.staffDetails();
        props.setOpenAddStaffModal(false);
        tostifySuccess(response?.data?.message);
        clearInputField();
      } catch (error) {
        console.log("AddStaffModal error:", error);
        props.setOpenAddStaffModal(false);
        tostifyErr(error?.response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={props.openAddStaffModal}
        onClose={() => {
          // clearInputField();
          props.setOpenAddStaffModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative no-scrollbar">
          <div className="no-scrollbar px-4">
            <Tostify />
            <span
              onClick={() => props.setOpenAddStaffModal(false)}
              className="absolute top-6  right-5 text-3xl  cursor-pointer"
            >
              x
            </span>
            <div className="btn-wrapper text-center">
              <div className="text-xl text-[#2b2f32] font-bold">Add Staff</div>
            </div>

            <div className="flex-auto mt-2">
              <form onSubmit={(e) => handleAddStaff(e)}>
                <div className="flex flex-col lg:flex-row justify-between items-center gap-5">
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Profile Photo
                    </div>
                    <input
                      type="file"
                      id="file"
                      autoComplete="off"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                      className={
                        "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Select Photo"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Name
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      type="text"
                      id="fname"
                      autoComplete="off"
                      value={fname}
                      onChange={(e) => {
                        setFnameErr(false);
                        setFname(e.target.value);
                      }}
                      className={
                        fnameErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Enter Name"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Designation
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      type="text"
                      id="designation"
                      autoComplete="off"
                      value={designation}
                      onChange={(e) => {
                        setDesignationErr(false);
                        setDesignation(e.target.value);
                      }}
                      className={
                        designationErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Enter Designation"
                    ></input>
                  </div>
                </div>

                <div className="flex  flex-col lg:flex-row  justify-between items-center gap-5">
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Gender
                    </div>
                    <select
                      value={gender}
                      id="gender"
                      onChange={(e) => {
                        setGender(e.target.value);
                      }}
                      className={
                        "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150  "
                      }
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Blood Group
                    </div>
                    <select
                      value={bGroup}
                      id="bGroup"
                      onChange={(e) => {
                        setBGroup(e.target.value);
                      }}
                      className={
                        "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150  "
                      }
                    >
                      <option value="">Select BloodGroup</option>
                      <option value="o+">O+</option>
                      <option value="o-">O-</option>
                      <option value="a+">A+</option>
                      <option value="a-">A-</option>
                      <option value="b+">B+</option>
                      <option value="b-">B-</option>
                      <option value="ab+">AB+</option>
                      <option value="ab-">AB-</option>
                    </select>
                  </div>

                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Employee Id
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      type="text"
                      value={empId}
                      id="empId"
                      onChange={(e) => {
                        setEmpIdErr(false);
                        setEmpId(e.target.value);
                      }}
                      className={
                        empIdErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Enter Emp Id"
                    ></input>
                  </div>
                </div>

                <div className="flex  flex-col lg:flex-row  justify-between items-center gap-5">
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Marital Status
                    </div>

                    <select
                      value={marital}
                      id="marital"
                      onChange={(e) => {
                        setMarital(e.target.value);
                      }}
                      className={
                        "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150  "
                      }
                    >
                      <option value="">Select Martial Status</option>
                      <option value="single">Single</option>
                      <option value="married">Married</option>
                    </select>
                  </div>

                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Current Address
                    </div>
                    <input
                      type="text"
                      id="currentAddress"
                      autoComplete="off"
                      value={currentAddress}
                      onChange={(e) => {
                        setCurrentAddress(e.target.value);
                      }}
                      className={
                        "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Enter Current Address."
                    ></input>
                  </div>
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Permanent Address
                    </div>
                    <input
                      type="text"
                      id="permanentAddress"
                      autoComplete="off"
                      value={permanentAddress}
                      onChange={(e) => {
                        setPermanentAddress(e.target.value);
                      }}
                      className={
                        "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Enter Permanent Address."
                    ></input>
                  </div>
                </div>

                <div className="flex  flex-col lg:flex-row  justify-between items-center gap-5">
                  {/* <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      email
                    </div>
                    <input
                      type="text"
                      value={email}
                      autoComplete="off"
                      id="email"
                      onChange={(e) => {
                        setEmailErr(false);
                        setEmail(e.target.value);
                      }}
                      className={
                        emailErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Enter Your email"
                    ></input>
                  </div> */}

                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Phone
                      {/* <span className="text-red-600 text-lg"> *</span> */}
                    </div>
                    <div className="flex gap-4">
                      <div>
                        <select
                          id="code"
                          className={
                            "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-primaryColor w-max ease-linear transition-all duration-150  "
                          }
                        >
                          <option value="+91">+91</option>
                        </select>
                      </div>
                      <div className="w-full">
                        <input
                          type="text"
                          value={phone}
                          id="phone"
                          autoComplete="off"
                          onChange={(e) => {
                            setPhone(e.target.value);
                            setPhoneErr(false);
                          }}
                          className={
                            phoneErr
                              ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                              : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                          }
                          placeholder="Enter Your Phone No."
                        />
                      </div>
                    </div>
                  </div>

                  <div className="relative w-full mb-5">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Password
                      {/* <span className="text-red-600 text-lg"> *</span> */}
                    </div>
                    <input
                      value={password}
                      autoComplete="off"
                      id="password"
                      onChange={(e) => {
                        setPasswordErr(false);
                        setPassword(e.target.value);
                      }}
                      type={visible ? "text" : "password"}
                      className={
                        passwordErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150 pr-10"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150 pr-10"
                      }
                      placeholder="Password"
                    />
                    {visible ? (
                      <div className="absolute right-1 top-12 ">
                        <IoMdEye
                          size={20}
                          onClick={handleClick}
                          className="mx-2 text-[#828282]"
                        />
                      </div>
                    ) : (
                      <div className="absolute right-1 top-12">
                        <IoMdEyeOff
                          size={20}
                          onClick={handleClick}
                          className="mx-2 text-[#828282]"
                        />
                      </div>
                    )}
                    {/* <div className="text-[#828282] text-xs mt-2">
                                            Password must contain at least one lowercase letter, one uppercase letter,
                                            one digit, one special character, and be between 8 to 30 characters long.
                                        </div> */}
                  </div>
                </div>

                <div className="flex  flex-col lg:flex-row  justify-between items-center gap-5">
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Document-1
                    </div>
                    <input
                      type="file"
                      id="file"
                      multiple
                      autoComplete="off"
                      onChange={(e) => handleDcouments(e.target.files)}
                      className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
                      placeholder="Select Photos"
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Date Of Joining
                    </div>
                    <input
                      type="date"
                      id="doj"
                      autoComplete="off"
                      defaultValue={new Date()?.toISOString()?.slice(0, 10)}
                      className={
                        "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Select Photo"
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Emergency Contact
                    </div>
                    <input
                      type="text"
                      id="emergency"
                      autoComplete="off"
                      className={
                        "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Enter Number"
                    />
                  </div>
                </div>

                <div className="w-full flex items-center justify-center mt-5">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="h-12 w-2/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                  >
                    {isLoading ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default NewAddStaff;
