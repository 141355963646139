import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { decryptData } from "../../../Screens/Common/localStorageUtils";
import {
  tostifyErr,
  tostifySuccess,
} from "../../../Screens/Common/tostifyMessages";
import { allLeser, calculateLaser } from "../../../Screens/Common/initData";
import { allLaserList } from "../../../state-management/main/actions";
import regex from "../../../Screens/Common/validation";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  outline: "none",
  p: 4,
};

const AddExpanditure = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [newCategory, setNewCategory] = useState()
  const [formData, setFormData] = useState({
    cash: 0,
    expense: 0,
    remark: "",
    date: new Date()?.toISOString()?.slice(0, 10) || "",
    expenseType: "",
    paymentMethod: "",
    givenTo: "",
  });
  const [formError, setFormError] = useState({
    cashError: false,
    expenseError: false,
    remarkError: false,
    dateError: false,
    expenseTypeErr: false,
    givenToErr: false,
    paymentMethodErr: false
  });
  const decryptedData = decryptData();
  const adminId = decryptedData?.user?.role === "admin" ? decryptedData?.userTypeData?._id : decryptedData?.userTypeData?.adminId
  const branch = useSelector(state => state?.main?.branch) || "";
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const currentTime = new Date();
  const options = { hour: "numeric", minute: "numeric", hour12: true };
  const formattedTime = currentTime.toLocaleTimeString("en-US", options);

  // const allLeserData = async () => {
  //   const data = await allLeser()
  //   dispatch(allLaserList(data));
  // };

  useEffect(() => {
    const fetchExpenseTypes = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/category/find/${branch?._id}`, {
          headers: {
            Authorization: token,
          },
        });
        setExpenseTypes(response?.data?.data);
      } catch (error) {
        console.error("Error fetching expense types:", error);
      }
    };

    fetchExpenseTypes();
  }, [branch, isAddLoading]);

  const handleAddExpanditure = async (e) => {
    e.preventDefault();
    if (!formData.date) {
      setFormError((prev) => ({ ...prev, dateError: true }));
    } else if (!formData.expenseType) {
      setFormError((prev) => ({ ...prev, expenseTypeErr: true }));
    } else if (!formData.paymentMethod) {
      setFormError((prev) => ({ ...prev, paymentMethodErr: true }));
    } else if (!regex.priceRegex.test(formData.expense)) {
      setFormError((prevErrors) => ({ ...prevErrors, expenseError: true }));
    } else if (!formData.remark) {
      setFormError((prevErrors) => ({ ...prevErrors, remarkError: true }));
    } else {
      try {
        let data = JSON.stringify({
          organizationId: branch?._id,
          adminId: adminId,
          cash: formData.cash,
          expense: formData.expense,
          remark: formData.remark,
          currentTime: formattedTime,
          paymentMethod: formData.paymentMethod,
          expenseType: formData.expenseType,
          givenTo: formData.givenTo,
          date: formData.date,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}/expenditure/add/`,
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          data: data,
        };

        setIsLoading(true);

        const response = await axios.request(config);
        // await calculateLaser("656eb7ffcecf985357554d96");
        props.fetchExpanditure();
        // await allLeserData();
        props.setOpenAddExpanditure(false);
        clearInputField();
        tostifySuccess(response?.data?.message);
      } catch (error) {
        console.error(error);
        tostifyErr(error?.response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleAddExpenseType = async () => {
    try {
      let data = JSON.stringify({
        organizationId: branch?._id,
        category: newCategory,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/category/add/`,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      setIsAddLoading(true);

      const response = await axios.request(config);
      setNewCategory("");
      tostifySuccess(response?.data?.message);
    } catch (error) {
      console.error(error);
      tostifyErr(error?.response?.data?.message);
    } finally {
      setIsAddLoading(false);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormError((prevErrors) => ({ ...prevErrors, [`${name}Error`]: false }));
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const clearInputField = () => {
    setFormData((prev) => ({
      ...prev,
      cash: 0,
      expense: 0,
      remark: "",
    }));
    setFormError((prev) => ({
      ...prev,
      cashError: false,
      expenseError: false,
      remarkError: false,
    }));
  };

  return (
    <React.Fragment>
      <Modal
        open={props.openAddExpanditure}
        onClose={() => {
          props.setOpenAddExpanditure(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <div className="no-scrollbar max-h-[600px] min-w-[400px] sm:min-w-[450px] overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() => props.setOpenAddExpanditure(false)}
                className="absolute top-6  right-5 text-3xl  cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">
                Add Expanditure
              </div>
            </div>

            <div className="flex-auto mt-2">
              <form onSubmit={(e) => handleAddExpanditure(e)}>
                <div className="relative w-full mb-5">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Date
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={(e) => handleChange(e)}
                    className={
                      formError.dateError
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150 pr-10"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-10"
                    }
                  />

                </div>

                <div className="relative w-full mb-5 flex gap-2">
                  <input
                    type="text"
                    placeholder="Enter new expense type"
                    value={newCategory}
                    onChange={(e) => setNewCategory(e.target.value)}
                    className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-10"
                  />
                  <button
                    type="button"
                    onClick={handleAddExpenseType}
                    className="bg-primaryColor text-white px-3 py-2 rounded-md shadow-lg hover:bg-primaryHoverColor transition-all duration-150"
                  >
                    {isAddLoading ? "..Loading" : "Add"}
                  </button>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="relative w-full mb-5">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Expense Type
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <select name="expenseType" id="expenseType"
                      value={formData?.expenseType}
                      onChange={(e) => handleChange(e)}
                      className={
                        formError?.expenseTypeErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                      }>
                      <option value="">Select expense type</option>
                      {expenseTypes?.map((type) => (
                        <option key={type._id} value={type?.category}>
                          {type?.category}
                        </option>
                      ))}
                      {/* <option value="">Select Expense Type</option>
                      <option value="Bank Charge">Bank Charge</option>
                      <option value="Advance Salary">Advance Salary</option>
                      <option value="Car Maintenance">Car Maintenance</option>
                      <option value="Cash Transfer to Bank">Cash Transfer to Bank</option>
                      <option value="Cash Transfer to Hub">Cash Transfer to Hub</option>
                      <option value="Client Snacks">Client Snacks</option>
                      <option value="Clinical Charges">Clinical Charges</option>
                      <option value="Equipments">Equipments</option>
                      <option value="Fuel">Fuel</option>
                      <option value="Government Fee">Government Fee</option>
                      <option value="Incentive">Incentive</option>
                      <option value="Insurance">Insurance</option>
                      <option value="International Purchase">International Purchase</option>
                      <option value="Loan Repayment">Loan Repayment</option>
                      <option value="Maintenance">Maintenance</option>
                      <option value="Marketing">Marketing</option>
                      <option value="Maiscellaneous">Maiscellaneous</option>
                      <option value="MRA">MRA</option>
                      <option value="Other">Other</option>
                      <option value="Pantry">Pantry</option>
                      <option value="Pantry Bill">Pantry Bill</option>
                      <option value="Product">Product</option>
                      <option value="Rent">Rent</option>
                      <option value="Salary">Salary</option>
                      <option value="Staff Snacks">Staff Snacks</option>
                      <option value="Tax">Tax</option>
                      <option value="Tea & Refreshment">Tea & Refreshment</option>
                      <option value="Utilities">Utilities</option> */}
                    </select>
                  </div>

                  <div className="relative w-full mb-5">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Payment Method
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <select name="paymentMethod" id="paymentMethod"
                      value={formData.paymentMethod}
                      onChange={(e) => handleChange(e)}
                      className={
                        formError.paymentMethodErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                      }>
                      <option value="">Select Payment Method</option>
                      <option value="Cash">CASH</option>
                      <option value="Card">CARD</option>
                      <option value="Upi">UPI</option>
                      <option value="NetBanking">NET BANKING</option>
                    </select>

                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="relative w-full mb-5">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Expense
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      type="text"
                      name="expense"
                      value={formData.expense}
                      onChange={(e) => handleChange(e)}
                      className={
                        formError.expenseError
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150 pr-10"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-10"
                      }
                      placeholder="Enter Expense"
                    />
                  </div>

                  <div className="relative w-full mb-5">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Given To
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      type="text"
                      name="givenTo"
                      value={formData.givenTo}
                      onChange={(e) => handleChange(e)}
                      className={
                        formError.givenToErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150 pr-10"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-10"
                      }
                    />

                  </div>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Remark
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <textarea
                    type="text"
                    name="remark"
                    value={formData.remark}
                    onChange={(e) => handleChange(e)}
                    className={
                      formError.remarkError
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Remark"
                  ></textarea>
                </div>

                <div className="w-full flex items-center justify-center mt-5">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="h-12 w-2/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                  >
                    {isLoading ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default AddExpanditure;
