import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  tostifyErr,
  tostifySuccess,
} from "../../../Screens/Common/tostifyMessages";
import regex from "../../../Screens/Common/validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  outline: "none",
  p: 4,
};

const EditClient = (props) => {
  const [isLoading, setIsLoading] = useState();
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [remark, setRemark] = useState("");
  const [code, setCode] = useState("");
  const [codeErr, setCodeErr] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const updatedPhone = code + phone;
  const token = "Bearer " + localStorage.getItem("refresh_token");

  useEffect(() => {
    setName(props?.initialValue[0]?.name || "");
    setEmail(props?.initialValue[0]?.email || "");
    setRemark(props?.initialValue[0]?.remark || "");
    setCode(props?.initialValue[0]?.phoneNumber?.slice(0, 3) || "");
    setPhone(props?.initialValue[0]?.phoneNumber?.slice(3, 14) || "");
  }, [props.handleOpenEditClient]);

  const clearInputField = () => {
    setCode("");
    setCodeErr("");
    setPhone("");
    setPhoneErr("");
    setName("");
    setNameErr("");
    setEmail("");
    setEmailErr("");
    setRemark("");
  };

  const handleEditClient = async (e) => {
    e.preventDefault();
    if (!regex.nameRegEx.test(name)) {
      setNameErr(true);
    } else if (!email) {
      setEmailErr(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailErr(true);
    } else if (!code) {
      setCodeErr(true);
    } else if (!regex.phoneRegEx.test(phone)) {
      setPhoneErr(true);
    } else {
      let data = JSON.stringify({
        name: name,
        email: email,
        phoneNumber: updatedPhone,
        remark: remark,
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/customer/update/${props.id}`,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      setIsLoading(true);
      axios
        .request(config)
        .then((response) => {
          setIsLoading(false);
          props.fetchClients();
          tostifySuccess(response?.data?.message);
          props.setId("");
          props.handleClear();
          props.setOpenEditClientModal(false);
          clearInputField();
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          tostifyErr(error?.response?.data?.error);
          props.setOpenEditClientModal(false);
          clearInputField();
        });
    }
  };

  return (
    <React.Fragment >
      <Modal
        open={props.openEditClientModal}
        onClose={() => {
          clearInputField();
          props.setOpenEditClientModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <div className="no-scrollbar max-h-[400px] overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() => props.setOpenEditClientModal(false)}
                className="absolute top-6  right-5 text-3xl  cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">
                Edit Client Details
              </div>
            </div>

            <div className="flex-auto mt-2">
              <form onSubmit={(e) => handleEditClient(e)}>
                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="name"
                    autoComplete="off"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameErr(false);
                    }}
                    className={
                      nameErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Your Name"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    email
                  </div>
                  <input
                    type="text"
                    id="email"
                    autoComplete="off"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailErr(false);
                    }}
                    className={
                      emailErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Your email"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Remark
                  </div>
                  <input
                    type="text"
                    value={remark}
                    id="remark"
                    onChange={(e) => {
                      setRemark(e.target.value);
                    }}
                    className={
                      "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Your Remark"
                  />
                </div>

                <div className="flex justify-center items-center mb-3 w-96">
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Phone
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <div className="flex justify-between w-full">
                      <div className="w-[20%] ">
                        <select
                          value={code}
                          id="code"
                          onChange={(e) => {
                            setCode(e.target.value);
                            setCodeErr(false);
                          }}
                          className={
                            codeErr
                              ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-max ease-linear transition-all duration-150  "
                              : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-max ease-linear transition-all duration-150  "
                          }
                        >
                          <option value="">Code</option>
                          <option value="+91">+91</option>
                        </select>
                      </div>
                      <div className="w-[76%]">
                        <input
                          type="text"
                          id="phone"
                          autoComplete="off"
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                            setPhoneErr(false);
                          }}
                          className={
                            phoneErr
                              ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                              : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                          }
                          placeholder="Enter Your Phone No."
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full flex items-center justify-center mt-5">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="h-12 w-2/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                  >
                    {isLoading ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Edit</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default EditClient;
