import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";
import { storeClient } from "../../../state-management/main/actions";
import NewLoader from "../../../Screens/Common/NewLoader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
};

const NewMembershipModal = (props) => {
  const main = useSelector((state) => state?.main);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMembership, setSelectedMembership] = useState("");
  const [membershipErr, setMembershipErr] = useState(false);
  const [expDate, setExpDate] = useState("");
  const branch = useSelector(state => state?.main?.branch);
  const storedDraftBill = useSelector(state => state?.main?.draftBill);
  const filteredPackages = main?.membershipList?.filter(
    (memb) => memb?.organizationId?._id === branch?._id
  );
  const currentDate = new Date();
  const membershipData = filteredPackages?.find(
    (pack) => pack?._id === selectedMembership
  );
  const membershipValidity = membershipData?.validity;

  const clearInputField = () => {
    setSelectedMembership("");
    setMembershipErr(false);
  };

  const handleAddMembership = async (e) => {
    e.preventDefault();
    setMembershipErr(false);

    if (!selectedMembership) {
      setMembershipErr(true);
      return;
    }

    if (storedDraftBill && Object.keys(storedDraftBill).length > 0 && props?.addMember?.[0]) {
      setIsLoading(true);
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/customer/removeMembership`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
            },
            params: {
              customerId: props?.client?._id,
              membershipId: props?.addMember?.[0]?._id,
            },
          }
        );

        if (response.status !== 200) {
          throw new Error("Failed to remove membership");
        }

        dispatch(storeClient(response?.data?.data))
        setIsLoading(false);

      } catch (error) {
        console.error("Error removing membership:", error);
        setIsLoading(true);
        return;
      }
    }

    props.setOpenAddMembershipModal(false);

    const selectedPackage = filteredPackages?.find(
      (pack) => pack._id === selectedMembership
    );

    props.setAddMember([selectedPackage]);

    setSelectedMembership("");
  };


  useEffect(() => {
    if (
      membershipData &&
      typeof membershipValidity === "number" &&
      !isNaN(membershipValidity)
    ) {
      const expirationDate = new Date(currentDate);
      expirationDate.setMonth(expirationDate.getMonth() + membershipValidity);

      if (!isNaN(expirationDate)) {
        const formattedExpDate = expirationDate.toISOString().split("T")[0];
        setExpDate(formattedExpDate);
      }
    }
  }, [currentDate, membershipData, membershipValidity]);

  return (
    <React.Fragment>
      <Modal
        open={props.openAddMembershipModal}
        onClose={() => {
          clearInputField();
          props.setOpenAddMembershipModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <div className="max-h-[400px] overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() => props.setOpenAddMembershipModal(false)}
                className="absolute top-6 right-5 text-3xl cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">
                Membership List
              </div>
            </div>

            <div className="flex-auto mt-2">
              <div className="flex justify-center items-center mb-3 w-96">
                <div className="relative w-full ml-6 mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Available Memberships
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <select
                    value={selectedMembership}
                    onChange={(e) => setSelectedMembership(e.target.value)}
                    className={
                      membershipErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                  >
                    <option value="">Select Membership</option>
                    {filteredPackages?.map((v, i) => (
                      <option className="capitalize" key={i} value={v._id}>
                        {v.packageName} - {v.validity} Months - Rs
                        {v.packagePrice}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="w-full flex items-center justify-center mt-5">
                <button
                  onClick={handleAddMembership}
                  disabled={isLoading}
                  className="h-12 w-2/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                >
                  {isLoading ? (
                    <NewLoader />
                  ) : (
                    <span>Add</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default NewMembershipModal;