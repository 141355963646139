import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import axios from "axios";
import { decryptData } from "../../../Screens/Common/localStorageUtils";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import Tostify from "../../../Screens/Common/Tostify";
import { allLeser, calculateLaser } from "../../../Screens/Common/initData";
import { allLaserList } from "../../../state-management/main/actions";
import {
  tostifyErr,
  tostifySuccess,
} from "../../../Screens/Common/tostifyMessages";
import { wait } from "@testing-library/user-event/dist/utils";
import regex from "../../../Screens/Common/validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  outline: "none",
  borderRadius: "15px",
  p: 4,
};

const BillingModal = (props) => {
  const dispatch = useDispatch();
  const [notficationId, setNotificationId] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClient, setISClient] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
  const [clientData, setClientdData] = useState([]);
  const [searchResultBox, setSearchResultBox] = useState(false);
  const [perticularUserData, setperticularUserData] = useState({});
  const main = useSelector((state) => state.main);
  const [membershipPackages, setMembershipPackages] = useState([]);
  const [name, setName] = useState("");
  const [gsting, setGsting] = useState("0%");
  const [mobileNumber, setMobileNumber] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [membershipId, setMembershipId] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [affiliateStaff, setAffiliateStaff] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [gender, setGender] = useState("");
  const [orgId, setOrgId] = useState();
  const [discount, setDiscount] = useState("");

  const [selectedServices, setSelectedServices] = useState([]);
  const [paidAmount, setPaidAmount] = useState(null);
  const [mergePayment, setMergePayment] = useState(false);
  const [membershipPayment, setMembershipPayment] = useState(false);
  const [singlePayment, setSinglePayment] = useState(false);
  const [updateWalletBalance, setUpdateWalletBalance] = useState(null);
  const [updateNoOfService, setUpdateNoOfService] = useState(null);

  // ================================================================
  const [nameAleart, setNameAleart] = useState(false);
  const [orgIdAlert, setOrgIdAleart] = useState(false);
  const [mobileNumberAleart, setMobileNumberAleart] = useState(false);
  const [aapointmentDateAleart, setApointmentDateAleart] = useState(false);
  const [aapointmentTimeAleart, setApointmentTimeAleart] = useState(false);
  const [emailAleart, setEmailAleart] = useState(false);
  const [dateOfBirthAleart, setDateOfBirthAleart] = useState(false);
  const [genderAleart, setGenderAleart] = useState(false);
  const [affiliateStaffAleart, setAffiliateStaffAleart] = useState(false);
  const [hoursAleart, setHoursAleart] = useState(false);
  const [servicesAleart, setServicesAleart] = useState(false);
  const [paymentMethodAleart, setPaymentMethodAleart] = useState(false);
  // ================================================================

  const selectedServiceIds = selectedServices.map((ele) => ele._id);
  const serviceCharge = selectedServices?.map((ele) => Number(ele.price));
  const totalServiceCharge = serviceCharge.reduce(
    (acc, price) => acc + price,
    0
  );
  const selectedDiscount = main?.discountList?.find(
    (item) => item._id === discount
  );
  let serviceAfterDiscount = totalServiceCharge;

  if (selectedDiscount) {
    serviceAfterDiscount =
      selectedDiscount.type === "percentage"
        ? totalServiceCharge -
        (selectedDiscount.amount / 100) * totalServiceCharge
        : totalServiceCharge - selectedDiscount.amount;
  }

  const gstPercentage = parseFloat(gsting.replace("%", "")) / 100 || 0;
  const gstAmount = serviceAfterDiscount * gstPercentage;
  const decryptedData = decryptData();
  const admin = useSelector((state) => state.admin);
  //   const orgId = decryptedData?.userTypeData?.organizationId?._id;
  const orgIds = main?.orgList?.filter(
    (ele) => ele.adminId === decryptedData?.user?.role === "admin" ? decryptedData?.userTypeData?._id : decryptedData?.userTypeData?.adminId
  );
  const staffs =
    admin?.staffList?.filter((stf) => stf?.organizationId === orgId) || [];
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-CA");
  const perticularMembership = membershipPackages.filter(
    (ele) => ele._id === perticularUserData.membershipId
  );
  const mangBranchId = decryptedData?.userTypeData?.organizationId?._id;
  const inventoryArr = admin?.inventoryService?.filter(
    (inv) => inv?.organizationId?._id === orgId
  );

  const discountList = main?.discountList?.filter(
    (dis) => dis?.organization?._id === orgId && dis?.organization !== null
  );
  const allLeserData = async () => {
    const data = await allLeser();
    dispatch(allLaserList(data));
  };

  const handleSendNotification = async () => {
    if (!orgId) {
      setOrgIdAleart(true);
      tostifyErr("please select a branch first");
    } else if (selectedServices.length === 0) {
      setServicesAleart(true);
      tostifyErr("please select a service");
    } else if (!affiliateStaff) {
      setServicesAleart(true);
      tostifyErr("please select a staff");
    } else {
      let data = JSON.stringify({
        organizationId: orgId,
        adminId: decryptedData?.user?.role === "admin" ? decryptedData?.userTypeData?._id : decryptedData?.userTypeData?.adminId,
        staffId: affiliateStaff,
        serviceId: selectedServices,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/notification/add`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setNotificationId(response?.data?.data?.notification?._id);
          tostifySuccess(response?.data?.message);
          setIsButtonDisabled(true);
          setTimeout(() => {
            setIsButtonDisabled(false);
          }, 120000);
        })
        .catch((error) => {
          console.log(error);
          tostifyErr(error?.response?.data?.message);
        });
    }
  };

  const handleGetStatus = async () => {
    if (!notficationId) {
      tostifyErr(
        "Please send a notification to a staff before proceeding further."
      );
      return;
    } else {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/notification/find-notification/${notficationId}`,
        headers: {
          Authorization: token,
        },
      };

      axios
        .request(config)
        .then((response) => {
          if (response?.data?.data?.isAccepted === "accepted") {
            tostifySuccess("Service is accepted by staff");
            setIsAccepted(true);
            setIsButtonDisabled(false);
          } else if (response?.data?.data?.isAccepted === "rejected") {
            tostifyErr("Service is rejected by staff");
            setIsAccepted(false);
            setIsButtonDisabled(false);
          } else if (response?.data?.data?.isAccepted === "pending") {
            tostifyErr("Service is not accepted yet.");
            setIsAccepted(false);
            setIsButtonDisabled(false);
          } else {
            tostifyErr(
              "Service status is not availble please send another notification to staff"
            );
            setIsAccepted(false);
            setIsButtonDisabled(false);
          }
        })
        .catch((error) => {
          console.log(error);
          tostifyErr(error?.response?.data?.message);
        });
    }
  };

  const handleCheckboxChange = (service) => {
    const isSelected = selectedServices?.some(
      (selectedService) => selectedService.name === service.name
    );
    if (isSelected) {
      setSelectedServices(
        selectedServices?.filter(
          (selectedService) => selectedService.name !== service.name
        )
      );
    } else {
      setSelectedServices([
        ...selectedServices,
        {
          _id: service._id,
          name: service.name,
          price: service.price,
          duration: service.duration,
        },
      ]);
    }
  };

  const handleTimeChange = () => {
    const selectedDuration = selectedServices?.map((ele) =>
      Number(ele.duration)
    );
    const totalDuration = selectedDuration?.reduce(
      (acc, duration) => acc + duration,
      0
    );
    const totalHours = Math.floor(totalDuration / 60);
    const totalMinutes = totalDuration % 60;
    setHours(totalHours);
    setMinutes(totalMinutes);
  };
  useEffect(() => {
    handleTimeChange();
  }, [handleCheckboxChange]);

  const HandleDownload = async () => {
    const pdfDoc = new jsPDF();
    const content = `
    Invoice

    Customer Details:
    Name: ${perticularUserData?.name || name}
    Phone Number: ${perticularUserData?.phoneNumber || mobileNumber}
    Date of Birth: ${perticularUserData?.dateOfBirth || dateOfBirth}
    Membership ID: ${perticularUserData?.membershipId || membershipId}
    Gender: ${perticularUserData?.gender || gender}

    Appointment Details:
    Date: ${appointmentDate}
    Time: ${appointmentTime}
    Services: ${selectedServices
        ?.map((ele) => `${ele.name} (Rs.${ele.price})`)
        .join("\n")}
    Payment Method: ${paymentMethod}
    Affiliate Staff: ${affiliateStaff}
    Duration: ${hours} hours ${minutes} minutes

    Billing Details:
    GSTIN: ${gstPercentage}%
    Total Service Charge: Rs.${totalServiceCharge.toFixed(2)}
    GST Amount: Rs.${gstAmount.toFixed(2)}
    Total Amount (Including GST & Discount): Rs.${(
        serviceAfterDiscount + gstAmount
      ).toFixed(2)}

    Payment Details:
    Paid Amount: Rs.${paidAmount}
    Wallet Balance: Rs.${updateWalletBalance || 0}
    Number of Services Remaining: ${updateNoOfService || 0}
    `;
    pdfDoc.text(content, 20, 20);
    const currentDate = new Date();
    const filename = `billing_data_${currentDate.getTime()}.pdf`;
    pdfDoc.save(filename);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSearch = () => {
    if (searchData == "") {
      tostifyErr("Search by user number");
    } else {
      handleClear();
      setSearchResultBox(true);
      const result = admin?.clientList
        ?.filter((ele) => ele?.organization?._id === orgId)
        .filter((item) =>
          item.phoneNumber.toLowerCase().includes(searchData.toLowerCase())
        );
      setClientdData(result);
    }
  };

  const handleClear = () => {
    setSearchData("");
    setName("");
    setAffiliateStaff("");
    setAppointmentDate("");
    setAppointmentTime("");
    setDateOfBirth("");
    setMobileNumber("");
    setEmail("");
    setGender("");
    setPaymentMethod("");
    setClientdData([]);
    setSearchResultBox(false);
    setperticularUserData({});
    setISClient(false);
    setSelectedServices([]);
    setDiscount("");
    setPaidAmount(0);

    setNameAleart(false);
    setMobileNumberAleart(false);
    setApointmentDateAleart(false);
    setApointmentTimeAleart(false);
    setEmailAleart(false);
    setDateOfBirthAleart(false);
    setGenderAleart(false);
    setAffiliateStaffAleart(false);
    setHoursAleart(false);
    setServicesAleart(false);
    setPaymentMethodAleart(false);
  };

  const getUserData = (id) => {
    setSearchData("");
    setSearchResultBox(false);
    const result = admin?.clientList?.filter((item) => item._id == id);
    setperticularUserData(result[0]);
    setMembershipId(result[0].membershipId);
    setISClient(true);
    getMembershipPackages();
  };

  const getMembershipPackages = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/membership-package/get-membership`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        setMembershipPackages(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const HandleCreateNewUser = async () => {
    setIsLoading(true);
    let data1 = JSON.stringify({
      organizationId: orgId,
      name: name,
      phoneNumber: selectedCountryCode + mobileNumber,
      email: email,
      remark: "Good",
      dateOfBirth: dateOfBirth,
      gender: gender,
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/customer/add`,
        data1,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data) {
        tostifySuccess(response?.data?.message);
        UserBillCreate(response?.data?.data?.customer);
        props.fetchClients();
      }
    } catch (error) {
      console.log(error);
      tostifyErr(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const UserBillCreate = async (customerData) => {
    setIsLoading(true);
    let NewUserdata = JSON.stringify({
      customerId: customerData?._id || "",
      adminId: decryptedData?.user?.role === "admin" ? decryptedData?.userTypeData?._id : decryptedData?.userTypeData?.adminId || "",
      date: formattedDate,
      organizationId: customerData?.organization,
      name: name,
      phone: customerData?.phoneNumber,
      email: customerData?.email,
      remark: "Good",
      appointementDate: appointmentDate,
      appointementTime: appointmentTime,
      dateOfBirth: customerData?.dateOfBirth,
      membershipId: customerData?.membershipId,
      paymentMethod: paymentMethod,
      staffIds: affiliateStaff,
      duration: `${hours}hours ${minutes ? minutes : ""}${minutes && "minutes"
        }`,
      gstPercentage: `${gstPercentage}`,
      totalServiceCharge: totalServiceCharge,
      gstChargeAmount: gstAmount,
      finalPrice: `${serviceAfterDiscount + gstAmount}`,
      gender: customerData?.gender,
      membershipServicesAmount: 0,
      paidAmount: paidAmount,
      services: selectedServices,
      walletBalance: "0",
      numberOfServices: "0",
    });

    let ExistUserdata = JSON.stringify({
      customerId: perticularUserData?._id,
      adminId: decryptedData?.user?.role === "admin" ? decryptedData?.userTypeData?._id : decryptedData?.userTypeData?.adminId,
      date: formattedDate,
      organizationId: orgId,
      name: perticularUserData?.name,
      phone: perticularUserData?.phoneNumber,
      email: perticularUserData?.email,
      remark: "Good",
      membershipId: perticularUserData?.membershipId,
      appointementDate: appointmentDate,
      appointementTime: appointmentTime,
      dateOfBirth: perticularUserData?.dateOfBirth,
      membershipId: membershipId,
      paymentMethod: paymentMethod,
      staffIds: affiliateStaff,
      duration: `${hours}hours ${minutes ? minutes : ""}${minutes && "minutes"
        }`,
      gstPercentage: `${gstPercentage}`,
      totalServiceCharge: totalServiceCharge,
      gstChargeAmount: gstAmount,
      finalPrice: `${serviceAfterDiscount + gstAmount}`,
      gender: perticularUserData.gender,
      membershipServicesAmount: 0,
      paidAmount: paidAmount,
      services: selectedServices,
      walletBalance: `${updateWalletBalance === 0 ? "0" : updateWalletBalance}`,
      numberOfServices: updateNoOfService,
    });

    let config2 = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/billing/add`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: isClient ? ExistUserdata : NewUserdata,
    };

    try {
      const response = await axios.request(config2);

      if (response?.status === 200) {
        HandleDownload();
        handleClear();
        setIsLoading(false);
        HandleCreateAppointment();
        await calculateLaser(orgId);
        props.fetchClients();
        props.setOpenBillingModal(false);
        await allLeserData();
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const HandleCreateAppointment = () => {
    let data = JSON.stringify({
      customerName: perticularUserData?.name || name,
      customerPhone:
        perticularUserData?.phoneNumber || selectedCountryCode + mobileNumber,
      staffId: affiliateStaff,
      startTime: appointmentDate,
      duration: `${hours}hours ${minutes ? minutes : ""}${minutes && "minutes"
        }`,
      organizationId: orgId,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/appointment/add/`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => { })
      .catch((error) => {
        console.log("add apointment error:", error);
      });
  };

  const handleButtonClick = () => {
    if (!orgId) {
      setOrgIdAleart(true);
      tostifyErr("Please select a branch");
    } else if (!isClient && !name) {
      setNameAleart(true);
      tostifyErr("Please fill the name!");
    } else if (!isClient && !mobileNumber) {
      setMobileNumberAleart(true);
      tostifyErr("Please fill the mobile number!");
    } else if (!appointmentDate) {
      setApointmentDateAleart(true);
      tostifyErr("Please select the Appointment Date!");
    } else if (!appointmentTime) {
      tostifyErr("Please select the Appointment time!");
      setApointmentTimeAleart(true);
    } else if (!isClient && !email) {
      tostifyErr("Please fill the email!");
      setEmailAleart(true);
    } else if (!isClient && !gender) {
      setGenderAleart(true);
      tostifyErr("Please choose the gender!");
    } else if (!orgId) {
      setOrgIdAleart(true);
      tostifyErr("Please select the organization");
    } else if (!affiliateStaff) {
      setAffiliateStaffAleart(true);
      tostifyErr("Please choose the staff !");
    } else if (!regex.priceRegex.test(hours)) {
      setHoursAleart(true);
      tostifyErr("Please fill the duration!");
    } else if (selectedServices.length == 0) {
      setServicesAleart(true);
      tostifyErr("Please choose min 1 service!");
    } else if (!isAccepted) {
      tostifyErr(
        "Please send a notification to a staff for verification or click on the verify button before proceeding further"
      );
    } else if (!paymentMethod) {
      setPaymentMethodAleart(true);
      tostifyErr("Please choose a payment method!");
    } else {
      if (isClient) {
        if (mergePayment || singlePayment || membershipPayment) {
          UserBillCreate();
        } else {
          tostifyErr("Please change a payment method");
        }
      } else {
        if (singlePayment) {
          HandleCreateNewUser();
        } else {
          tostifyErr("Please choose a payment methods");
        }
      }
    }
  };

  useEffect(() => {
    if (
      paymentMethod === "Wallet Balance" &&
      perticularUserData?.walletBalance < serviceAfterDiscount + gstAmount
    ) {
      tostifyErr(
        "Insufficient Wallet Balance. Please choose another payment method."
      );
      setMergePayment(false);
      setSinglePayment(false);
      setMembershipPayment(false);
      return;
    } else if (
      paymentMethod === "Wallet Balance" &&
      perticularUserData?.walletBalance > serviceAfterDiscount + gstAmount
    ) {
      setUpdateWalletBalance(
        perticularUserData?.walletBalance - (serviceAfterDiscount + gstAmount)
      );
      setUpdateNoOfService(perticularUserData?.numberOfServices - 1);
      setPaidAmount(0);
      setMergePayment(false);
      setSinglePayment(false);
      setMembershipPayment(true);
    } else if (
      paymentMethod === "Wallet Balance + Cash" ||
      paymentMethod === "Wallet Balance + Upi" ||
      paymentMethod === "Wallet Balance + Card"
    ) {
      if (perticularUserData.walletBalance > serviceAfterDiscount + gstAmount) {
        setUpdateWalletBalance(
          perticularUserData?.walletBalance - (serviceAfterDiscount + gstAmount)
        );
        setPaidAmount(0);
        setMergePayment(true);
        setSinglePayment(false);
        setMembershipPayment(false);
      } else {
        const calculatedPaidAmount = Math.max(
          serviceAfterDiscount + gstAmount - perticularUserData?.walletBalance,
          0
        );
        setPaidAmount(calculatedPaidAmount);
        setUpdateWalletBalance(0);
        setUpdateNoOfService(perticularUserData?.numberOfServices - 1);
        setMergePayment(true);
        setSinglePayment(false);
        setMembershipPayment(false);
      }
    } else if (
      paymentMethod === "Cash" ||
      paymentMethod === "Card" ||
      paymentMethod === "UPI"
    ) {
      setUpdateWalletBalance(perticularUserData?.walletBalance);
      setPaidAmount(serviceAfterDiscount + gstAmount);
      setUpdateNoOfService(perticularUserData?.numberOfServices);
      setMergePayment(false);
      setSinglePayment(true);
      setMembershipPayment(false);
    } else if (paymentMethod === "") {
      setPaidAmount(0);
      setMergePayment(false);
      setSinglePayment(false);
      setMembershipPayment(false);
    }
  }, [
    paymentMethod,
    totalServiceCharge,
    gstAmount,
    perticularUserData?.walletBalance,
  ]);

  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
  };
  const contentStyle = {
    width: "90%",
  };
  return (
    <React.Fragment>
      <Modal
        open={props.openBillingModal}
        onClose={() => {
          props.setOpenBillingModal(false);
          // handleClear();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={modalStyle}
      >
        <Box sx={style} style={contentStyle} className="relative">
          <Tostify />
          <h1 className="text-center text-[20px] font-bold">Billing </h1>
          <div className="no-scrollbar grid grid-cols-1  md:grid-cols-2 h-[400px] overflow-y-scroll gap-4">
            <span
              onClick={() => props.setOpenBillingModal(false)}
              className="absolute top-6  right-5 text-3xl  cursor-pointer"
            >
              x
            </span>
            <div className=" flex flex-col  p-2">
              <div className="grid grid-cols-1 h-[auto] gap-2 mb-6">
                <label htmlFor="OrgId">
                  Branch <span className="text-red-600 text-lg"> *</span>
                </label>
                <select
                  onChange={(e) => {
                    setOrgIdAleart(false);
                    setOrgId(e.target.value);
                    setSelectedServices([]);
                    handleClear();
                  }}
                  id="OrgId"
                  className={
                    orgIdAlert
                      ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                      : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                  }
                >
                  <option value="">Choose Branch</option>
                  {orgIds?.map((ele) => (
                    <option key={ele._id} value={ele._id}>
                      {ele.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid grid-cols-1 h-[auto] gap-2 mb-6">
                <label htmlFor="number">Search by Number</label>
                <div className="w-[100%] flex gap-2 rounded relative">
                  <input
                    id="number"
                    onChange={(e) => setSearchData(e.target.value)}
                    value={searchData}
                    type="text"
                    autoComplete="off"
                    className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    placeholder="Enter Phone No."
                  />
                  <button
                    onClick={handleClear}
                    className="absolute top-3 right-24 mr-2"
                  >
                    {searchData && "X"}
                  </button>
                  <button
                    onClick={handleSearch}
                    className="w-[20%] bg-gray-200"
                  >
                    search
                  </button>
                </div>
                {isClient && (
                  <div>
                    <button
                      onClick={handleClear}
                      className="bg-primaryColor text-white cursor-pointer px-3 rounded-lg"
                    >
                      Clear
                    </button>
                  </div>
                )}
              </div>
              <div className={`${searchResultBox ? "" : "hidden"}`}>
                {clientData.length > 0 ? (
                  <div className="border relative bg-gray-200 mt-2 w-[100%] p-3">
                    {clientData?.map((ele, i) => (
                      <p
                        onClick={() => getUserData(ele._id)}
                        className="hover:bg-gray-300 cursor-pointer px-3"
                        key={i}
                      >
                        {ele.name}
                      </p>
                    ))}
                  </div>
                ) : (
                  <div className="border relative bg-gray-200 mt-2 w-[100%] p-3">
                    Data not found
                  </div>
                )}
              </div>

              <div className="grid grid-cols-1 h-[auto] gap-2 mb-6">
                <label htmlFor="name">
                  Name <span className="text-red-600 text-lg"> *</span>
                </label>
                <input
                  onChange={(e) => {
                    setNameAleart(false);
                    setName(e.target.value);
                  }}
                  id="name"
                  autoComplete="off"
                  disabled={perticularUserData?.name}
                  value={perticularUserData?.name || name}
                  type="text"
                  className={
                    nameAleart
                      ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                      : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                  }
                  placeholder="Enter Name"
                />
              </div>
              <div className="grid grid-cols-1 h-[auto] gap-2 mb-6">
                <label htmlFor="PhoneNumber">
                  Mobile Number <span className="text-red-600 text-lg"> *</span>
                </label>
                <div className="flex gap-2">
                  <select
                    value={selectedCountryCode}
                    id="code"
                    onChange={(e) => setSelectedCountryCode(e.target.value)}
                    className={
                      isClient
                        ? "hidden"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6]  ease-linear transition-all duration-150 w-[40%] md:w-[20%]"
                    }
                  >
                    <option value="+91">+91 (India)</option>
                    <option value="+1">+1 (United States)</option>
                    <option value="+44">+44 (United Kingdom)</option>
                    <option value="+81">+81 (Japan)</option>
                  </select>
                  <input
                    type="number"
                    onChange={(e) => {
                      setMobileNumberAleart(false);
                      setMobileNumber(e.target.value);
                    }}
                    placeholder={
                      perticularUserData?.phoneNumber || "Enter Number"
                    }
                    disabled={perticularUserData?.phoneNumber}
                    id="PhoneNumber"
                    autoComplete="off"
                    value={mobileNumber}
                    className={
                      mobileNumberAleart
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                  />
                </div>
              </div>

              <div className={`w-[100%] grid grid-cols-1 gap-2 mb-6`}>
                <span>
                  Services <span className="text-red-600 text-lg"> *</span>
                </span>
                <div
                  className={
                    servicesAleart
                      ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                      : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                  }
                >
                  {inventoryArr?.map((ele) => (
                    <div key={ele.name} className="flex gap-2">
                      <input
                        type="checkbox"
                        className="border"
                        name="serivce"
                        onChange={() => {
                          setServicesAleart(false);
                          handleCheckboxChange(ele);
                        }}
                        checked={selectedServices.some(
                          (selectedService) => selectedService.name === ele.name
                        )}
                      />
                      <div className="flex justify-between items-center px-4 py-2">
                        <div className="flex items-center">
                          <span className="font-bold mr-2">{ele.name}</span>
                          <span className="text-gray-500 mr-2">
                            Rs. {ele.price}
                          </span>
                          <span className="text-gray-500">
                            ({ele.duration} Mins.)
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className=" w-[100%] grid grid-cols-2 gap-4 mb-6">
                <div className="grid grid-cols-1 h-[auto] gap-2">
                  <label htmlFor="date">Appointment Date?</label>
                  <input
                    onChange={(e) => {
                      setApointmentDateAleart(false);
                      setAppointmentDate(e.target.value);
                    }}
                    value={appointmentDate}
                    type="date"
                    name="date"
                    id="date"
                    className={
                      aapointmentDateAleart
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                  />
                </div>
                <div className="grid grid-cols-1 h-[auto] gap-2">
                  <label htmlFor="time">Appointment Time?</label>
                  <input
                    onChange={(e) => {
                      setApointmentTimeAleart(false);
                      setAppointmentTime(e.target.value);
                    }}
                    type="time"
                    value={appointmentTime}
                    name="time"
                    id="time"
                    className={
                      aapointmentTimeAleart
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                  />
                </div>
              </div>

              <div className=" w-[100%]  grid grid-cols-2 gap-4  mb-6">
                <div className="grid grid-cols-1 h-[auto] gap-1">
                  <label htmlFor="email">Date of Anniversary</label>
                  <input
                    // disabled={perticularUserData?.anniversary}
                    // value={perticularUserData?.email || email}
                    autoComplete="off"
                    type="date"
                    name="doa"
                    id="doa"
                    className={
                      0
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                  />
                </div>
                <div className="grid grid-cols-1 h-[auto]">
                  <label htmlFor="dob">Date of birth</label>
                  <input
                    type="date"
                    name="dob"
                    id="dob"
                    className={
                      dateOfBirthAleart
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    value={
                      isClient
                        ? formatDate(perticularUserData.dateOfBirth)
                        : dateOfBirth
                    }
                    onChange={(e) => {
                      setDateOfBirthAleart(false);
                      setDateOfBirth(e.target.value);
                    }}
                    disabled={isClient}
                  />
                </div>
              </div>
            </div>
            <div className=" p-2">
              <div className="grid grid-cols-1 h-[auto] gap-2 mb-6">
                <label htmlFor="email">
                  Email <span className="text-red-600 text-lg"> *</span>
                </label>
                <input
                  onChange={(e) => {
                    setEmailAleart(false);
                    setEmail(e.target.value);
                  }}
                  disabled={perticularUserData?.email}
                  value={perticularUserData?.email || email}
                  autoComplete="off"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter email"
                  className={
                    emailAleart
                      ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                      : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                  }
                />
              </div>

              <div
                className={`${isClient &&
                  perticularUserData.walletBalance !== 0 &&
                  perticularUserData.numberOfServices !== 0 &&
                  perticularUserData.membershipId !== null
                  ? "mb-2"
                  : ""
                  } w-[100%] grid grid-cols-1 gap-2`}
              >
                {isClient && (
                  <div
                    className={`${perticularUserData.walletBalance == 0 ||
                      perticularUserData.numberOfServices == 0 ||
                      perticularUserData.membershipId == null
                      ? "hidden"
                      : "grid grid-cols-1 h-[auto] gap-2"
                      } `}
                  >
                    <div className="flex items-center gap-6">
                      <label htmlFor="Membership">Membership</label>
                    </div>
                    <input
                      type="text"
                      id="Membership"
                      value={perticularMembership[0]?.packageName || ""}
                      className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      disabled={perticularMembership[0]?.packageName}
                    />
                  </div>
                )}
              </div>

              <div className="grid grid-cols-1 h-[auto] gap-2 mb-6">
                <div className="grid grid-cols-2 gap-2">
                  <label htmlFor="Durations">Duration (Hours)</label>
                  <label htmlFor="Durations"> Minutes</label>
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <input
                    id="Durations"
                    value={hours}
                    onChange={handleTimeChange}
                    type="number"
                    className={
                      hoursAleart
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="hours"
                  />
                  <input
                    id="DurationsMinute"
                    value={minutes}
                    onChange={handleTimeChange}
                    type="number"
                    className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    placeholder="minutes"
                  />
                </div>
              </div>

              <div className=" w-[100%]  grid grid-cols-2 gap-4 mb-6">
                <div className="grid grid-cols-1 h-[auto] gap-2">
                  <label htmlFor="Gender">
                    Gender <span className="text-red-600 text-lg"> *</span>
                  </label>
                  <select
                    disabled={perticularUserData?.gender}
                    name="Gender"
                    id="Gender"
                    className={
                      genderAleart
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    value={perticularUserData?.gender || gender}
                    onChange={(e) => {
                      setGenderAleart(false);
                      setGender(e.target.value);
                    }}
                  >
                    <option value="">Choose Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="others">Others</option>
                  </select>
                </div>

                <div className="grid grid-cols-1 h-[auto] gap-2">
                  <label htmlFor="Staff">
                    Affiliate Staff{" "}
                    <span className="text-red-600 text-lg"> *</span>
                  </label>
                  <select
                    onChange={(e) => {
                      setAffiliateStaffAleart(false);
                      setAffiliateStaff(e.target.value);
                    }}
                    name="Staff"
                    id="Staff"
                    className={
                      affiliateStaffAleart
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                  >
                    <option value="">Choose Staff</option>
                    {staffs?.map((ele) => (
                      <option key={ele.userId._id} value={ele._id}>
                        {ele.userId.firstName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className=" flex justify-evenly items-center gap-2 mb-6">
                <button
                  disabled={isButtonDisabled}
                  onClick={() => handleSendNotification()}
                  className={`h-10 w-full flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-md shadow-lg shadow-[#ffdcc7] ${isButtonDisabled ? "cursor-not-allowed" : ""
                    }`}
                >
                  {isLoading ? (
                    <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                  ) : (
                    <span>Send</span>
                  )}
                </button>
                <button
                  onClick={() => handleGetStatus()}
                  className="h-10 w-full flex content-center items-center justify-center text-lg font-semibold text-white  bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-md shadow-lg shadow-[#ffdcc7]"
                >
                  {isLoading ? (
                    <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                  ) : (
                    <span>Verify</span>
                  )}
                </button>
              </div>

              {isClient && (
                <div className=" w-[100%]  grid grid-cols-1 gap-4 my-2">
                  <div
                    className={`${perticularUserData.walletBalance === 0 ||
                      perticularUserData.numberOfServices === 0 ||
                      perticularUserData.membershipId == null
                      ? "hidden"
                      : "grid grid-cols-1 h-[auto] gap-2"
                      } `}
                  >
                    <div>
                      <label htmlFor="remain">No of services remains</label>
                    </div>
                    <input
                      type="text"
                      name="remain"
                      id="remain"
                      className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      placeholder={
                        perticularUserData?.numberOfServices
                          ? perticularUserData.numberOfServices
                          : 0
                      }
                      disabled
                    />
                  </div>
                </div>
              )}

              <div className=" w-[100%]  grid grid-cols-2 gap-4 my-2">
                {isClient && (
                  <div
                    className={`${perticularUserData.walletBalance === 0 ||
                      perticularUserData.numberOfServices === 0 ||
                      perticularUserData.membershipId == null
                      ? "hidden"
                      : "grid grid-cols-1 h-[auto] gap-2"
                      } `}
                  >
                    <div>
                      <label htmlFor="balance">
                        Your Current Wallet Ballance{" "}
                      </label>
                    </div>
                    <input
                      type="text"
                      name="balance"
                      id="balance"
                      className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      placeholder={
                        perticularUserData?.walletBalance
                          ? perticularUserData.walletBalance
                          : 0
                      }
                      disabled
                    />
                  </div>
                )}

                <div className="grid grid-cols-1 h-[auto]">
                  <label htmlFor="GstCharge">GSTIN %</label>
                  <select
                    onChange={(e) => setGsting(e.target.value)}
                    name="GstCharge"
                    id="GstCharge"
                    className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                  >
                    <option value="0%">Select GSTIN</option>
                    <option value="3%">3%</option>
                    <option value="4%">4%</option>
                    <option value="5%">5%</option>
                    <option value="10%">10%</option>
                  </select>
                </div>
              </div>
              {isClient ? (
                <div className="grid grid-cols-1 h-[auto] gap-2 mb-6">
                  <label htmlFor="payment">Payment Method</label>
                  <select
                    onChange={(e) => {
                      setPaymentMethodAleart(false);
                      setPaymentMethod(e.target.value);
                    }}
                    name="payment"
                    id="payment"
                    className={
                      paymentMethodAleart
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                  >
                    <option value="">Choose Payment Method</option>
                    <option value="Cash">Cash</option>
                    <option value="UPI">UPI</option>
                    <option value="Card">Card</option>
                    <option
                      className={`${perticularUserData.walletBalance === 0 ||
                        perticularUserData.numberOfServices === 0 ||
                        perticularUserData.membershipId == null
                        ? "hidden"
                        : "grid grid-cols-1 h-[auto]"
                        } `}
                      value="Wallet Balance + Cash"
                    >
                      Wallet Balance + Cash
                    </option>
                    <option
                      className={`${perticularUserData.walletBalance === 0 ||
                        perticularUserData.numberOfServices === 0 ||
                        perticularUserData.membershipId == null
                        ? "hidden"
                        : "grid grid-cols-1 h-[auto]"
                        } `}
                      value="Wallet Balance + Upi"
                    >
                      Wallet Balance + UPI
                    </option>
                    <option
                      className={`${perticularUserData.walletBalance === 0 ||
                        perticularUserData.numberOfServices === 0 ||
                        perticularUserData.membershipId == null
                        ? "hidden"
                        : "grid grid-cols-1 h-[auto]"
                        } `}
                      value="Wallet Balance + Card"
                    >
                      Wallet Balance + Card
                    </option>
                    <option
                      className={`${perticularUserData.walletBalance === 0 ||
                        perticularUserData.numberOfServices === 0 ||
                        perticularUserData.membershipId == null
                        ? "hidden"
                        : "grid grid-cols-1 h-[auto]"
                        } `}
                      value="Wallet Balance"
                    >
                      Wallet
                    </option>
                  </select>
                </div>
              ) : (
                <div className="grid grid-cols-1 h-[auto] gap-2 mt-1 mb-6">
                  <label htmlFor="paymentMethod">Payment Method</label>
                  <select
                    onChange={(e) => {
                      setPaymentMethodAleart(false);
                      setPaymentMethod(e.target.value);
                    }}
                    name="paymentMethod"
                    id="paymentMethod"
                    className={
                      paymentMethodAleart
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                  >
                    <option value="">Choose Payment Method</option>
                    <option value="Cash">Cash</option>
                    <option value="UPI">UPI</option>
                    <option value="Card">Card</option>
                  </select>
                </div>
              )}

              <div className="w-full grid grid-cols-1 mb-4">
                <select
                  value={discount}
                  onChange={(e) => {
                    setDiscount(e.target.value);
                  }}
                  name="discounts"
                  id="discounts"
                  className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                >
                  <option value="">Select Discount</option>
                  {discountList?.map((item) => (
                    <option key={item._id} value={item._id} className="py-1">
                      {item.name} -{" "}
                      {item.type === "percentage"
                        ? `${item.amount}%`
                        : item.amount}
                    </option>
                  ))}
                </select>
              </div>

              <div className=" w-[100%]  grid grid-cols-2 gap-4 mb-2">
                <div className="grid grid-cols-1 h-[auto]">
                  <h1>Total Service Charge : {totalServiceCharge}</h1>
                  <h1>GST Charge : {gstAmount}</h1>
                  <h1>
                    Total including gst amount & discount:{" "}
                    {serviceAfterDiscount + gstAmount}
                  </h1>
                </div>
              </div>

              <div className="flex items-center justify-center mt-5">
                <button
                  disabled={isButtonDisabled}
                  onClick={handleButtonClick}
                  className="h-12 w-[200px] flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                >
                  {isLoading ? (
                    <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                  ) : (
                    <span>Download Bill</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default BillingModal;
