import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import html2pdf from "html2pdf.js";
import {
  decryptData,
  getEncryptItems,
} from "../../Screens/Common/localStorageUtils";
import { useSelector } from "react-redux";
import pdf from "../../Assets/Pdf.png";
import InvoiceDownload from "./InvoiceDownload/InvoiceDownload";
import edit from "../../Assets/edit.png";
import view from "../../Assets/view.png";
import recyclebin from "../../Assets/recyclebin.png";
import InvoiceDeleteModal from "./InvoiceModal/InvoiceDeleteModal";
import { allBillData, updateCustomerWallet } from "../../Screens/Common/initData";
import { allBillList, setIsInvoiceForEdit, storeClient, storeDraftBill } from "../../state-management/main/actions";
import { useDispatch } from "react-redux";
import InvoiceViewModal from "./InvoiceModal/InvoiceViewModal";
import { ImSearch } from "react-icons/im";
import Dropdown from "../../Screens/Common/Dropdown";
import SkeletonTableLoader from "../../Screens/Common/Loading/SkeletonTableLoader";
import clear from "../../Assets/billClear.png";
import DownloadTableData from "../../Screens/Common/downloadData";
import { RiDeleteBin5Fill, RiEdit2Fill } from "react-icons/ri";
import { LuEye } from "react-icons/lu";
import NewLoader from "../../Screens/Common/NewLoader";
import { useNavigate } from "react-router-dom";

const AllInvoice = (props) => {
  const [AllBillData, setAllBillData] = useState([]);
  const [singleInvoice, setSingleInvoice] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState("All Invoice");
  const [openModal, setOpenModal] = useState(false);
  const [isSelected, setIsSelected] = useState("All");
  const [viewModal, setViewModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [results, setResults] = useState([]);
  const [startFrom, setStartFrom] = useState();
  const [endFrom, setEndFrom] = useState();
  const [id, setId] = useState("");
  const branch = useSelector((state) => state?.main?.branch);
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const dispatch = useDispatch();
  let userRole = decryptData()?.user?.role;
  const userRoleName = getEncryptItems("customRoles");

  const getBillData = async () => {
    const data = await allBillData();
    dispatch(allBillList(data?.filter(ele => !ele?.isDeleted)));
  };
  useEffect(() => {
    getBillData();
  }, [branch]);

  useEffect(() => {
    FetchBillingData();
  }, [selectedFilter, branch]);
  const branchName = branch?.name || "";
  const branchLocation = branch?.address || "";
  const branchPhone = branch?.primaryPhone || "";
  const branchPic = branch?.url;
  const navigate = useNavigate();
  const user = decryptData()?.user;

  const today = new Date().toISOString().slice(0, 10);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const filteredBills =
    isSelected === "Today"
      ? AllBillData?.filter((item) => item?.date === today)
      : isSelected === "YesterDay"
        ? AllBillData?.filter(
          (item) =>
            item?.date === new Date(yesterday).toISOString().slice(0, 10)
        )
        : isSelected === "Month"
          ? AllBillData?.filter((item) => {
            const itemDate = new Date(item?.date);
            return (
              itemDate.getMonth() === currentMonth &&
              itemDate.getFullYear() === currentYear
            );
          })
          : isSelected === "Custom Date"
            ? AllBillData?.filter((item) => {
              const fromDate = new Date(startFrom);
              const toDate = new Date(endFrom);
              const itemDate = new Date(item?.date);
              return itemDate >= fromDate && itemDate <= toDate;
            })
            : AllBillData;

  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    results?.length && searchInput
      ? results?.slice(indexOfFirstItem, indexOfLastItem)
      : filteredBills?.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  function formatDateString(dateString) {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const allDeletedBill = async () => {
    const decryptedData = decryptData();
    const role = decryptedData?.user?.role;
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) {
      return;
    } else if (role === "staff") {
      return;
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/billing/deleted-bill`,
      headers: {
        Authorization: token,
      },
    };

    try {
      const res = await axios.request(config);
      return res?.data?.data;

    } catch (error) {
      console.log(error);
    }
  };

  const FetchBillingData = async () => {
    const token = "Bearer " + localStorage.getItem("refresh_token");

    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/billing/find-billing?populate=membershipIds`,
      headers: {
        Authorization: token,
      },
    };

    setIsLoading(true);
    try {
      let billingData = [];

      if (selectedFilter === "Deleted Invoice") {
        // Fetch deleted bills directly from deleted-bill API
        const deletedBills = await allDeletedBill();
        billingData = deletedBills.filter(
          (item) => item?.organizationId === branch?._id
        );
        console.log("deletedBills",deletedBills)
      } else {
        // Fetch regular bills and apply filters
        const res = await axios.request(config);
        const filteredBills = res?.data?.data?.filter(
          (item) => item?.organizationId === branch?._id && !item?.isDeleted
        );

        switch (selectedFilter) {
          case "Filter Invoices":
            billingData = filteredBills.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            break;
          case "Invoice With Membership":
            billingData = filteredBills.filter(
              (item) => item?.membershipIds?.length > 0
            );
            break;
          case "Invoice With No Membership":
            billingData = filteredBills.filter(
              (item) => item?.membershipIds?.length === 0
            );
            break;
          default:
            billingData = [];
        }
      }

      setAllBillData(billingData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setAllBillData([]);
    }
  };
  const fetchClient = async (phone) => {
    if (phone) {
      setIsLoading(true);

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/customer/singleCustomer?phone=${phone}`,
        headers: {
          'Authorization': token,
        },
      };

      try {
        const response = await axios.request(config);
        dispatch(storeClient(response?.data?.data?.customer));
        return response?.data?.data;
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const openViewModal = async (id) => {
    const selectedInvoice = AllBillData?.find((item) => item?._id === id);
    await fetchClient(`+91${selectedInvoice?.billingInfo?.phone}`);
    dispatch(storeDraftBill(selectedInvoice));
    setSingleInvoice(selectedInvoice);
    setViewModal(true);
  };

  const handleEdit = async (id) => {
    const selectedInvoice = AllBillData?.find((item) => item?._id === id);
    const newClient = await fetchClient(`+91${selectedInvoice?.billingInfo?.phone}`);

    const walletBalanceFromInvoice = selectedInvoice?.paymentMethods?.find(ele => ele?.payment === "WalletBalance")?.amount;
    const newWalletBalance = walletBalanceFromInvoice + newClient?.walletBalance;
    const updatedClient = await updateCustomerWallet(newWalletBalance, selectedInvoice.customer, 0);
    dispatch(storeClient(updatedClient));
    await Promise.all([
      dispatch(storeDraftBill(selectedInvoice)),
      dispatch(setIsInvoiceForEdit(true))
    ]);
    setSingleInvoice(selectedInvoice);
    // Now navigate to the dashboard
    navigate(`/${user?.role}/dashboard`);
  }

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    const filteredData = filteredBills?.filter(
      (ele) =>
        ele?.billingInfo?.name
          ?.toLowerCase()
          ?.includes(searchInput?.toLowerCase())
    );
    setResults(filteredData);
  };

  const handleClear = () => {
    // fetchClients();
    // setFilterDate("");
    setSearchInput("");
    setResults([]);
    setCurrentPage(1);
  };

  const handleSelectMembershipType = (value) => {
    setSelectedFilter(value || "");
    setCurrentPage(1);
  };

  const handleSelectDateType = (value) => {
    setIsSelected(value || "");
    setCurrentPage(1);
  };

  useEffect(() => {
    if (searchInput?.length === 0) {
      setResults([]);
    }
  }, [searchInput]);

  const rowData = currentItems?.map((data) => ({
    Client: data?.billingInfo?.name,
    Phone: data?.billingInfo?.phone,
    Date: data?.date,
    Billed: data?.paidAmount,
    Status: data?.status || "paid",
  }));
  const headerData = [
    { label: "Client", field: "Client" },
    { label: "Phone", field: "Phone" },
    { label: "Date", field: "Date" },
    { label: "Billed", field: "Billed" },
    { label: "Status", field: "Status" },
  ];

  const downloadInvoicesAsPDF = () => {
    const container = document.createElement('div');

    currentItems.forEach((invoice, index) => {
      const invoiceHTML = `
        <div class="invoice" style="page-break-after: always; font-family: Arial, sans-serif; max-width: 800px; margin: 0 auto; padding: 20px;">
          <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;">
            <img src='${branchPic}' alt="Kaya Spa" style="max-width: 100px;">
            <div style="text-align: right;">
              <h2 style="margin: 0;">INVOICE</h2>
              <p style="margin: 5px 0;">Invoice No. - ${invoice?.invoiceNumber}</p>
              <p style="margin: 5px 0;">Date/Time - ${new Date(invoice?.date).toLocaleDateString()}, ${new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}</p>
            </div>
          </div>
          
          <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
            <div>
              <h3 style="margin: 0 0 10px 0;">Invoice From:</h3>
              <p style="margin: 0;">${branchName}</p>
              <p style="margin: 0;">AT - ${branchLocation}</p>
              <p style="margin: 0;">${branchPhone}</p>
            </div>
            <div style="text-align: right;">
              <h3 style="margin: 0 0 10px 0;">Invoice To:</h3>
              <p style="margin: 0;">${invoice?.billingInfo?.name || ""}</p>
              <p style="margin: 0;">${invoice?.billingInfo?.phone || ""}</p>
              <p style="margin: 0;">Wallet Bal.: Rs.${invoice?.walletApplied?.toFixed(2) || "0.00"}/-</p>
              <p style="margin: 0;">Loyalty Point Bal: 0.00/-</p>
            </div>
          </div>
          
                  <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px; border: 1px solid #ddd;">
          <tr style="background-color: #f2994a; color: white;">
            <th style="padding: 10px; text-align: left; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">SL NO</th>
            <th style="padding: 10px; text-align: left; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">SERVICES</th>
            <th style="padding: 10px; text-align: center; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">QTY</th>
            <th style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">AMOUNT</th>
            <th style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">DISCOUNT</th>
            <th style="padding: 10px; text-align: right; border-bottom: 1px solid #ddd;">TOTAL AMOUNT</th>
          </tr>
          ${invoice?.services?.map((service, index) => `
            <tr style="background-color: ${index % 2 === 0 ? '#f9f9f9' : 'white'};">
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${index + 1}</td>
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${service?.serviceName} (${service?.duration})</td>
              <td style="padding: 10px; text-align: center; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${service?.quantity}</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">₹${service?.itemPerPrice * service?.quantity}</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${service?.discount || ""} ${service?.discountType === "percentage" ? "%" : ""}</td>
              <td style="padding: 10px; text-align: right; border-bottom: 1px solid #ddd;">₹${service?.price}</td>
            </tr>
          `).join('')}
          ${invoice?.products?.map((product, index) => `
            <tr style="background-color: ${(invoice?.services?.length + index) % 2 === 0 ? '#f9f9f9' : 'white'};">
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${invoice?.services?.length + index + 1}</td>
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${product?.productName}</td>
              <td style="padding: 10px; text-align: center; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${product?.quantity}</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">₹${product?.itemPerPrice * product?.quantity}</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${product?.discount || ""} ${product?.discountType === "percentage" ? "%" : ""}</td>
              <td style="padding: 10px; text-align: right; border-bottom: 1px solid #ddd;">₹${product?.price}</td>
            </tr>
          `).join('')}
          ${invoice?.membershipIds?.map((member, index) => `
            <tr style="background-color: ${(invoice?.services?.length + invoice?.products?.length + index) % 2 === 0 ? '#f9f9f9' : 'white'};">
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${invoice?.services?.length + invoice?.products?.length + index + 1}</td>
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${member?.packageName || ""}</td>
              <td style="padding: 10px; text-align: center; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">1</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">₹${member?.price || 0}</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${member?.discount || 0} ${member?.discountType === "percentage" ? "%" : ""}</td>
              <td style="padding: 10px; text-align: right; border-bottom: 1px solid #ddd;">₹${(member?.price || 0) - (member?.discount || 0)}</td>
            </tr>
          `).join('')}
        </table>
          
          <div style="margin-bottom: 20px;">
            <p style="margin: 5px 0; text-align: right;">Amount: ₹${invoice?.finalPrice}</p>
            <p style="margin: 5px 0; text-align: right;">Total (Rounded off): ₹${invoice?.finalPrice}</p>
            <p style="margin: 5px 0; text-align: right;">Paid Amount: ₹${invoice?.paidAmount}</p>
          </div>
          
          <div>
            <h3 style="margin: 0 0 10px 0;">Terms & Conditions</h3>
            <ul style="margin: 0; padding-left: 20px;">
              <li>Money Once Paid Shall not be refunded</li>
              <li>Services are not transferable</li>
            </ul>
          </div>
        </div>
      `;

      container.innerHTML += invoiceHTML;
    });

    const opt = {
      margin: 10,
      filename: 'invoices.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().from(container).set(opt).save();
  };

  return (
    <React.Fragment>
      {(userRole === "admin" ||
        (userRole === "staff" &&
          userRoleName?.roleId?.permissions?.invoice?.sidebarInvoice)) && (
          <div className="mt-4 mx-auto">
            <div className="px-5 py-5 bg-white w-full border-t rounded-t-lg flex flex-wrap justify-between gap-3">
              <div className="gap-2 items-center lg:block hidden">
                <div className="font-bold text-2xl tracking-wide">Invoice</div>
              </div>

              <div className="flex flex-col lg:items-center gap-2">
                <div>
                  <Dropdown
                    label="Today"
                    items={["Today", "YesterDay", "Month", "Custom Date"]}
                    onSelect={handleSelectDateType}
                  />
                </div>
                <div>
                  {isSelected === "Custom Date" && (
                    <div className="flex flex-wrap gap-2">
                      <div className="flex justify-center items-center gap-6">
                        <label>From Date</label>
                        <input
                          value={startFrom}
                          onChange={(e) => setStartFrom(e.target.value)}
                          type="date"
                          className="p-2 h-11 border rounded-md border-gray-300 focus:outline-none focus:ring-1 ring-[#845ff6] ease-linear transition-all duration-150"
                        />
                      </div>
                      <div className="flex justify-center items-center gap-6">
                        <label>To Date</label>
                        <input
                          value={endFrom}
                          onChange={(e) => setEndFrom(e.target.value)}
                          type="date"
                          className="p-2 h-11 border rounded-md border-gray-300 focus:outline-none focus:ring-1 ring-[#845ff6] ease-linear transition-all duration-150"
                        />
                      </div>
                      <button
                        onClick={() => {
                          setStartFrom("");
                          setEndFrom("");
                          setIsSelected("All");
                        }}
                      >
                        <img src={clear} alt="clear" className="w-8 h-8" />
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div>
                <Dropdown
                  label="Filter Invoices"
                  items={userRole === "admin" ? [
                    "Filter Invoices",
                    "Invoice With Membership",
                    "Invoice With No Membership",
                    "Deleted Invoice",
                  ] : [
                    "Filter Invoices",
                    "Invoice With Membership",
                    "Invoice With No Membership",
                  ]}
                  onSelect={handleSelectMembershipType}
                />
              </div>

              <div>
                <div className="bg-white rounded-lg w-full  flex p-1 relative border border-gray-200 hover:border-primaryHoverColor hover:border-1">
                  <span className="flex items-center justify-center transition ease-in-out duration-300 ml-2">
                    <ImSearch color="gray" size={15} />
                  </span>
                  <input
                    id="searchData"
                    className="border-0 rounded px-8 py-1 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none w-full pr-6"
                    placeholder="Search By Name"
                    type="text"
                    value={searchInput}
                    onChange={(e) => {
                      handleSearch(e);
                      setCurrentPage(1);
                    }}
                  />
                  {searchInput && (
                    <span
                      className="absolute right-14 top-2 cursor-pointer"
                      onClick={handleClear}
                    >
                      X
                    </span>
                  )}
                </div>
              </div>

              <div>
                <div className="flex flex-row items-center justify-center gap-2 flex-wrap mb-2">
                  {/* <DownloadTableData rowData={rowData} headerData={headerData} /> */}
                  <img src={pdf} alt="download" onClick={downloadInvoicesAsPDF} className="w-7 h-7 cursor-pointer" />
                </div>
              </div>
            </div>

            {isLoading ? (
              <SkeletonTableLoader />
            ) : currentItems?.length === 0 ? (
              <div className="flex justify-center bg-[#fff] w-full shadow-md py-4">
                No Invoice found
              </div>
            ) : (
              <>
                {searchInput && results?.length === 0 && <h1 className="flex justify-center font-semibold text-lg tracking-widest pt-2 pb-6 bg-white">
                  No Invoice Found
                </h1>}
                <div className="shadow rounded-t-lg overflow-x-auto">
                  <table className="min-w-full leading-normal">
                    <thead>
                      <tr className="bg-gray-100">
                        <th className="px-5 py-3 border-2 border-gray-200  text-sm font-semibold text-gray-600 tracking-wide text-center">
                          Sl
                        </th>
                        <th className="px-5 py-3 border-2 border-gray-200  text-sm font-semibold text-gray-600 tracking-wide text-center">
                          ID
                        </th>

                        <th className="px-5 py-3 border-2 border-gray-200  text-sm font-semibold text-gray-600 tracking-wide text-center">
                          Client Detail
                        </th>

                        <th className="px-5 py-3 border-2 border-gray-200  text-sm font-semibold text-gray-600 tracking-wide text-center">
                          Service Used
                        </th>

                        <th className="px-5 py-3 border-2 border-gray-200  text-sm font-semibold text-gray-600 tracking-wide text-center">
                          Product Sold
                        </th>
                        <th className="px-5 py-3 border-2 border-gray-200  text-sm font-semibold text-gray-600 tracking-wide text-center">
                          Billed
                        </th>
                        <th className="px-5 py-3 border-2 border-gray-200  text-sm font-semibold text-gray-600 tracking-wide text-center">
                          Status
                        </th>
                        <th className="px-5 py-3 border-2 border-gray-200 text-sm font-semibold text-gray-600 tracking-wide">
                          {(userRole === "admin" ||
                            (userRole === "staff" &&
                              (userRoleName?.roleId?.permissions?.invoice
                                ?.invoiceEdit ||
                                userRoleName?.roleId?.permissions?.invoice
                                  ?.invoiceDelete ||
                                userRoleName?.roleId?.permissions?.invoice
                                  ?.invoiceView))) && <span>Actions</span>}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems?.map((ele, ind) => (
                        <tr
                          className={ele?.isDeleted ? 'bg-gray-200' : ''}
                          key={ele._id}>
                          <td className="px-5 py-5 border border-gray-200 text-sm">
                            <div className="text-gray-900 whitespace-no-wrap text-center">
                              {currentPage * 5 + ind - 4}
                            </div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 text-sm">
                            <div className="text-gray-900 whitespace-no-wrap text-center">
                              {ele?.invoiceNumber} {ele?.isDraftInvoice ? <span className="text-primaryColor text-xs">(Draft)</span> : ""}
                            </div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 text-sm">
                            <div className="text-gray-900 whitespace-no-wrap text-center">
                              <h1>
                                {ele?.billingInfo?.name}
                              </h1>
                              <h1>
                                {ele?.billingInfo?.phone}
                              </h1>
                            </div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 text-sm">
                            <div className="text-gray-900 whitespace-no-wrap text-center">
                              {ele?.services?.map((service, index) => (
                                <div key={index}>
                                  <p>{service?.serviceName}</p>
                                  <p>{service?.duration}</p>
                                </div>
                              ))}
                            </div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 text-sm">
                            <div className="text-gray-900 whitespace-no-wrap text-center">
                              {ele?.products?.map((service, index) => (
                                <div key={index}>
                                  <p>{service?.serviceName}</p>
                                  <p>{service?.duration}</p>
                                </div>
                              ))}
                            </div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 text-sm">
                            <div className="text-gray-900 whitespace-no-wrap text-center">
                              {ele?.paidAmount}
                            </div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 text-sm">
                            <div className="whitespace-no-wrap flex items-center justify-center">
                              {ele?.status || "Paid"}
                            </div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 text-sm">
                            <div className="whitespace-no-wrap flex gap-3 items-center justify-center">

                              {(userRole === "admin" ||
                                (userRole === "staff" &&
                                  userRoleName?.roleId?.permissions?.invoice
                                    ?.invoiceDelete)) && !ele?.isDeleted && (
                                  <RiDeleteBin5Fill
                                    className="text-red-500 hover:text-primaryHoverColor transition ease-in-out hover:duration-300 cursor-pointer"
                                    onClick={() => {
                                      setId(ele?._id);
                                      setOpenModal(true);
                                    }}
                                  />
                                )}

                              {/* {(userRole === "admin" ||
                                (userRole === "staff" &&
                                  userRoleName?.roleId?.permissions?.invoice
                                    ?.invoiceDelete)) && (
                                  <RiEdit2Fill
                                    className="text-red-500 hover:text-primaryHoverColor transition ease-in-out hover:duration-300 cursor-pointer"
                                    onClick={() => handleEdit(ele?._id)}
                                  />
                                )} */}


                              {userRole === "admin" && (
                                <InvoiceDownload
                                  id={ele?._id}
                                  className="cursor-pointer"
                                />
                              )}

                              {(userRole === "admin" ||
                                (userRole === "staff" &&
                                  userRoleName?.roleId?.permissions?.invoice?.invoiceView)) && (
                                  isLoading ? (
                                    <NewLoader />
                                  ) : (
                                    <LuEye
                                      className="cursor-pointer w-6 h-6 text-orange-500"
                                      onClick={() => {
                                        openViewModal(ele?._id);
                                        setId(ele?._id);
                                      }}
                                    />
                                  )
                                )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {currentItems?.length !== 0 || results?.length !== 0 ? (
              <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
                <span className="text-xs xs:text-sm text-gray-900">
                  Showing {indexOfFirstItem + 1} to{" "}
                  {results?.length
                    ? indexOfFirstItem + results?.length
                    : indexOfFirstItem + currentItems?.length}{" "}
                  of {results?.length ? results?.length : filteredBills?.length}{" "}
                  Entries
                </span>
                <div className="inline-flex mt-2 xs:mt-0">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`text-sm ${currentPage === 1
                      ? "bg-gray-200"
                      : "bg-gray-300 hover:bg-gray-400"
                      } text-gray-800 font-semibold py-2 px-4 rounded-l`}
                  >
                    Prev
                  </button>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={
                      currentPage ===
                      Math.ceil(
                        results?.length
                          ? results?.length / itemsPerPage
                          : filteredBills?.length / itemsPerPage
                      )
                    }
                    className={`text-sm ${currentPage ===
                      Math.ceil(
                        results?.length
                          ? results?.length / itemsPerPage
                          : filteredBills?.length / itemsPerPage
                      )
                      ? "bg-gray-200"
                      : "bg-gray-300 hover:bg-gray-400"
                      } text-gray-800 font-semibold py-2 px-4 rounded-r`}
                  >
                    Next
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        )}

      <InvoiceDeleteModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        id={id}
        setId={setId}
        getBillData={getBillData}
        FetchBillingData={FetchBillingData}
      />

      {singleInvoice && (
        <InvoiceViewModal
          viewModal={viewModal}
          setViewModal={setViewModal}
          AllBills={props?.AllBills}
          singleInvoice={singleInvoice}
          id={id}
          setId={setId}
          FetchBillingData={FetchBillingData}
          getBillData={getBillData}
        />
      )}
    </React.Fragment>
  );
};

export default AllInvoice;
