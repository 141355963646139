import * as actionTypes from "./actionTypes";

const initialState = {
  loginUserData: null,
  orgList: null,
  managerList: null,
  staffList: null,
  clientList: null,
  inventoryService: null,
  membershipList: null,
  laserList: null,
  appointmentList: null,
  billList: null,
  membershipRevenueList: null,
  discountList: null,
  supplierList: null,
  customerWithMembershipList: null,
  productList: null,
  packageList: null,
  serviceVsStaffData: null,
  billForm: {},
  newBill: null,
  draftBill: null,
  clientDuringBill: {},
  appointmentDetailFromCalendar: {},
  branch: {},
  isInvoiceForEdit: false,
};

let newState;

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_LOGIN_DATA: {
      newState = {
        ...state,
        loginUserData: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_ORG_LIST: {
      newState = {
        ...state,
        orgList: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_MANAGER_LIST: {
      newState = {
        ...state,
        managerList: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_STAFF_LIST: {
      newState = {
        ...state,
        staffList: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_CLIENT_LIST: {
      newState = {
        ...state,
        clientList: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_INVENTORY_SERVICE: {
      newState = {
        ...state,
        inventoryService: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_DISCOUNT_LIST: {
      newState = {
        ...state,
        discountList: action.payload,
      };
      return newState;
    }

    case actionTypes.All_MEMBERSHIPS_LIST: {
      newState = {
        ...state,
        membershipList: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_LASER_LIST: {
      newState = {
        ...state,
        laserList: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_APPOINTMENT_LIST: {
      newState = {
        ...state,
        appointmentList: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_PRODUCT_LIST: {
      newState = {
        ...state,
        productList: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_BILL_LIST: {
      newState = {
        ...state,
        billList: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_MEMBERSHIP_REVENUE: {
      newState = {
        ...state,
        membershipRevenueList: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_SUPPLIER_LIST: {
      newState = {
        ...state,
        supplierList: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_CUSTOMER_WITH_MEMBERSHIP: {
      newState = {
        ...state,
        customerWithMembershipList: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_PACKAGE_LIST: {
      newState = {
        ...state,
        packageList: action.payload,
      };
      return newState;
    }

    case actionTypes.SERVICE_VS_STAFF: {
      newState = {
        ...state,
        serviceVsStaffData: action.payload,
      };
      return newState;
    }
    case actionTypes.BILL_FORM: {
      newState = {
        ...state,
        billForm: action.payload,
      };
      return newState;
    }

    case actionTypes.CLEAR_BILL_FORM: {
      newState = {
        ...state,
        billForm: null,
      };
      return newState;
    }

    case actionTypes.NEW_CREATED_BILL: {
      newState = {
        ...state,
        newBill: action.payload,
      };
      return newState;
    }

    case actionTypes.DRAFT_BILL: {
      newState = {
        ...state,
        draftBill: action.payload,
      };
      return newState;
    }

    case actionTypes.CLEAR_DRAFT_BILL: {
      newState = {
        ...state,
        draftBill: null,
      };
      return newState;
    }

    case actionTypes.CLIENT_WHILE_BILLING: {
      newState = {
        ...state,
        clientDuringBill: action.payload,
      };
      return newState;
    }

    case actionTypes.CLEAR_CLIENT_WHILE_BILLING: {
      newState = {
        ...state,
        clientDuringBill: {},
      };
      return newState;
    }

    case actionTypes.CLEAR_NEW_BILL: {
      newState = {
        ...state,
        newBill: {},
      };
      return newState;
    }
    case actionTypes.APPOINTMENT_FROM_CALENDAR: {
      newState = {
        ...state,
        appointmentDetailFromCalendar: action.payload,
      };
      return newState;
    }

    case actionTypes.SET_BRANCH: {
      newState = {
        ...state,
        branch: action.payload,
      };
      return newState;
    }

    case actionTypes.IS_INVOICE_FOR_EDIT: {
      newState = {
        ...state,
        isInvoiceForEdit: action.payload,
      };
      return newState;
    }

    case actionTypes.LOGOUT_USER: {
      return initialState;
    }

    default:
      return state;
  }
};

export default mainReducer;
