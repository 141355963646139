import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { getEncryptItems } from "../../../Screens/Common/localStorageUtils";
import { FaDownload, FaGift } from "react-icons/fa";
import PaymentModal from "./PaymentModal";
import { HiOutlineMailOpen } from "react-icons/hi";
import { MdOutlineTextsms } from "react-icons/md";
import { SlPrinter } from "react-icons/sl";
import { GrView } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import kaya from "../../../Assets/Frame 427321207.png";
import html2pdf from "html2pdf.js";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { MdModeEditOutline } from "react-icons/md";
import {
  clearBillForm,
  clearClient,
  clearDraftBill,
  setBillForm,
  storeClient,
  storeDraftBill,
  storeNewBill,
} from "../../../state-management/main/actions";
import PaymentEditModal from "./PaymentEditModal";
import { updateBilling, updateCustomerWallet } from "../../../Screens/Common/initData";
import NewLoader from "../../../Screens/Common/NewLoader";
import { fetchClient } from "./billModalMethods";
import AvailableServicesPopover from "./AvailableServicesPopover";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "90%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  outline: "none",
  py: 1,
  px: 4,
};

const InvoicePreviewModal = ({
  // setIsModalOpen,
  setClient,
  singleInvoice,
  setViewModal,
  viewModal,
  setFormData,
  initialState,
  setAddMember,
  setIsEditModalOpen,
  setDraftBillCreated
}) => {
  const [previewOption, setPreviewOption] = useState("webview");
  const [isWalletApplied, setIsWalletApplied] = useState(false);
  const [applyBalance, setApplyBalance] = useState(false);
  const [applyService, setApplyService] = useState(false);
  const [applyGiftCard, setapplyGiftCard] = useState(false);
  const [applyCoupon, setapplyCoupon] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [selectedContents, setSelectedContents] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const [openEditPayment, setOpenEditPayment] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [freeServicesApplied, setFreeServicesApplied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [availableClientServices, setAvailableClientServices] = useState([]);
  const branch = useSelector((state) => state?.main?.branch) || "";
  const dispatch = useDispatch();
  const BillData = useSelector((state) => state.main.billForm);
  const [paymentStatus, setPaymentStatus] = useState("pending");
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const printRef = useRef(null);
  const draftBill = useSelector(state => state?.main?.draftBill);
  const clientDuringBill = useSelector(state => state?.main?.clientDuringBill);

  const handlePreviewOptionChange = (option) => {
    setPreviewOption(option);
  };

  useEffect(() => {
    const fetchAndStoreClient = async () => {
      try {
        const client = await fetchClient(clientDuringBill?.phoneNumber);
        dispatch(storeClient(client?.customer));
        setAvailableClientServices(client?.availableServices)
      } catch (error) {
        console.error("Error fetching client:", error);
        setAvailableClientServices([]);
      }
    };

    fetchAndStoreClient();
  }, [viewModal, clientDuringBill?.phoneNumber, freeServicesApplied]);

  let freeServices = {};
  let serviceToBeBilled = [];
  let servicesIncludedInMembership = [];
  let totalServiceCharge = 0;

  BillData?.requiredServices?.forEach((service) => {
    // const quantity = clientDuringBill?.services[service?.serviceId] || 0;
    const quantity = BillData?.client?.services[service?.serviceId] || 0;

    if (quantity >= service?.quantity) {
      freeServices[service.serviceId] = service?.quantity || 0;
      servicesIncludedInMembership.push(service);
    } else {
      if (quantity > 0) {
        servicesIncludedInMembership.push(service);
      }

      const quantityLeft = service?.quantity - quantity || 0;
      const itemPerPrice = service?.itemPerPrice || 0;
      const priceBeforeDiscount = itemPerPrice * quantityLeft;

      let discountedPrice;

      if (service?.discountType === "percentage") {
        const discountAmount =
          priceBeforeDiscount * (service?.discount / 100 || 0);
        discountedPrice = priceBeforeDiscount - discountAmount;
      } else {
        discountedPrice = priceBeforeDiscount - (service?.discount || 0);
      }

      const newServiceObj = {
        ...service,
        quantity: quantityLeft,
        itemPerPrice: itemPerPrice,
        price: discountedPrice,
      };

      serviceToBeBilled.push(newServiceObj);
      freeServices[service?.serviceId] = quantity;
    }
  });

  const remainingServices = { ...BillData?.client?.services };
  for (const serviceId in freeServices) {
    if (remainingServices[serviceId]) {
      remainingServices[serviceId] -= freeServices[serviceId];
      if (remainingServices[serviceId] <= 0) {
        delete remainingServices[serviceId];
      }
    }
  }
  const allServiceCharge = BillData?.requiredServices?.reduce(
    (acc, service) => acc + service.price,
    0
  )
  const totalServiceChargeBeforeFree = serviceToBeBilled?.reduce((acc, service) => acc + service.price, 0);
  totalServiceCharge = draftBill?.freeServicesApplied
    ? serviceToBeBilled?.reduce((acc, service) => acc + service.price, 0)
    : BillData?.requiredServices?.reduce(
      (acc, service) => acc + service.price,
      0
    );
  const totalProductCharge = BillData?.requiredProducts?.reduce(
    (acc, product) => acc + Number(product.price),
    0
  );
  const freeServiceAmount = Number(allServiceCharge || 0) - Number(totalServiceChargeBeforeFree || 0);
  const totalMembershipPrice = BillData?.membershipAray?.[0]?.price || 0;
  const handleApplyFreeServices = async () => {
    try {
      setFreeServicesApplied(true);
      setIsLoading(true);

      const newClient = await updateCustomerWallet(
        clientDuringBill?.walletBalance,
        clientDuringBill?._id,
        Math.max(clientDuringBill?.dueAmount + draftBill?.finalPrice - freeServiceAmount, 0),
        BillData?.membership,
        remainingServices
      );

      const allPayment = draftBill?.paymentMethods?.reduce((acc, cur) => acc + cur?.amount, 0);

      const newBill = await updateBilling(
        draftBill?._id, {
        dueAmount: draftBill?.dueAmount - freeServiceAmount,
        freeServicesApplied: true,
        serviceBilledOn: serviceToBeBilled,
        servicesIncludedInMembership: servicesIncludedInMembership,
        paidAmount: draftBill?.paidAmount + Number(walletBalance),
        isDraftInvoice: false,
        paymentStatus: Number(totalServiceCharge + (totalProductCharge || 0) + (totalMembershipPrice || 0) - allPayment) > 0 ? "due" : "paid"
      }
      );

      if (newClient && newBill) {
        dispatch(storeClient(newClient));
        setClient(newClient);
        dispatch(storeDraftBill(newBill));
        setFreeServicesApplied(true);
      }

      dispatch(
        setBillForm({
          ...BillData,
          freeServicesApplied: true,
          serviceToBeBilled: serviceToBeBilled,
          remainingServices: remainingServices,
          servicesIncludedInMembership: servicesIncludedInMembership,
          freeServices: freeServices,
        })
      );

    } catch (error) {
      console.error("Error applying free services:", error);
      // You might want to handle the error further, such as displaying a notification to the user
      toast.error("something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCompleteButtonClick = () => {
    setPaymentModalOpen(true);
  };

  const handleBillModalOpen = () => {
    setIsEditModalOpen(true);
    // setIsModalOpen(true);
    setViewModal(false);
  };

  const handleViewPdf = () => {
    const content = renderPreviewContent();
    setSelectedContents(content);
    setPdfViewerOpen(true);
  };
  const handleApplyWalletBalance = async () => {
    if (Number(walletBalance) > clientDuringBill?.walletBalance + (BillData?.membershipAray?.[0]?.packagePrice || 0) - draftBill?.walletApplied) {
      alert("Please enter less than the current wallet balance");
      return;
    }

    const maximumApplicableWalletBalance = draftBill?.freeServicesApplied ?
      totalServiceCharge - BillData?.membershipAray?.[0]?.price
      : draftBill?.finalPrice - BillData?.membershipAray?.[0]?.price

    if (walletBalance > maximumApplicableWalletBalance) {
      alert("You can't apply wallet balance for purchasing of another memberships");
      return;
    }
    setIsLoading(true);
    try {
      const newClientDueAmount = draftBill?.freeServicesApplied ? clientDuringBill?.dueAmount - walletBalance
        : Math.max(clientDuringBill?.dueAmount + draftBill?.finalPrice - Number(walletBalance), 0)
      const newClient = await updateCustomerWallet(
        clientDuringBill?.walletBalance + (BillData?.membershipAray?.[0]?.packagePrice || 0) - Number(walletBalance),
        clientDuringBill?._id,
        newClientDueAmount, BillData?.membership, remainingServices);
      // const newClient = await updateCustomerWallet(
      //   clientDuringBill?.walletBalance + (BillData?.membershipAray?.[0]?.packagePrice || 0) - Number(walletBalance),
      //   clientDuringBill?._id,
      //   Math.max(clientDuringBill?.dueAmount + draftBill?.finalPrice - Number(walletBalance), 0), BillData?.membership, remainingServices);

      const previousPaymentMethods = draftBill?.paymentMethods;
      const paymentMethods = [
        ...previousPaymentMethods,
        {
          id: new Date().getTime(),
          payment: 'WalletBalance',
          amount: Number(walletBalance),
          paymentDate: new Date()?.toISOString()?.slice(0, 10)
        }
      ];

      const allPayment = paymentMethods?.reduce((acc, cur) => acc + cur?.amount, 0);

      const newDraftBill = await updateBilling(draftBill?._id, {
        paymentMethods,
        dueAmount: draftBill?.dueAmount - Number(walletBalance),
        // previousClientDue: clientDuringBill?.dueAmount,
        paidAmount: draftBill?.paidAmount + Number(walletBalance),
        walletApplied: draftBill?.walletApplied + Number(walletBalance),
        isDraftInvoice: draftBill?.walletApplied + Number(walletBalance) < 0,
        paymentStatus: Number(totalServiceCharge + totalProductCharge + (totalMembershipPrice || 0) - allPayment) > 0 ? "due" : "paid"
      });

      if (newClient && newDraftBill) {
        dispatch(storeClient(newClient));
        setClient(newClient);
        dispatch(storeDraftBill(newDraftBill));
        setIsWalletApplied(true);
        setWalletBalance(0);
        setApplyBalance(false);
      }
    } catch (error) {
      console.error("Error applying wallet balance:", error);
      alert("An error occurred while applying the wallet balance. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditPayments = async () => {
    setApplyBalance(true)
    setIsWalletApplied(false);
    setOpenEditPayment(true);
  }
  const handleWalletEdit = async (clientDuringBill, draftBill) => {
    try {
      setIsLoading(true);
      const newWalletBalance = clientDuringBill?.walletBalance + draftBill?.walletApplied;
      const newDueAmount = draftBill?.dueAmount + draftBill?.walletApplied;

      const updatedClient = await updateCustomerWallet(newWalletBalance, clientDuringBill._id, newDueAmount);

      if (!updatedClient) {
        throw new Error("Failed to update customer wallet");
      }

      const filteredPaymentMethods = draftBill?.paymentMethods.filter(method => method.payment !== "WalletBalance");

      const newPaidAmount = draftBill?.paidAmount - draftBill?.walletApplied;

      const updatedBill = await updateBilling(draftBill._id, {
        walletApplied: 0,
        paymentMethods: filteredPaymentMethods,
        paidAmount: newPaidAmount,
        dueAmount: newDueAmount,
        previousClientDue: clientDuringBill?.dueAmount,
      });

      if (!updatedBill) {
        throw new Error("Failed to update billing information");
      }

      dispatch(storeDraftBill(updatedBill));
      dispatch(storeClient(updatedClient));
      setClient(updatedClient);
      setApplyBalance(true);
      setIsWalletApplied(false);
    } catch (error) {
      console.error("Error in handleWalletEdit:", error);
      alert("An error occurred while editing the wallet. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrint = () => {
    const content = renderPreviewContent();
    const printWindow = window.open("", "_blank");

    if (previewOption === "pdfview") {
      // For thermal view, use the content as is
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
          </head>
          <body>${content}</body>
        </html>
      `);
    } else {
      // For web view, use the existing styling
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              body {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
                font-size: 10px; 
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 100vh;
              }
                
              @media print {
                body {
                  width: 100%;
                  height: 100%;
                }
                @page {
                  size: auto;
                  margin: 5mm; 
                }
                table {
                  border-collapse: collapse;
                  width: 100%;
                }
                th, td {
                  padding: 2px; /* Reduce cell padding */
                  text-align: left;
                  border: 1px solid #ddd;
                }
                .header {
                  margin-bottom: 10px; /* Reduce header margin */
                }
                .header img {
                  max-width: 60px; /* Reduce logo size */
                }
                h1, h2, h3 {
                  margin: 5px 0; /* Reduce heading margins */
                }
                p {
                  margin: 2px 0; /* Reduce paragraph margins */
                }
                .terms {
                  margin-top: 10px; /* Reduce terms margin */
                }
                .terms ul {
                  padding-left: 15px; /* Reduce list padding */
                }
              }
            </style>
          </head>
          <body>${content}</body>
        </html>
      `);
    }

    printWindow.document.close();
    printWindow.focus();

    printWindow.onload = function () {
      printWindow.print();
      printWindow.onafterprint = function () {
        printWindow.close();
      };
    };
  };

  const renderPreviewContent = () => {
    const branchName = branch?.name || "";
    const branchLocation = branch?.address || "";
    const branchPhone = branch?.primaryPhone || "";
    const branchPic = branch?.url || kaya;

    // const { billingInfo } = singleInvoice;

    const customerName =
      singleInvoice?.billingInfo?.name ||
      BillData?.client?.name ||
      BillData?.name ||
      "";
    const customerPhone =
      singleInvoice?.billingInfo?.phone ||
      BillData?.client?.phoneNumber ||
      BillData?.phoneNumber ||
      "";

    const invoiceNumber = draftBill?.invoiceNumber || "";

    if (previewOption === "pdfview") {
      const pdfContent = `
      <!DOCTYPE html>
        <html lang="en">

        <head>
          <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap" rel="stylesheet">
        <title>Invoice</title>
        <style>
        .dm-serif-display-regular {
        font-family: "DM Serif Display", serif;
        font-weight: 400;
        font-style: normal;
      }

      .dm-serif-display-regular-italic {
        font-family: "DM Serif Display", serif;
        font-weight: 400;
        font-style: italic;
      }

          body {
            margin: 0;
            padding: 0;
            line-height: 1.4;
          }

          .container {
            max-width: 380px;
            margin: 0 auto;
            padding: 10px;
          }

          .header {
            text-align: center;
            font-weight: 900;
            margin-bottom: 10px;
            padding-bottom: 5px;
          }
        .header h3{
        margin:0;
          }
        .header h4{
          margin:0;
          font-size:25px;
          }
          .logo {
            margin-bottom: 5px;
          }

          .address {
            margin: 5px 0;
          }


          .tables td{
          text-align:left;
          }
            .table {
            width: 100%; 
            margin-bottom: 10px;
            font-weight: 900;
            margin: 0 auto;
          }

          .table th {
            text-align: left;
            padding: 0;
          }

          .table td {
            padding: 0;
            text-align: right;
          }

          .table td:first-child {
            text-align: left;
          }

          .total {
            text-align: right;
            font-weight: bold;
          }

          .footer {
            text-align: center;
            font-weight: 900;
            margin-top: 10px;
            padding-top: 5px;
          }
        .tabletopDiv {
          display: flex;
          justify-content: space-between;
          align-items: center;
      }
          .tabletopDiv h2{
          font-size:16px;
          margin:1px 0 ;
          }
          .tabletopDiv h4{
          font-size:16px;
          margin:1px 0;
            width: 40%;
          text-align: left; 
          }

        </style>
        </head>

        <body>
          <div class="container">
            <div class="header">
            <img src='${branchPic}' alt="Plus" width='70' class='logo' />
            <h3>${branchName}</h3>
            <p class='address'>${branchLocation}</p>
            <p class='address'>Contact: ${branchPhone}</p>
            <h4>TAX INVOICE</h4>
          </div>
             <hr style="border: 1px dashed black;  margin-top:8px; margin:0;" />
          <hr style="border: 1px dashed black;  margin-top:3px;" />
            <div class="tabletop">
            <div class="tabletopDiv">
            <h2>NAME</h2>
            <h4 style="text-transform: uppercase;"">:${customerName}</h4>
            </div>
            <div class="tabletopDiv">
            <h2>MOBILE</h2>
            <h4>:${customerPhone}</h4>
            </div>
            <div class="tabletopDiv">
            <h2>INVOICE</h2>
            <h4>:${invoiceNumber}</h4>
            </div>
            <div class="tabletopDiv">
            <h2>DATE</h2>
            <h4>:${new Date().toISOString().slice(0, 10)}</h4>
            </div>
        </div> 
        <hr style="border: 1px dashed black; margin-top:8px; margin-bottom: 0;" />
          <hr style="border: 1px dashed black;  margin-top:3px;"/>
            <table class="table">
  <thead>
    <tr>
      <th style="padding-right:60px;">
        <div style="display: flex; flex-direction: column; align-items: flex-start;">
          <p style="margin: 0;">NAME</p>
          <p style="margin: 0;">STAFF</p>
        </div>
      </th>
      <th style="padding-left:0; font-size:12px; text-align: center; vertical-align: bottom;">PRICE</th>
      <th style="padding-right:2px; font-size:12px; text-align: center; vertical-align: bottom;">DISCOUNT</th>
      <th style="padding-right:0; font-size:12px; text-align: center; vertical-align: bottom;">QTY</th>
      <th style="padding-right:0; font-size:12px; text-align: center; vertical-align: bottom;">TOTAL</th>
    </tr>
        </thead>
        <tbody>
          ${draftBill?.freeServicesApplied
          ? (serviceToBeBilled?.map((ele, ind) => `
              <tr>
                <td>${ele?.serviceName || ""} ${ele?.duration || ""} 
                  <span style="color:gray;text-transform: capitalize;">
                    ${ele?.staffName ? `by ${ele?.staffName}` : ""}
                  </span>
                </td>
                <td>${((ele?.itemPerPrice || 0) * (ele?.quantity || 0)) || ""}</td>
                <td>${ele?.discountType === "percentage" ? `${ele?.discount || 0} %` : (ele?.discount || 0)}</td>
                <td>${ind + 1}</td>
                <td>₹${ele?.price || 0}/-</td>
              </tr>
            `).join("") || "")
          : (draftBill?.services?.map((ele, ind) => `
              <tr>
                <td>${ele?.serviceName || ""} ${ele?.duration || ""} 
                  <span style="color:gray;text-transform: capitalize;">
                    ${ele?.staffName ? `by ${ele?.staffName}` : ""}
                  </span>
                </td>
                <td>${((ele?.itemPerPrice || 0) * (ele?.quantity || 0)) || ""}</td>
                <td>${ele?.discountType === "percentage" ? `${ele?.discount || 0} %` : (ele?.discount || 0)}</td>
                <td>${ind + 1}</td>
                <td>₹${ele?.price || 0}/-</td>
              </tr>
            `).join("") || "")
        }
          ${draftBill?.products?.map?.((ele, ind) => `
            <tr>
              <td>${ele?.productName || ""}</td>
              <td>${((ele?.itemPerPrice || 0) * (ele?.quantity || 0)) || ""}</td>
              <td>${ele?.discountType === "percentage" ? `${ele?.discount || 0} %` : (ele?.discount || 0)}</td>
              <td>${ind + 1}</td>
              <td>₹${ele?.price || 0}/-</td>
            </tr>
          `).join("") || ""}
          ${draftBill?.membershipIds?.map?.((ele, ind) => `
            <tr>
              <td>${ele?.packageName || ""}</td>
              <td>${ele?.price || ""}</td>
              <td></td>
              <td>${ind + 1}</td>
              <td>₹${ele?.price || 0}/-</td>
            </tr>
          `).join("") || ""}
        </tbody>
      </table>
            <hr style="border: 1px dashed black; margin-top:8px;  margin-bottom: 0;" />
          <hr style="border: 1px dashed black; margin-top:3px;" />
            <table class="table">
              <tr>
                <th>SUBTOTAL</th>
                <td>₹${totalServiceCharge + totalProductCharge + (totalMembershipPrice || 0)
        }/-</td>
              </tr>
              <tr>
                <th>NET</th>
                <td>₹${totalServiceCharge +
        totalProductCharge +
        (totalMembershipPrice || 0) -
        draftBill?.walletApplied
        }/-</td>
              </tr>
              <tr>
                <th>GRAND TOTAL</th>
                <td>₹${totalServiceCharge + totalProductCharge + (totalMembershipPrice || 0)
        }/-</td>
              </tr>
            </table>
            <hr style="border: 1px dashed black; margin-top:8px; margin-bottom: 0;" />
          <hr style="border: 1px dashed black;  margin-top:3px;" />
            <table class="table">
              <tr>
                <th>Payments</th>
              </tr>
              ${draftBill?.paymentMethods?.length > 0
          ? draftBill.paymentMethods
            .map(
              (method, index) => `
                        <tr key=${index}>
                          <td>${method?.payment}</td>
                          <td>₹${method?.amount}</td>
                        </tr>
                        `
            )
            .join("")
          : `
                        <tr>
                          <td colspan="2">No payment methods available</td>
                        </tr>
                        `
        }
              <tr>
                <th>Paid</th>
                <td>₹${draftBill?.paymentMethods?.reduce((acc, cur) => acc + cur.amount, 0) || 0
        }</td>
              </tr>
            </table>
            <div class="footer">
              <p>Please visit again!</p>
              <p>Generated By: at ${new Date().toISOString().slice(0, 10)}</p>
            </div>
          </div>
        </body>

        </html>   
      `;
      return pdfContent;
    } else {
      const tableContent = `
      <div style="width: 100%;">
        <style>
         @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
          body {
            font-family: Nunito;
            margin: 0;
            padding: 20px;
          }
          .invoice {
            width: 700px;
            margin: 0 auto;
            border: 1px solid #ccc;
            padding: 20px;
            box-sizing: border-box;
          }
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
          }
          .header img {
            max-width: 100px;
          }
          .from, .to {
            width: 50%;
            box-sizing: border-box;
          }
          .from {
            float: left;
            text-align: left;
          }
          .to {
            float: right;
            text-align: right;
          }
          .clearfix::after {
            content: "";
            clear: both;
            display: table;
          }
          table {
              width: 100%;
              table-layout: fixed;
              border-collapse: separate;
              border-spacing: 0;
              margin-top: 20px;
              border: 1px solid black;
            }
            th, td {
              padding: 12px 8px;
              text-align: center;
              border-right: 1px solid black;
              border-bottom: 1px solid black;
              word-wrap: break-word;
            }
            th:last-child, td:last-child {
              border-right: none;
            }
            tr:last-child td {
              border-bottom: none;
            }
            th {
              background-color: #fe8740;
              color: #fff;
              font-weight: 700;
              text-transform: uppercase;
            }
            th:nth-child(1), td:nth-child(1) { width: 5%; }  /* Sl No */
            th:nth-child(2), td:nth-child(2) { width: 40%; } /* Services */
            th:nth-child(3), td:nth-child(3) { width: 5%; }  /* Qty */
            th:nth-child(4), td:nth-child(4) { width: 15%; } /* Amount */
            th:nth-child(5), td:nth-child(5) { width: 20%; } /* Discount */
            th:nth-child(6), td:nth-child(6) { width: 20%; } /* Total Amount */
            
            tr.total-row td {
              background-color: #e9ecef;
              font-weight: 700;
            }
          .total {
            text-align: right;
            margin-top: 20px;
          }
          .payment {
            margin-top: 20px;
          }
          .terms {
            margin-top: 20px;
            }
            tr.no-vertical-border td {
              border-top: none;
              border-bottom: none;
            }
            tr.no-vertical-border:last-child td {
              border-bottom: none;
            }
            tr.no-vertical-border:first-child td {
              border-top: none;
            }

             /* Styles for the payment summary table */
            .payment-summary-table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 20px;
            }
            
            .payment-summary-table td {
              padding: 8px;
              border: 1px solid black;
            }
            
            .payment-summary-table td:first-child {
              width: 70%; /* Make the first column wider */
              text-align: left;
            }
            
            .payment-summary-table td:last-child {
              width: 30%; /* Make the second column narrower */
              text-align: right;
            }

            /* Style for the header row */
            .payment-summary-table tr:first-child td {
              background-color: #fff;
              font-weight: 700;
            }
        </style>
        <div class="invoice">
          <div class="header">
            <img src='${branchPic}' alt="Kaya Spa">
            <div >
              <h2 style="text-align: right;">INVOICE</h2>
              <p style="text-align: right;">Invoice No. - ${invoiceNumber}</p>
             <p style="text-align: right;">
                    Date/Time - ${new Date()
          .toISOString()
          .slice(0, 10)}, ${new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
                </p>
            </div>
          </div>
          <div class="clearfix">
            <div class="from">
              <h3>Invoice From:</h3>
              <p>${branchName}</p>
              <p>AT - ${branchLocation}</p>
              <p> ${branchPhone}</p>
            </div>
            <div class="to">
              <h3>Invoice To:</h3>
              <p>${singleInvoice?.billingInfo?.name || customerName || customerPhone || ""
        }</p>
              <p> ${customerPhone || ""}</p>
              <p>Wallet Bal.: Rs.${clientDuringBill?.walletBalance
        }.00/-</p>
              <p>Loyalty Point Bal: 0.00/-</p>
            </div>
          </div>
          <table>
            <tr>
              <th>Sl</th>
              <th colspan="6">Services</th>
              <th>Qt</th>
              <th>Amount</th>
              <th>Discount</th>
              <th>Total</th>
            </tr>
            ${
        //   draftBill?.freeServicesApplied
        // ? serviceToBeBilled
        //   ?.map(
        //     (ele, ind) => `
        //           <tr key=${ind}>
        //             <td>${ind + 1}</td>
        //             <td colspan="6">${ele?.serviceName}-- ${ele?.duration} <span style="color:gray;">(${ele?.staffName})</span></td>
        //             <td>${ele?.quantity}</td>
        //             <td>${ele?.itemPerPrice * ele?.quantity || 0}</td>
        //             <td>${ele?.discountType === "flat" && ele?.discount !== null
        //         ? `$${ele?.discount}`
        //         : ele?.discountType === "percentage" && ele?.discount !== null
        //           ? `${ele?.discount} %`
        //           : ""} </td>
        //             <td>${ele?.price}</td>
        //           </tr>
        //         `
        //   )
        //   .join("")
        // : 
        draftBill?.services
          ?.map(
            (service, index) => `
                    <tr key=${index}>
                      <td>${index + 1}</td>
                      <td colspan="6">${service?.serviceName}-- ${service?.duration}  <span style="color:gray;">(${service?.staffName})</span></td>
                      <td>${service?.quantity}</td>
                      <td>${service?.itemPerPrice * service?.quantity || 0}</td>
                     <td>${service?.discountType === "flat" && service?.discount !== null
                ? `$${service?.discount}`
                : service?.discountType === "percentage" && service?.discount !== null
                  ? `${service?.discount} %`
                  : 0} </td>
                      <td>${service?.price}</td>
                    </tr>
                  `
          )
          .join("")
        }
            ${draftBill?.products?.[0]?.productName ? draftBill?.products
          ?.map(
            (product, index) => `
                  <tr key=${index}>
                    <td>${index + 1}</td>
                    <td colspan="6">${product?.productName}</td>
                    <td>${product?.quantity}</td>
                    <td>${Number(product?.itemPerPrice * product?.quantity) || 0
              }</td>
                    <td>${product?.discountType === "flat"
                ? product?.discount
                : product?.discountType === "percentage"
                  ? product?.discount + " %"
                  : ""
              }</td>
                    <td>${product?.price || 0}</td>
                  </tr>
                `
          )
          .join("") : ""}
            ${draftBill?.membershipIds
          ?.map(
            (member, index) => `
                  <tr key=${index}>
                    <td>${index + 1}</td>
                    <td colspan="6">${member?.packageName}</td>
                    <td>1</td>
                    <td>${member?.price}</td>
                    <td>${member?.discount || 0}</td>
                    <td>${member?.price - (member?.discount || 0)}</td>
                  </tr>
                `
          )
          .join("")}

            ${Array(5).fill('').map(() => `
              <tr class="no-vertical-border">
                <td style="padding: 20px; text-align: center"></td>
                <td colspan="6" style="padding: 20px;"></td>
                <td style="padding: 20px; text-align: center"></td>
                <td style="padding: 20px; text-align: right"></td>
                <td style="padding: 20px; text-align: right"></td>
                <td style="padding: 20px; text-align: right"></td>
              </tr>
            `).join('')}
    
            ${freeServicesApplied ? `
              <tr>
                <td colspan="10" style="text-align: right; border-top: 1px solid #000;">Free Services</td>
                <td colspan="2" style="text-align: left; border-top: 1px solid #000;">
                  ${servicesIncludedInMembership
            ?.map((service) =>
              `${service?.serviceName} x ${freeServices[service?.serviceId]}`
            )
            .join(", ") || ""}
                </td>
              </tr>`
          : ''}            
          </table >
    
          <table class="payment-summary-table">
            <tr>
              <td style="text-align: left;">Amount:</td>
              <td style="text-align: right;">${Number(allServiceCharge) +
        Number(totalProductCharge || 0) +
        Number(totalMembershipPrice || 0)
        }</td>
            </tr>
            <tr>
              <td style="text-align: left;">Total (Free Service off):</td>
              <td style="text-align: right;">${draftBill?.freeServicesApplied ? - Number(freeServiceAmount) : 0
        }</td>
            <tr>
              <td style="text-align: left;">Total (Rounded off):</td>
              <td style="text-align: right;">${Number(totalServiceCharge) +
        Number(totalProductCharge || 0) +
        Number(totalMembershipPrice || 0)
        }</td>
            </tr>
            
              ${draftBill?.paymentMethods?.length > 0
          ? draftBill.paymentMethods
            .map(
              (method, index) => `
                    <tr key=${index} class="payment-method">
                     
                      <td style="text-align: left;"> ${method?.payment || ""
                } </td>
                      <td style="text-align: right;">${method?.amount}</td>
                    </tr>
                    
                  `
            )
            .join("")
          : "No payment methods available"
        }
  
          </table>
    
          <div class="terms">
            <h3>Terms &amp; Conditions</h3>
            <ul>
              <li>Money once paid shall not be refunded.</li>
              <li>Services shall not be rendered beyond expiry or validity period.</li>
              <li>Services are not transferable.</li>
            </ul>
          </div>
        </div >
      </div >
  `;
      return tableContent;
    }
  };

  const sendRescheduleSms = async () => {
    let data = JSON.stringify({
      body: `thank You.Visit Again `,
      to: "+916371638082",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/twilio/send-sms`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast(response?.data?.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDownload = () => {
    const content = renderPreviewContent();

    html2pdf()
      .from(content)
      .set({
        margin: 10, filename: "preview.pdf", image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
      })
      .save();
  };

  const handleClear = () => {
    setViewModal(false);
    setDraftBillCreated(false);
    setIsWalletApplied(false);
    setApplyBalance(false);
    setApplyService(false);
    if (paymentStatus === "completed" || isWalletApplied) {
      setFormData({
        ...initialState,
        requiredServices: [
          {
            serviceName: "",
            duration: "",
            staff: "",
            discount: "",
            serviceId: "",
            staffName: "",
            price: "",
            seat: "",
            quantity: "",
          },
        ],
        requiredProducts: [
          {
            productName: "",
            price: "",
            staff: "",
            discount: "",
            staffName: "",
            quantity: "",
            productId: "",
          },
        ],
      });
      setWalletBalance(0);
      setAddMember([]);
      setPaymentStatus("pending");
      setIsEditModalOpen(false);
      dispatch(clearBillForm());
      dispatch(storeNewBill(null));
      dispatch(clearDraftBill());
      dispatch(clearClient());
    }
  }

  return (
    <React.Fragment>
      <Modal
        open={viewModal}
      // onClose={() => props.setViewModal(false)}
      >
        <Box sx={style} className="no-scrollbar">
          <div>
            <div className="btn-wrapper text-center">
              <span
                className="absolute top-4 right-8 text-3xl cursor-pointer"
                onClick={handleClear}
              >
                x
              </span>
              <div className="text-2xl text-[#2b2f32] font-bold text-center">
                Preview
              </div>
            </div>
            <div className="flex flex-col justify-center ">
              <div className="flex flex-col gap-2">
                <div className="flex gap-4 mx-auto">
                  <div className="flex flex-col">
                    <div className="flex flex-col justify-center items-center">
                      <img
                        src={branch?.url || kaya}
                        alt="Logo"
                        className="w-auto h-20"
                      />
                      {/* <p className="text-[18px] font-semibold capitalize">
                        {branch?.name}
                      </p> */}
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <p className="text-[18px] font-semibold">Wallet Balance</p>
                    <p className="text-[18px] font-semibold text-gray-400">
                      {draftBill?.walletApplied > 0 ? (clientDuringBill?.walletBalance || 0) :
                        paymentStatus === "completed" ? (clientDuringBill?.walletBalance || 0)
                          : ((clientDuringBill?.walletBalance || 0) + (BillData?.membershipAray?.[0]?.packagePrice || 0))}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <p className="text-[18px] font-semibold">Due Amount</p>
                    <p className="text-red-500 font-semibold text-[18px]">
                      {clientDuringBill?.dueAmount}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <p className="text-[18px] font-semibold">Date</p>
                    <p className="text-[18px] font-semibold text-gray-400">
                      {singleInvoice?.date?.slice(0, 10) ||
                        new Date()?.toISOString()?.slice(0, 10)}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <AvailableServicesPopover
                      availableClientServices={availableClientServices}
                    />
                  </div>
                </div>

                <div className="flex justify-center items-center flex-wrap  gap-2">
                  <button
                    onClick={handleDownload}
                    className="bg-primaryColor text-white flex items-center gap-3 rounded-md px-4 py-2"
                  >
                    Download
                    <FaDownload
                      size={15}
                      className="text-white cursor-pointer hover:text-white transition ease-in-out hover:duration-300"
                    />
                  </button>

                  <button
                    onClick={handleViewPdf}
                    className="bg-primaryColor text-white flex items-center gap-3 rounded-md px-4 py-2"
                  >
                    View
                    <GrView
                      size={15}
                      className="text-white cursor-pointer hover:text-white transition ease-in-out hover:duration-300"
                    />
                  </button>

                  <button
                    className="bg-primaryColor text-white flex items-center gap-3 rounded-md px-4 py-2"
                    onClick={handlePrint}
                  >
                    Print
                    <SlPrinter
                      size={15}
                      className="text-white cursor-pointer hover:text-white transition ease-in-out hover:duration-300"
                    />
                  </button>

                  <button className="bg-primaryColor text-white flex items-center gap-3 rounded-md px-4 py-2">
                    Email
                    <HiOutlineMailOpen
                      size={15}
                      className="text-white cursor-pointer hover:text-white transition ease-in-out hover:duration-300"
                    />
                  </button>

                  <button
                    onClick={() => sendRescheduleSms()}
                    className="bg-primaryColor text-white flex items-center gap-3 rounded-md px-4 py-2"
                  >
                    Sms
                    <MdOutlineTextsms
                      size={15}
                      className="text-white cursor-pointer hover:text-white transition ease-in-out hover:duration-300"
                    />
                  </button>
                </div>
              </div>

              <div className="flex justify-center items-center flex-col mt-4 w-full">
                <div className="flex flex-col w-full justify-center">
                  <div className="flex gap-4 justify-center items-center">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        id="webview"
                        name="previewOption"
                        value="webview"
                        onChange={(e) =>
                          handlePreviewOptionChange(e.target.value)
                        }
                        checked={previewOption === "webview"}
                        className="mr-2"
                      />
                      <label htmlFor="webview">Web View</label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        id="pdfview"
                        name="previewOption"
                        value="pdfview"
                        onChange={(e) =>
                          handlePreviewOptionChange(e.target.value)
                        }
                        checked={previewOption === "pdfview"}
                        className="mr-2"
                      />
                      <label htmlFor="pdfview">Thermal View</label>
                    </div>
                  </div>

                  <div className="flex justify-start">
                    <iframe
                      ref={printRef}
                      title="Print Content"
                      className="w-full overflow-y-scroll no-scrollbar h-[250px]"
                      srcDoc={renderPreviewContent()}
                    />
                  </div>
                </div>
              </div>

              <div className="flex gap-3 items-center md:mt-0 mt-3">
                <p className="font-bold text-[18px]">
                  {/* Remaining Price :{" "} */}
                  Remaining Price :{" "}
                  {draftBill?.walletApplied || draftBill?.freeServicesApplied || paymentStatus === "completed"
                    ? clientDuringBill?.dueAmount
                    : (totalServiceCharge || 0) +
                    (totalProductCharge || 0) +
                    (clientDuringBill?.dueAmount || 0) +
                    // (clientDuringBill?.previousDueAmount || 0) +
                    (totalMembershipPrice || 0) -
                    (draftBill?.paidAmount || 0)
                  }
                </p>
                <p className="text-[18px] text-red-400 font-semibold">
                  Final Price :{" "}
                  {draftBill?.walletApplied || draftBill?.freeServicesApplied || paymentStatus === "completed"
                    ? clientDuringBill?.dueAmount
                    : (draftBill?.finalPrice || 0) + (clientDuringBill?.dueAmount || 0)}
                  {/* : (draftBill?.finalPrice || 0) + (clientDuringBill?.previousDueAmount || 0)} */}
                </p>
                <p className="text-[18px] text-red-400 font-semibold">
                  Wallet Applied :{" "}
                  {draftBill?.walletApplied}
                </p>
              </div>
            </div>

            {/* <div className="flex items-center">
                  <p className="text-[18px] font-semibold">
                    Payment :{" "}
                    <span
                      className={
                        paymentStatus === "completed"
                          ? "text-green-400"
                          : "text-red-400"
                      }
                    >
                      {paymentStatus === "completed" ? "Completed" : "Pending"}
                    </span>
                  </p>
                </div> */}
            <div className="flex justify-between items-start mt-2 md:flex-row flex-col">
              <div className="flex gap-2 items-start">
                <button
                  onClick={() => setShowOptions(!showOptions)}
                  className="p-2 w-fit bg-primaryColor text-white rounded-full shadow-lg hover:bg-primaryHoverColor transition duration-300"
                >
                  <FaGift size={24} />
                </button>
                {showOptions &&
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2">
                      <input
                        type="checkbox"
                        name="walletBalance"
                        id="walletBalance"
                        value={applyBalance}
                        checked={applyBalance}
                        onChange={() => {
                          if (applyBalance === true) {
                            dispatch(setBillForm({ ...BillData, paidPrice: 0 }));
                            // setWalletBalance(0);
                          }
                          setApplyBalance((prev) => !prev);
                          setActiveTab("wallet");
                        }}
                      />
                      <span>Apply Wallet Balance</span>
                      <input
                        type="checkbox"
                        name="freeServices"
                        id="freeServices"
                        value={applyService}
                        disabled={paymentStatus === "completed"}
                        checked={applyService}
                        onChange={() => {
                          if (applyService === true) {
                            dispatch(
                              setBillForm({
                                ...BillData,
                                freeServicesApplied: false,
                                serviceToBeBilled: BillData?.requiredServices,
                                remainingServices: {
                                  ...BillData?.client?.services,
                                },
                                servicesIncludedInMembership: [],
                                freeServices: [],
                              })
                            );
                          }
                          setApplyService((prev) => !prev);
                          setActiveTab("freeServices");
                        }}
                      />
                      <span>Apply Services</span>
                      {/* <input
                        type="checkbox"
                        name="applyCoupon"
                        id="applyCoupon"
                        value={applyCoupon}
                        disabled={paymentStatus === "completed"}
                        checked={applyCoupon}
                        onChange={() => {
                          setapplyCoupon(prev => !prev);
                          setActiveTab("coupon")
                        }}
                      />
                      <span>Apply Coupon</span>
                      <input
                        type="checkbox"
                        name="applyGiftCard"
                        id="applyGiftCard"
                        value={applyGiftCard}
                        disabled={paymentStatus === "completed"}
                        checked={applyGiftCard}
                        onChange={() => {
                          setapplyGiftCard(prev => !prev);
                          setActiveTab("giftCard");
                        }}
                      />
                      <span>Apply GiftCard</span> */}
                    </div>

                    <div className="grid grid-cols-2">

                      {applyBalance && (
                        <div className="flex gap-4 mt-2 w-full">
                          <div className="md:w-[60%] w-full flx flex-col gap-1">
                            <label className="text-red-500" htmlFor="applyBalance">
                              {walletBalance >
                                clientDuringBill?.walletBalance + BillData?.membershipAray?.[0]?.packagePrice
                                ? "Insufficent Wallet Balance"
                                : ""}
                            </label>
                            <input
                              className={
                                walletBalance >
                                  clientDuringBill?.walletBalance + clientDuringBill?.walletBalance + BillData?.membershipAray?.[0]?.packagePrice
                                  ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150 pr-10"
                                  : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-10"
                              }
                              type="number"
                              placeholder="Apply Balance"
                              id="balance"
                              name="balance"
                              disabled={isWalletApplied}
                              value={walletBalance}
                              min={0}
                              onChange={(e) => setWalletBalance(e.target.value)}
                            />
                          </div>
                          <div
                            className={`md: w - [25 %] w - full ${walletBalance >
                              clientDuringBill?.walletBalance &&
                              "mt-1"
                              } `}
                          >
                            <button
                              disabled={
                                walletBalance >
                                clientDuringBill?.walletBalance + BillData?.membershipAray?.[0]?.packagePrice ||
                                isWalletApplied
                              }
                              onClick={() => handleApplyWalletBalance()}
                              className="py-2 w-full flex items-center justify-center text-md px-4 font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor rounded-lg shadow-lg transition ease-in-out duration-300"
                            >
                              {isLoading ? (
                                <NewLoader />
                              ) : (
                                draftBill?.walletApplied > 0 ? "Applied" : "Apply"
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                      {applyService && (
                        <div className="flex gap-4 mt-2 w-full">
                          <div className="md:w-[60%] w-full flx flex-col gap-1">
                            <input
                              className={
                                0
                                  ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150 pr-10"
                                  : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-10"
                              }
                              type="text"
                              placeholder="Apply Free Services"
                              id="freeServices"
                              name="freeServices"
                              disabled={true}
                              defaultValue={servicesIncludedInMembership
                                ?.map(
                                  (service) =>
                                    `${service?.serviceName} x ${freeServices[service?.serviceId]
                                    } `
                                )
                                .join(", ")}
                            />
                          </div>
                          <div className={`md: w - [25 %] w - full`}>
                            <button
                              onClick={() => handleApplyFreeServices()}
                              disabled={draftBill?.freeServicesApplied}
                                // && (freeServices && typeof freeServices === 'object' && Object.keys(freeServices).length > 0)}
                              className="h-11 w-full flex items-center justify-center text-lg px-4 font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor rounded-xl shadow-lg transition ease-in-out duration-300"
                            >
                              {isLoading ? (
                                <NewLoader />
                              ) : (
                                draftBill?.freeServicesApplied > 0 ? "Applied" : "Apply"
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                      {applyGiftCard && activeTab === "giftCard" && (
                        <div className="flex gap-4 mt-2 w-full">
                          <div className="md:w-[60%] w-full flx flex-col gap-1">
                            <label className="text-red-500" htmlFor="applyGiftCard">
                              {0
                                ? "Insufficent GiftCard Balance"
                                : ""}
                            </label>
                            <input
                              className={
                                0
                                  ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150 pr-10"
                                  : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-10"
                              }
                              type="number"
                              placeholder="Apply GiftCard"
                              id="giftCard"
                              name="giftCard"
                              disabled={paymentStatus === "completed"}
                              // value={walletBalance}
                              min={0}
                            // onChange={(e) => setWalletBalance(e.target.value)}
                            />
                          </div>
                          <div
                            className={`md: w - [25 %] w - full ${walletBalance >
                              BillData?.client?.walletBalance +
                              BillData?.membershipAray?.[0]?.packagePrice &&
                              "mt-6"
                              } `}
                          >
                            <button
                              // onClick={() => handleApplyWalletBalance()}
                              className="h-11 w-full flex items-center justify-center text-lg px-4 font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor rounded-xl shadow-lg transition ease-in-out duration-300"
                            >
                              {0 ? "Applied" : " Apply"}
                            </button>
                          </div>
                        </div>
                      )}
                      {applyCoupon && activeTab === "coupon" && (
                        <div className="flex gap-4 mt-2 w-full">
                          <div className="md:w-[60%] w-full flx flex-col gap-1">
                            <label className="text-red-500" htmlFor="applyCoupon">
                              {0
                                ? "Invalid Coupon"
                                : ""}
                            </label>
                            <input
                              className={
                                0
                                  ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150 pr-10"
                                  : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-10"
                              }
                              type="number"
                              placeholder="Apply Coupon"
                              id="coupon"
                              name="coupon"
                              disabled={paymentStatus === "completed"}
                              // value={walletBalance}
                              min={0}
                            // onChange={(e) => setWalletBalance(e.target.value)}
                            />
                          </div>
                          <div
                            className={`md: w - [25 %] w - full ${walletBalance >
                              BillData?.client?.walletBalance +
                              BillData?.membershipAray?.[0]?.packagePrice &&
                              "mt-6"
                              } `}
                          >
                            <button
                              // onClick={() => handleApplyWalletBalance()}
                              className="h-11 w-full flex items-center justify-center text-lg px-4 font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor rounded-xl shadow-lg transition ease-in-out duration-300"
                            >
                              {0 ? "Applied" : " Apply"}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>}
              </div>
              <div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4">
                    {paymentStatus === "completed" ? (
                      <>
                        <button
                          className="bg-primaryColor flex gap-2 items-center text-white py-2 px-4 rounded-md hover:bg-primaryHoverColor focus:outline-none focus:ring-2 focus:ring-primaryColor"
                          onClick={() => handleWalletEdit(clientDuringBill, draftBill)}
                        >
                          {isLoading ? (
                            <NewLoader />
                          ) : (
                            <>
                              <MdModeEditOutline /> Wallet
                            </>
                          )}

                        </button>
                        <button
                          className="bg-primaryColor flex gap-2 items-center text-white py-2 px-4 rounded-md hover:bg-primaryHoverColor focus:outline-none focus:ring-2 focus:ring-primaryColor"
                          onClick={() => handleEditPayments()}
                        >
                          <MdModeEditOutline /> Payments
                        </button>
                      </>
                    ) : (
                      <button
                        className="bg-primaryColor text-white py-2 px-4 rounded-md hover:bg-primaryHoverColor focus:outline-none focus:ring-2 focus:ring-primaryColor"
                        onClick={() => {
                          handleCompleteButtonClick();
                        }}
                      >
                        View Payments
                      </button>
                    )}
                  </div>
                  <div className="flex flex-row items-center justify-end gap-4">
                    {paymentStatus !== "completed" && !isWalletApplied && !freeServicesApplied && (
                      <button
                        disabled={paymentStatus === "completed"}
                        className=" bg-slate-800 text-white py-2 w-16 rounded-md hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-primaryColor"
                        onClick={handleBillModalOpen}
                      >
                        Edit
                      </button>
                    )}
                    <button
                      className="bg-primaryColor text-white py-2 w-16 rounded-md hover:bg-primaryHoverColor focus:outline-none focus:ring-2 focus:ring-primaryColor"
                      onClick={handleClear}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Toaster />
      <PaymentModal
        isOpen={paymentModalOpen}
        onClose={() => setPaymentModalOpen(false)}
        isWalletApplied={isWalletApplied}
        setPaymentStatus={setPaymentStatus}
        paymentStatus={paymentStatus}
      />

      <PaymentEditModal
        isOpen={openEditPayment}
        onClose={() => setOpenEditPayment(false)}
        singleInvoice={draftBill}
        isEdited={isEdited}
        setIsEdited={setIsEdited}
      />
      <Modal open={pdfViewerOpen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "800px",
            maxHeight: "90vh",
            overflow: "scroll",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            outline: "none",
            borderRadius: "8px",
          }}
          className="no-scrollbar"
        >
          <div className="flex justify-end">
            <button
              onClick={() => setPdfViewerOpen(false)}
              className=" text-2xl"
            >
              x
            </button>
          </div>
          <div>
            <iframe
              title="PDF Viewer"
              className="w-full h-screen"
              srcDoc={selectedContents}
              style={{ border: "none" }}
            />
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default InvoicePreviewModal;

